<div class="page-container">
  <app-menu
    *ngIf="!isReplacingProduct() && !isSelectingProduct() && !isClientView"
    class="hide-phone"
    active="Marketplace"
  ></app-menu>
  <div class="content-container">
    <app-header
      handleBackButton="true"
      (onBack)="back()"
      [title]="
        !isReplacingProduct()
          ? isSelectingProduct()
            ? 'Select product'
            : 'Marketplace'
          : 'Choose replacement product'
      "
      [ngClass]="{ titleOnly: isReplacingProduct() || isSelectingProduct() }"
    ></app-header>
    <mat-spinner
      class="page-loading"
      *ngIf="loading"
      diameter="35"
      strokeWidth="1"
    ></mat-spinner>
    <div class="content" *ngIf="!loading">
      <div class="image-column hide-phone">
        <img [src]="selectedExtraImage ? selectedExtraImage : product.fullCoverImageUrl | safeUrl" [ngClass]="{'standardise-image': product.unitType === 'Each'}" />
        <img
          class="hover-style"
          [ngClass]="{ hide: !showHover, 'standardise-image': product.unitType === 'Each' }"
          [src]="hoverStyle"
        />
      </div>
      <div class="content-column">
        <div class="small-button hide-phone" (click)="back()">Back</div>
        <h1 class="title" [innerHTML]="product.productCollection | safeHtml"></h1>
        <p class="category" [innerHTML]="product.brand + (product.subBrand ? ' - '+product.subBrand : '') +' | '+ (product.productCategory) | safeHtml"></p>
        <p class="stock" *ngIf="!isClientView">
          {{ getStockText() }}
        </p>
        <div class="flrhub-bg">
        <div class="price" *ngIf="product.retailPrice && !isClientView && !hasProductBreakpoints()">
          {{ product.retailPrice }}/{{unitTypeToMeasure(product.unitType)}}
        <!--  <span class="discount">Discounts may apply at checkout</span> -->
        </div>
        <div *ngIf="product.priceBreakpoints && product.priceBreakpoints.length > 0 && !isClientView" class="price-breakpoints">
          <div class="price small" *ngIf="product.retailPrice">
            {{ productClass.calculateRetailCost(product,0) }}/{{unitTypeToMeasure(product.unitType)}}
          </div>
          <table class="price-breakpoints-details">
            <tr *ngFor="let breakpoint of product.priceBreakpoints" class="price-breakpoint-row">
              <th><span class="price-breakpoints-cost">{{getBreakpointCost(breakpoint)}}</span></th>
              <th><span class="price-breakpoints-units">{{getBreakpointQuantity(breakpoint)}}+{{product.unitType === 'Each' ? ' Units' : product.unitType === 'LM' ? 'LM' : 'm²'}}</span></th>
            </tr>
          </table>
          <div class="price" *ngIf="product.retailPrice && !isClientView">
            <!--<span class="discount">Discounts may apply at checkout</span> -->
          </div>
        </div>
        <p class="description">
          {{ formattedDescription }} {{ product.productTypeIso }}
          {{ product.chemicalResistance }}
        </p>
          <div class="select-color-container">
            <p>Select a colour:</p>
            <div class="styles-container hide-scrollbar" *ngIf="product.brandHierarchy !== 'Variant'">
              <img
                *ngFor="let style of collection.products"
                (click)="selectStyle(style)"
                class="style"
                (mouseover)="
              hoverStyle = style.fullThumbnailImageUrl; showHover = true
            "
                (mouseout)="showHover = false"
                [ngClass]="{ selected: selectedProductCode === style.productCode }"
                [src]="style.thumbnailImageUrl"
                loading="lazy"
              />
            </div>
            <div class="" *ngIf="product.brandHierarchy !== 'Variant' && product.productDesign">Colour#:&nbsp;{{ product.productDesign }}</div>
          </div>

          <div
            class="quantity-container"
            *ngIf="!isSelectingProduct() && product.retailPrice && product.unitType !== 'LM'"
          >
            <div class="quantity-area">
              <div class="qty-label">Quantity</div>
              <input
                class="quantity-field"
                [ngClass]="{ 'quantity-error': errors.quantity }"
                [(ngModel)]="quantity"
                (ngModelChange)="errors.quantity = false; calculateBoxQuantity(); calculateStockAvailable()"
                type="number"
                min="0"
                placeholder="0"
              />
              <div *ngIf="product.unitType !== 'Quantity' && product.unitType !== 'Each' && product.unitType !== 'LM'" class="unit">
                  m<sup>2</sup>
                </div>
                <div *ngIf="product.unitType === 'Quantity' || product.unitType === 'Each'" class="unit">
                  Units
                </div>
                <div *ngIf="product.unitType === 'LM'" class="unit">
                  LM
                </div>
                <div
                  class="order-fulfilment-details"
                  *ngIf="product.unitType === 'Box'||product.unitType === 'Pack'"
                >
                  Orders are filled at
                  <b>{{ surfacePerBox }}m<sup>2</sup> per box</b>
                </div>
                <div
                  class="order-fulfilment-details"
                  *ngIf="product.unitType === 'Roll'"
                >
                  Roll size:
                  <b>{{ (+product.length * +product.width) / 10000 }}m<sup>2</sup></b>
                </div>
            </div>
          </div>
          <div [style.color]="'#000000'" *ngIf="isStockAvailable || isStockUnavailable">
            {{isStockAvailable ? 'Requested amount above is in stock' : 'The requested amount above is not in stock'}}
          </div>
        </div>

        <!-- NEW ADD-ONS AREA -->
        <div class="add-ons-outer" *ngIf="this.addOns.length > 0">
          <div class="inner" *ngFor="let product of this.addOns">
              <img class="img-style" [src]="product.coverImageUrl? product.coverImageUrl :'../../../assets/images/image_placeholder.svg'" />
            <div class="add-ons-content">
              <p class="add-on-name">{{product.productCollection}}</p>
              <div style="padding-left: 10px; padding-right: 10px;">
                <p class="add-ons-price">{{product.retailPrice}}</p>
              </div>
              <!--QTY AREA-->
              <div class="add-ons-qty-outer">
                <div class="ao-qty-label">Quantity / {{ product.unitTypeMeasurement }}</div>
                  <input
                  [ngClass]="{ 'quantity-error': errors.quantity }"
                  [(ngModel)]="product.quantity"
                  (ngModelChange)="errors.quantity = false; calculateBoxQuantity()"
                  type="number"
                  min="0"
                  placeholder="0"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- END OF NEW ADD-ONS AREA -->

        <div class="image-column show-phone">
          <img [src]="product.fullCoverImageUrl | safeUrl" [ngClass]="{'standardise-image': product.unitType === 'Each'}" loading="lazy" />
          <img
            class="hover-style"
            [ngClass]="{ hide: !showHover, 'standardise-image': product.unitType === 'Each'}"
            [src]="hoverStyle"
            loading="lazy"
          />
        </div>

        <div class="files hide-scrollbar">
          <div *ngFor="let file of product.files">
            <div
              class="secondary-button"
              (click)="downloadFile(file)" *ngIf="file.fileUrl">
              <img
                class="icon"
                src="../../../assets/images/download_icon.png"
              />{{ file.fileType }}
            </div>
          </div>
        </div>
        <div class="styles-container hide-scrollbar" *ngIf="product.brandHierarchy === 'Variant'">
          <img
            (click)="selectExtraImage(product.fullCoverImageUrl)"
            class="style"
            (mouseover)="
              hoverStyle = product.fullCoverImageUrl; showHover = true
            "
            (mouseout)="showHover = false"
            [ngClass]="{ selected: selectedExtraImage === product.fullCoverImageUrl}"
            [src]="product.thumbnailImageUrl"
            loading="lazy"
          />
          <img
            *ngFor="let extraImage of product.extraImages"
            (click)="selectExtraImage(extraImage.fullImage)"
            class="style"
            (mouseover)="
              hoverStyle = extraImage.fullImage; showHover = true
            "
            (mouseout)="showHover = false"
            [ngClass]="{ selected: selectedExtraImage === extraImage.fullImage}"
            [src]="extraImage.thumbnailImage"
            loading="lazy"
          />
        </div>
        <div class="input-field width-fit" *ngIf="product.brandHierarchy === 'Variant'">
          <div *ngFor="let variant of getVariations()">
            <label [for]="variant" >{{variant}}</label>
            <select [id]="variant" [name]="variant" [(ngModel)]="selectedVariations[variant]" (ngModelChange)="loadProductForVaration()">
              <option value="" disabled selected hidden>Please select...</option>
              <option *ngFor="let variant of getVariantList(variant)" [value]="variant">{{variant}}</option>
          </select>
          </div>
        </div>

        <!-- START OF CUT BUTTON AREA-->
        <div class="cuts-container" *ngIf="product.unitType === 'LM' && populateCuts()">
          <div class="cut-row" *ngFor="let cut of product.cuts; let i = index; trackBy: trackByFn" >
            <div class="linear-metre-cut-button-outer" *ngIf="!isSelectingProduct() && product.retailPrice">
              <!-- NEW STRUCTURE STARTS HERE -->

              <div class="lm-quantity-label">
                <span>Quantity</span>
              </div>
                <div class="lm-cuts-wrapper">
                    <input
                    class="quantity-field"
                    [ngClass]="{ 'quantity-error': errors.quantity }"
                    [(ngModel)]="product.cuts[i]"
                    (ngModelChange)="errors.quantity = false; calculateBoxQuantity()"
                    type="number"
                    min="0"
                    placeholder="0"
                  />
                  <div>
                    <div class="unit">
                    LM (Cut {{i+1}})
                    <img *ngIf="product.cuts.length > 1" (click)="deleteCut(i)" src="../../../assets/images/thin_cross.png" alt="" class="cross-icon"></div>
                </div>
              </div>
              <!-- NEW STRUCTURE ENDS HERE -->


          </div>
         </div>
         <div class="secondary-button small-button add-cut-button" *ngIf="!isSelectingProduct() && product.retailPrice" (click)="addCutButtonPressed()">+ Add another cut</div>
          <p class="stock-disclaimer">
            Please make sure you add in sufficient waste for cuts in your order quantity.
            <br>All cut lengths specified through our estimation service are net quantities and do not allow wastage cuts.
            <br>FLRHUB recommends adding 100mm per cut length for cut waste.
          </p>
        </div>
        <!-- END OF CUT AREA -->
        <div *ngIf="product.retailPrice">
          <p
            *ngIf="
              (!isSelectingProduct() &&
                +product.stockAvailable < boxQuantity &&
                (product.unitType === 'Box')||product.unitType === 'Pack') ||
              !product.stockAvailable ||
              +product.stockAvailable < quantity
            "
            class="stock-disclaimer"
          >
          Availability for this product will depend on order quantity.<br />
          (Delivery times will be quoted before order confirmation.)
          </p>
        </div>
        <div class="price-on-request-container" *ngIf="!product.retailPrice && !isSelectingProduct()">
          <a
            href="mailto:team@flrhub.com.au?subject={{
              product.productCollection
            }} - {{ product.productDesign }}"
            class="primary-button price-on-request-button"
            target="_blank"
            >Price on request</a
          >
        </div>


        <div class="cart-outer-container">
          <div class="cart-inner-container">
            <div
              class="box-total"
              *ngIf="!isSelectingProduct() && (product.unitType === 'Box'||product.unitType === 'Pack')"
            >
              Box Total:
              <span
              >{{ (boxQuantity / surfacePerBox).toFixed(0) }} =
                {{ boxQuantity }}m<sup>2</sup></span
              >
            </div>
            <div
              class="box-total"
              *ngIf="
              !isSelectingProduct() &&
              product.unitType === 'Roll' &&
              isLessThanARoll && !isClientView
            "
            >
              Roll cut price: <span>${{ (quantity * 4).toFixed(2) }}</span>
            </div>
            <div
              class="box-total"
              *ngIf="
            !isSelectingProduct() &&
            product.unitType === 'LM' &&
            calculateLmCutPrice() > 0 && !isClientView
          "
            >
              Cut price: <span>${{ calculateLmCutPrice() }}</span>
            </div>
            <div
              class="separator"
              *ngIf="
              !isSelectingProduct() &&
              (product.unitType === 'Box' ||product.unitType === 'Pack'||
                (product.unitType === 'Roll' && isLessThanARoll)) && !isClientView
            "
            ></div>
            <div class="box-total" [ngClass]="{'solid': this.quantity > 0}" *ngIf="!isSelectingProduct() && !isClientView">
              {{this.product.unitType === 'LM' ? this.quantity ? 'Total LM: '+this.quantity+' | ' : 'Total LM: 0 | '  : ''}}Total: <span>{{ total }}</span>
            </div>

          </div>
          <div
            class="new-button add-to-card-button hide-phone"
            [ngClass]="{ loading: addingToCart,'disabled-button': addingToCart}"
            (click)="!addingToCart && addToCart()"
          >
            {{ getButtonTitle() }}
            <img src="../../../assets/images/shopping-cart.svg" alt="Shopping cart icon">
          </div>
        </div>

        <div
          class="primary-button add-to-card-button show-phone"
          [ngClass]="{ loading: addingToCart }"
          (click)="addToCart()"
          *ngIf="product.retailPrice"
        >
          {{ getButtonTitle() }}
        </div>

        <!-- <div class="estimate-button"><img src="../../../assets/images/ruler_icon.png"> Get An Estimation</div> -->

        <!-- <div class="weld-rod-description" *ngIf="weldRodRequired">Weld rod is required for this product. <span class="add-weld-rod" (click)="addWeldRod()">Add weld rod</span></div> -->

        <div class="enquire-now">
          Need help with this product?
          <a
            href="mailto:team@flrhub.com.au?subject={{
              product.productCollection
            }}"
            target="_blank"
            gaEvent="product_enquire_button"
            gaCategory="click_event"
            >Enquire Now</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
