<div class="page-container phone-padding-top">
  <div class="show-phone top-logo-container">
    <img class="logo" src="../../../assets/images/FLRHUB-Logo_UPDATED.svg" />
  </div>
  <img
    class="logo hide-phone"
    src="../../../assets/images/FLRHUB-Logo_UPDATED.svg"
  />
  <div class="show-phone your-subscription">
        <!-- <img class="down-arrow" src="../../../assets/images/down_arrow.png"> -->
    <div class="description">
      Please read about our
      <a target="”_blank”" href="/delivery">Delivery</a> and
      <a target="”_blank”" href="/returns">Return</a> statements for further
      information.
    </div>
  </div>
  <div class="inner-container">
    <div class="small-button" (click)="back()">Back</div>
    <h1 class="heading">Let's grab your personal details now</h1>

    <div class="input-field-row">
      <div class="input-field" [ngClass]="{ error: errors.firstName }">
        <label for="firstName">First Name</label>
        <input
          id="firstName"
          [(ngModel)]="user.firstName"
          (ngModelChange)="validate()"
          placeholder="Type here..."
          type="text"
        />
      </div>
      <div class="input-field" [ngClass]="{ error: errors.lastName }">
        <label for="lastName">Last Name</label>
        <input
          id="lastName"
          [(ngModel)]="user.lastName"
          (ngModelChange)="validate()"
          placeholder="Type here..."
          type="text"
        />
      </div>
    </div>
    <div class="input-field-row">
      <div class="input-field" [ngClass]="{ error: errors.mobileNumber }">
        <label for="mobileNumber">Mobile Number</label>
        <input
          id="mobileNumber"
          [(ngModel)]="user.mobileNumber"
          (ngModelChange)="validate()"
          placeholder="Type here..."
          type="text"
        />
      </div>
      <!--<div class="input-field" [ngClass]="{error: errors.driversLicenceNumber}">
                <label for="driversLicenceNumber">Drivers Licence Number</label>
                <input id="driversLicenceNumber" [(ngModel)]="user.driversLicenceNumber" (ngModelChange)="validate()" placeholder="Type here..."
                    type="text">
            </div>-->
    </div>

    <div class="input-field" [ngClass]="{ error: errors.homeAddress }">
      <label for="homeAddress">Residential Address</label>
      <input
        id="homeAddress"
        ngx-google-places-autocomplete
        [(ngModel)]="user.homeAddress"
        (ngModelChange)="validate()"
        (onAddressChange)="handleAddressChange($event)"
        placeholder="Type here..."
        type="text"
      />
    </div>
    <div *ngIf="errorMessage.length > 0" style="margin-bottom: 35px">
      <div class="error" *ngFor="let error of errorMessage">
        <img class="error-icon" src="../../../assets/images/cross_icon.png" />{{
          error
        }}
      </div>
    </div>
    <div style="display: flex; justify-content: end">
      <div
        class="primary-button"
        [ngClass]="{ loading: loading }"
        (click)="signup()"
      >
        Next
      </div>
    </div>
  </div>
</div>

<img class="background-logo" src="../../../assets/images/bg_logo.png" />
