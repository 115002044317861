<div class="page-container">
    <app-menu *ngIf="!isReplacingProduct() && !isSelectingProduct() && !isClientView" active="Marketplace"></app-menu>
    <div class="content-container">
        <app-header [title]="!isReplacingProduct() ? isSelectingProduct() ? 'Select product' : 'Marketplace' : 'Choose replacement product'"[ngClass]="{titleOnly: isReplacingProduct() || isSelectingProduct()}"></app-header>
        <div class="inner-container">
            <div class="filters-container hide-scrollbar hide-phone" [ngClass]="{hideFilters: hideFilters}">
              <!-- NOTE: NEW FILTERS HERE -->

                <mat-accordion multi>
                  <mat-expansion-panel *ngFor="let filter of keysForObject(productFilters)">
                  <mat-expansion-panel-header class="filter-label">
                    <mat-panel-title>
                        {{filter}}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                    <div *ngFor="let filterItem of keysForObject(productFilters[filter].items)">
                  <mat-checkbox class="custom-checkbox" [checked]="productFilters[filter].items[filterItem].selected" [disabled]="loading"(change)="updateFilterItem($event, filter, productFilters[filter].items[filterItem])" >
                    {{ filterItem }} <span *ngIf="filter != 'Industries'"> ({{ productFilters[filter].items[filterItem].count ?? 0 }})</span>
                </mat-checkbox ></div>
                  </mat-expansion-panel>

                  <mat-expansion-panel>
                    <mat-expansion-panel-header class="filter-label">
                      <mat-panel-title>
                          Product view
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                   <div class="custom-checkbox">
                     <mat-checkbox [checked]="isClientView" (change)="updateClientView($event)" >Selection hub</mat-checkbox>
                   </div>
                  </mat-expansion-panel>
                </mat-accordion>

<!-- Dialog Template (AI Slop for fixing this) -->
<ng-template #filterDialogTemplate>
  <h2 mat-dialog-title>Filters</h2>
  <mat-dialog-content>
    <mat-accordion multi>
      <mat-expansion-panel *ngFor="let filter of keysForObject(productFilters)">
        <mat-expansion-panel-header class="filter-label">
          <mat-panel-title>
            {{ filter }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngFor="let filterItem of keysForObject(productFilters[filter].items)">
          <div *ngif="productFilters[filter].items[filterItem].count !== 0">
          <mat-checkbox class="custom-checkbox"
                        [checked]="productFilters[filter].items[filterItem].selected"
                        [disabled]="loading"
                        (change)="updateFilterItem($event, filter, productFilters[filter].items[filterItem])">
            {{ filterItem }}
            <span *ngIf="filter != 'Industries'">
              ({{ productFilters[filter].items[filterItem].count ?? 0 }})
            </span>
        </mat-checkbox>

        </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header class="filter-label">
          <mat-panel-title>
            Product View
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="custom-checkbox">
          <mat-checkbox [checked]="isClientView" (change)="updateClientView($event)">Selection hub</mat-checkbox>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button (click)="closeDialog()">Close</button>
  </mat-dialog-actions>
</ng-template>

                <div *ngIf="false"><!-- This is to hide the old filters -->
                <div class="filter-option" [ngClass]="{expanded: filters[filter].expanded}"
                  *ngFor="let filter of keysForObject(filters)"
                    (click)="filters[filter].expanded = !filters[filter].expanded">
                    <div  class="filter-label">{{filter}}</div>
                    <img class="arrow-icon" src="../../../assets/images/down_arrow.png">
                    <!-- <div class="options-container" [style]="'height: fit-content'"> -->
                    <div class="options-container" [style]="'height: '+getSubItemsHeight(filters[filter].items)+'px;'">
                        <div class="options" *ngFor="let item of filters[filter].items"
                        [style]="'height: '+getFilterHeight(item)+'px;'">
                        <!-- [ngClass]="{'expanded-sub-option': item.inputField && item.selected}"> -->
                            <div class="flex" (click)="filterSelected(filter, item); $event.stopPropagation();">
                                <div class="check-box" [ngClass]="{selected: item.selected}">
                                    <img src="../../../assets/images/check_icon.png">
                                </div>
                                <div class="item-name" [innerHTML]="item.name + ((item.count > -1 && filter !== 'Brands') || (item.count > 0 && filter === 'Brands') ? ' ('+item.count+')' : '')"></div>
                            </div>
                            <div class="subbrand-container" *ngIf="item.selected">
                                <div class="flex indent" *ngFor="let subCategory of item.subCategories ? item.subCategories : []" (click)="subbrandFilterSelected(filter, item, subCategory); $event.stopPropagation();">
                                    <div class="check-box" [ngClass]="{selected: subCategory.selected}">
                                        <img src="../../../assets/images/check_icon.png">
                                    </div>
                                    <div class="item-name small" [innerHTML]="subCategory.name"></div>
                                </div>
                            </div>                            
                            <div *ngIf="item.inputField && item.selected" class="sub-flex" (click)="$event.stopPropagation();">
                                <input class="quantity-field" type="number" id="filterData" [(ngModel)]="item.inputField.dataEntered" (ngModelChange)="stockRequiredUpdated(filter, item)" placeholder={{item.inputField.placeholder}}>
                                <div class="item-name">m²</div>
                            </div>
                        </div>
                    </div>                    
                </div>

                </div>
                <div class="secondary-button small-button clear-filters-button" *ngIf="filterIsSelected()"
                    (click)="clearAllFilter()"><img class="icon" src="../../../assets/images/close_icon.png">Clear all
                    filters</div>

            </div>
            <div id="scrollContent" class="content">
                <div class="search-container">
                    <img class="search-icon show-phone"  src="../../../assets/images/search_icon.png">
                    <div class="secondary-button small-button show-phone filter-button" (click)="openFilterDialog()"><img class="icon"
                        src="../../../assets/images/filter_icon.png"> Filters</div>
                    <div class="filter-toggle hide-phone" [ngClass]="{filtersShowing: !hideFilters}"
                        (click)="hideFilters = !hideFilters"><img
                            src="../../../assets/images/filter_icon.png">{{hideFilters ? 'Show' : 'Hide'}} Filters</div>

                    <div class="input-field search-field hide-phone">
                        <img class="icon" src="../../../assets/images/search_icon.png">
                        <!-- 1 indicates to loadProductCollection to bring search back to first page when search instantiated -->
                        <input [(ngModel)]="searchTerm" (ngModelChange)="onSearch($event)"
                            placeholder="Search products by name, type or brand..." type="text">
                    </div>

                    <div class="sort-selector">
                      <label for="sortProducts">Sort by:</label>
                      <select name="sortProducts" id="sortProducts" [(ngModel)]="productSort" (ngModelChange)="loadProductCollection(searchTerm, page)">
                        <option *ngFor="let option of sortOptions; index as index" [value]="index"> {{ option.label }}</option>
                      </select>
                    </div>
                </div>
                <!--


                <div><strong>Search term: {{ searchTerm }} </strong></div>
                <div>{{ products }}</div>
                <div><strong>Metadata: {{ meta }} </strong></div>

                -->
                <div *ngIf="!loading" class="products-container">
                    <div class="product-item" (click)="viewProduct(collection)" *ngFor="let collection of allProductCollections">
                        <!-- <div class="favourite-icon"><img src="../../../assets/images/favourite_icon.png"></div> -->
                        <!-- <img [ngClass]="{'standardise-image': collection.unitType === 'Each'}" class="product-image" [ngSrc]="collection.coverImageUrl ? collection.coverImageUrl : 'missing.jpg'" height="250" width="250"> -->
                        <img [ngClass]="{'standardise-image': collection.unitType === 'Each'}" class="product-image" [src]="collection.coverImageUrl" loading="lazy">
                        <div class="product-options">
                            <img class="product-option" *ngFor="let product of collection.products.slice(0, (isPhone ? 4 : 8))" loading="lazy" [src]="product.thumbnailImageUrl">
                            <div class="extra-options" *ngIf="collection.products.length > (isPhone ? 4 : 8)">
                                +{{collection.products.length - (isPhone ? 4 : 8)}}</div>
                        </div>
                        <div class="product-name" [innerHTML]="collection.collectionName | safeHtml"></div>
                        <div class="product-category" [innerHTML]="collection.brand + (collection.subBrand ? ' - '+collection.subBrand : '') +' | '+ (collection.category) | safeHtml"></div>
                        <div class="product-price" *ngIf="collection.retailPrice !== 0 && !isClientView">{{getPrice(collection)}}/{{unitTypeToMeasure(collection.unitType)}}</div>
                    </div>
                </div>
                <mat-spinner class="loading" *ngIf="loading" diameter="35" strokeWidth="3"></mat-spinner>
                <div *ngIf="!allProductCollections" class="loading">No products found</div>
                <mat-paginator [pageSize]="meta.per_page" [length]="meta.total" [pageIndex]="meta.current_page - 1" aria-label="Select page" (page)="handlePageEvent($event)"></mat-paginator>
            </div>
        </div>
    </div>
</div>
