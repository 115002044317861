import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Job } from 'src/app/classes/Job';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { AuthHelper } from 'src/app/helpers/AuthHelper';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';
import { UtilityHelper } from 'src/app/helpers/UtilityHelper';
import { AlertViewComponent } from 'src/app/modals/alert-view/alert-view.component';
import { CreateJobTypeComponent } from 'src/app/modals/create-job-type/create-job-type.component';

@Component({
  selector: 'app-my-job-posts',
  templateUrl: './my-job-posts.component.html',
  styleUrls: ['./my-job-posts.component.scss'],
})
export class MyJobPostsComponent implements OnInit, AfterViewInit {
  activeTab = 'Active Job Post';
  loading = true;
  perPage = 10;
  allLoaded = false;
  page = 0;
  jobPosts: Job[] = [];

  constructor(
    public router: Router,
    private authHelper: AuthHelper,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView('My Job Posts');
    this.redirectJobBoardPage();
  }

  ngAfterViewInit() {
    document
      .getElementById('scrollContent')
      ?.addEventListener('scroll', (event) => {
        let element: HTMLElement = event.target as HTMLElement;
        if (
          element.scrollHeight - element.scrollTop - element.clientHeight <=
          50
        ) {
          console.log('We are deep down, time to load more.');
          if (!this.loading && !this.allLoaded) {
            this.page++;
            this.loadJobPosts();
          }
        }
      });
  }

  reloadJobPosts() {
    this.allLoaded = false;
    this.page = 0;
    this.jobPosts = [];
    this.loadJobPosts();
  }

  loadJobPosts() {
    console.log('Loading job posts');
    this.loading = true;
    let lastDocumentId = '';
    if (this.jobPosts.length > 0) {
      lastDocumentId = this.jobPosts[this.jobPosts.length - 1].jobPostNumber;
    }
    console.log('loading job posts. about to hit database helper');
    DatabaseHelper.instance
      .getUserJobPosts(this.user.uid, this.perPage, lastDocumentId)
      .then((val) => {
        console.log('job posts loaded from database helper', val);
        if (val.jobPosts && val.jobPosts.length < this.perPage)
          this.allLoaded = true;
        this.jobPosts = this.jobPosts.concat(val);
        this.loading = false;
      });
    console.log('jobPosts', this.jobPosts);
  }

  async redirectJobBoardPage() {
    if (await this.authHelper.isLoggedIn()) {
      // redirect to job board page by user type
      const userType = this.user.type;
      if (userType == 'Installer') {
        this.router.navigateByUrl('my-job-bids');
      } else {
        this.loadJobPosts();
      }
    }
  }

  toggleSelectMobile() {
    document.querySelector('.main-tab-menu')?.classList.toggle('mobile-active');
  }

  removeSelectMobile() {
    document.querySelector('.main-tab-menu')?.classList.remove('mobile-active');
  }

  openCreateJobDialog() {
    // this.router.navigateByUrl('job-board-post-no-visit')

    // Site flow selector - not in scope for phase 1
    this.dialog.open(CreateJobTypeComponent, {
      disableClose: true,
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      maxHeight: '-webkit-fill-available',
      position: { bottom: '0', left: this.isPhone ? '0' : '20vw' },
      enterAnimationDuration: '0ms',
      panelClass: ['profile-modal', 'no-padding'],
      id: 'create-job',
    });
  }

  editJobPost(job: Job) {
    this.router.navigateByUrl('job-board-post-no-visit', {
      state: { job: job },
    });
  }

  removeJobPost(job: Job) {
    this.dialog.open(AlertViewComponent, {
      maxWidth: '400px',
      panelClass: ['alert-modal', 'no-padding'],
      id: 'alert-modal',
      data: {
        title: 'Delete Job Post?',
        message: 'Are you sure you want to Delete this job post?',
        primaryButton: 'Yes, Delete',
        secondaryButton: 'No',
      },
    });
    DatabaseHelper.instance.dataCallbacks.alertViewCallback = (
      button: string,
    ) => {
      if (button === 'Yes, Delete') {
        job.postStatus = 'Deleted';
        this.loading = true;
        DatabaseHelper.instance.submitJobPost(job, true, false).then((val) => {
          this.reloadJobPosts();
        });
      }
    };
  }

  get activeJobPosts() {
    console.log('jobPosts', this.jobPosts);
    return this.jobPosts.filter(
      (job) =>
        job.postStatus === 'Active' &&
        job.closingDateTime > new Date().getTime(),
    );
  }

  get draftJobPosts() {
    return this.jobPosts.filter((job) => job.postStatus === 'Draft');
  }

  get closedJobPosts() {
    return this.jobPosts.filter(
      (job) =>
        job.postStatus === 'Closed' ||
        job.closingDateTime <= new Date().getTime(),
    );
  }

  openJob(job: Job) {
    this.router.navigateByUrl('/my-job-post-details', { state: { job: job } });
  }

  get user() {
    return AuthHelper.instance.user;
  }

  get isPhone() {
    return UtilityHelper.isPhone();
  }

  get utilityHelper() {
    return UtilityHelper;
  }
}
