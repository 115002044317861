import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';
import { UtilityHelper } from 'src/app/helpers/UtilityHelper';
import { SearchComponent } from 'src/app/modals/search/search.component';

@Component({
  selector: 'app-product-industry',
  templateUrl: './product-industry.component.html',
  styleUrls: ['./product-industry.component.scss'],
})
export class ProductIndustryComponent implements OnInit {
  searchTerm = '';

  constructor(
    private router: Router,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView('Marketplace');
  }

  searchModal() {
    DatabaseHelper.instance.dataCallbacks.dataCallback = (search: string) => {
      this.router.navigateByUrl('marketplace', {
        state: { searchTerm: search },
      });
    };
    this.dialog.open(SearchComponent, {
      disableClose: true,
      height: '100vh',
      width: '100vh',
      maxWidth: '100vw',
      maxHeight: '-webkit-fill-available',
      position: { bottom: '0', left: '0' },
      enterAnimationDuration: '0',
      panelClass: ['profile-modal', 'grey-bg', 'no-scroll'],
      id: 'search-modal',
      autoFocus: false,
      data: { searchTerm: this.searchTerm },
    });
  }

  searchProducts() {
    AnalyticsHelper.instance.logEvent('search_product', {
      search_term: this.searchTerm,
    });
    this.router.navigateByUrl('marketplace', {
      state: { searchTerm: this.searchTerm },
    });
  }

  browseAll() {
    this.router.navigateByUrl('marketplace');
  }

  selectIndustry(industry: string) {
    this.router.navigate(['marketplace'], {
      queryParams: { industry },
    });
  }

  get isPhone() {
    return UtilityHelper.isPhone();
  }

  get isClientView() {
    return DatabaseHelper.instance.isClientView();
  }
}
