import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EstimateJobItem } from 'src/app/classes/EstimateJobItem';
import { Product } from 'src/app/classes/Product';
import { ProductCollection } from 'src/app/classes/ProductCollection';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { AuthHelper } from 'src/app/helpers/AuthHelper';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';
import { EstimateQuestionsHelper } from 'src/app/helpers/EstimateQuestionsHelper';
import { UtilityHelper } from 'src/app/helpers/UtilityHelper';

@Component({
  selector: 'app-estimation-steps-product',
  templateUrl: './estimation-steps-product.component.html',
  styleUrls: ['./estimation-steps-product.component.scss']
})
export class EstimationStepsProductComponent implements OnInit {

  selectedProductCode = "";
  product: Product = new Product();
  collection: ProductCollection = new ProductCollection();
  loading = true;
  selectingProduct = false;
  hoverStyle = "";
  showHover = false;

  constructor(private router: Router, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data : any) {
    console.log('Neil data:'+this.data);
    if(this.data)
    {
      this.collection = this.data.productCollection;
      console.log('Neil Collection:'+this.collection);
      this.selectedProductCode = this.collection.products[0].productCode;
      this.loadProduct();
    } else {
      this.router.navigateByUrl('/estimation');
    }
  }

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView("Estimation Product Details Modal");
  }


  loadProduct() {
    // this.loading = true;
    DatabaseHelper.instance.getProduct(this.selectedProductCode).then(val => {
      this.product = val;
      this.product.retailPrice = UtilityHelper.formatPrice(UtilityHelper.parsePrice(this.product.supplierUnitPrice)*(this.product.margin ? +this.product.margin : 1.28));
      this.loading = false;
    });
  }

  selectStyle(style: any) {
    this.selectedProductCode = style.productCode;
    this.loadProduct();
  }

  selectProduct()  {
    this.selectingProduct = true

    // add selection to user data
    const currentSelection = this.user.estimationCart.jobItems
    let jobItem = new EstimateJobItem();
    jobItem.product = this.product;
    jobItem.questions = EstimateQuestionsHelper.mapQuestionPerCategory(this.product);
    this.user.estimationCart.jobItems.push( jobItem )


    //this.user.save().then(val=>{
      // close the dialog
      this.dialog.getDialogById("estimation-steps-product")?.close();
      this.dialog.getDialogById('estimation-steps-view-product')?.addPanelClass("animate-out");
      setTimeout(() => {
        this.dialog.getDialogById('estimation-steps-view-product')?.close();
        this.selectingProduct = false
      }, 500);
    //})

  }

  back(){
    this.dialog.getDialogById('estimation-steps-view-product')?.close();
  }

  downloadFile(file: any) {
    window.open(file.fileUrl, "_blank");
  }

  get user() {
    return AuthHelper.instance.user;
  }


}
