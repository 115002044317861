<div class="menu-container">
  <img
    class="logo"
    (click)="goTo('/marketplace')"
    src="../../../assets/images/FLRHUB-Logo_UPDATED.svg"
  />  

  <ng-container *ngIf="!isHomePage">
    <div
      class="menu-item"
      [ngClass]="{ active: active === 'My Hub' }"
      (click)="goTo('/home')"
      gaEvent="my_hub_menu"
      gaCategory="click_event"
    >
      <img class="show-phone" src="../../../assets/images/myhub.png" />My Hub
    </div>
    <div
      class="menu-item"
      [ngClass]="{ active: active === 'Marketplace' }"
      (click)="goTo('/marketplace')"
      gaEvent="marketplace_menu"
      gaCategory="click_event"
    >
      <img class="show-phone" src="../../../assets/images/marketplace.png" />Marketplace
    </div>

    <div *ngIf="user.type === 'Installer' || user.type === 'Admin'"
      class="menu-item"
      [ngClass]="{ active: active === 'Job Board' }"
      (click)="goTo('/job-board')"
      gaEvent="job_board_menu"
      gaCategory="click_event"
    >
      <img class="show-phone" src="../../../assets/images/suitcase.png" />Job Board
    </div>
    <div
      class="menu-item"
      [ngClass]="{ active: active === 'Estimation' }"
      (click)="goTo('/estimation')"
      gaEvent="estimation_menu"
      gaCategory="click_event"
    >
      <img class="show-phone" src="../../../assets/images/ruler.png" />Estimations
    </div>
    <div
      class="menu-item hide-phone"
      [ngClass]="{ active: active === 'Community Hub' }"
      gaEvent="community_hub_menu"
      gaCategory="click_event"
    >
      Community Hub <span class="coming-soon">COMING SOON</span>
    </div>
  </ng-container>

  <div class="spacer"></div>
  <div class="menu-bottom-item">
    <div class="bottom-item">
      <a href="mailto:team@flrhub.com.au" target="_blank" style="color:white;">
        <img class="show-phone" src="../../../assets/images/questions.png"
          gaEvent="enquire_now_menu"
          gaCategory="click_event"
        />Enquire now
      </a>
    </div>
  </div>
  <ul class="menu-links">
    <li><a target="_blank" href="/terms">Terms & Conditions</a></li>
    <li><a target="_blank" href="/privacy">Privacy Policy</a></li>
    <li><a target="_blank" href="/returns">Returns Policy</a></li>
  </ul>
</div>