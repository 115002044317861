import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { LoginComponent } from './pages/login/login.component';
import { SplashComponent } from './pages/splash/splash.component';
import { HomeComponent } from './pages/home/home.component';

import { AngularFireAuthGuardModule } from '@angular/fire/compat/auth-guard';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import {
  AngularFireFunctionsModule,
  REGION,
} from '@angular/fire/compat/functions';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AuthHelper } from './helpers/AuthHelper';
import { HubspotService } from './helpers/HubspotService';
import { DatabaseHelper } from './helpers/DatabaseHelper';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MenuComponent } from './components/menu/menu.component';
import { ProductDetailsComponent } from './pages/product-details/product-details.component';
import { ProductsComponent } from './pages/products/products.component';
import { PipesModule } from './pipes/pipes/pipes.module';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { UtilityHelper } from './helpers/UtilityHelper';
import { SignUpDetailsComponent } from './pages/sign-up-details/sign-up-details.component';
import { SignUpPaymentComponent } from './pages/sign-up-payment/sign-up-payment.component';
import { HeaderComponent } from './components/header/header.component';
import { ProductIndustryComponent } from './pages/product-industry/product-industry.component';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { ABNDirective } from './directives/abn.directive';
import { CartComponent } from './pages/cart/cart.component';
import { ShippingComponent } from './pages/shipping/shipping.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { ReviewComponent } from './pages/review/review.component';
import { OrderSubmittedComponent } from './pages/order-submitted/order-submitted.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { MyHubTabsComponent } from './components/my-hub-tabs/my-hub-tabs.component';
import { OrderDetailsComponent } from './pages/order-details/order-details.component';
import { ProfileComponent } from './modals/profile/profile.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { EditProfileComponent } from './modals/edit-profile/edit-profile.component';
import { TermsComponent } from './pages/terms/terms.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { ReturnsComponent } from './pages/returns/returns.component';
import { DeliveryComponent } from './pages/delivery/delivery.component';
import { CanActivateAccountActive } from './guards/CanActivateAccountActive';
import { AccountPendingComponent } from './pages/account-pending/account-pending.component';
import { AccountDisabledComponent } from './pages/account-disabled/account-disabled.component';
import { AccountCancelledComponent } from './pages/account-cancelled/account-cancelled.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { SignUpCompanyDetailsComponent } from './pages/sign-up-company-details/sign-up-company-details.component';
import { AccountComponent } from './modals/account/account.component';
import { AlertViewComponent } from './modals/alert-view/alert-view.component';
import { SearchComponent } from './modals/search/search.component';
import { FiltersComponent } from './modals/filters/filters.component';
import { OrderUpdatedComponent } from './pages/order-updated/order-updated.component';
import { OrderConfirmedComponent } from './pages/order-confirmed/order-confirmed.component';
import { PaymentsHelper } from './helpers/PaymentsHelper';
import { LandingComponent } from './pages/landing/landing.component';
import { CreditCardComponent } from './modals/credit-card/credit-card.component';
import { CreditCardDirective } from './directives/cc-number.directive';
import { CreditCardExpiryDirective } from './directives/cc-expiry.directive';
import { EstimationComponent } from './pages/estimation/estimation.component';
import { EstimationStepsComponent } from './modals/estimation-steps/estimation-steps.component';
import { EstimationStepsProductsComponent } from './modals/estimation-steps-products/estimation-steps-products.component';
import { EstimationStepsProductComponent } from './modals/estimation-steps-product/estimation-steps-product.component';
import { EstimationsComponent } from './pages/estimations/estimations.component';
import { EstimationTermsComponent } from './pages/estimation-terms/estimation-terms.component';
import { EstimationSubmittedComponent } from './pages/estimation-submitted/estimation-submitted.component';
import { EstimationDetailsComponent } from './modals/estimation-details/estimation-details.component';
import { JobBoardComponent } from './pages/job-board/job-board.component';
import { JobBoardBuilderComponent } from './pages/job-board-builder/job-board-builder.component';
import { JobBoardInstallerComponent } from './pages/job-board-installer/job-board-installer.component';
import { CreateJobTypeComponent } from './modals/create-job-type/create-job-type.component';
import { JobBoardPostVisitComponent } from './pages/job-board-post-visit/job-board-post-visit.component';
import { JobBoardPostNoVisitComponent } from './pages/job-board-post-no-visit/job-board-post-no-visit.component';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { AnalyticsHelper } from './helpers/AnalyticsHelper';
import { MyJobPostsComponent } from './pages/my-job-posts/my-job-posts.component';
import { MyJobBidsComponent } from './pages/my-job-bids/my-job-bids.component';
import { JobBidDetailsComponent } from './modals/job-bid-details/job-bid-details.component';
import { MyJobPostDetailsComponent } from './pages/my-job-post-details/my-job-post-details.component';
import { JobDetailsComponent } from './pages/job-details/job-details.component';
import { NgOptimizedImage } from '@angular/common';
import { AccountCreatedComponent } from './pages/account-created/account-created.component';
import { EstimatorConfirmationComponent } from './pages/estimator-confirmation/estimator-confirmation.component';
import { ProductService } from './services/product.service';
import { OrderService } from './services/order.service';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { CustomerJobPostsComponent } from './pages/customer-job-posts/customer-job-posts.component';
import { JobBoardTabsComponent } from './components/job-board-tabs/job-board-tabs.component';
import { CustomerJobDetailComponent } from './pages/customer-job-detail/customer-job-detail.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    SplashComponent,
    HomeComponent,
    MenuComponent,
    ProductDetailsComponent,
    ProductsComponent,
    SignUpComponent,
    SignUpDetailsComponent,
    SignUpPaymentComponent,
    HeaderComponent,
    ProductIndustryComponent,
    NumbersOnlyDirective,
    CreditCardDirective,
    CreditCardExpiryDirective,
    ABNDirective,
    CartComponent,
    ShippingComponent,
    PaymentComponent,
    ReviewComponent,
    OrderSubmittedComponent,
    OrdersComponent,
    MyHubTabsComponent,
    OrderDetailsComponent,
    ProfileComponent,
    EditProfileComponent,
    TermsComponent,
    PrivacyComponent,
    ReturnsComponent,
    DeliveryComponent,
    AccountPendingComponent,
    AccountCreatedComponent,
    AccountDisabledComponent,
    AccountCancelledComponent,
    SignUpCompanyDetailsComponent,
    AccountComponent,
    AlertViewComponent,
    SearchComponent,
    FiltersComponent,
    OrderUpdatedComponent,
    OrderConfirmedComponent,
    LandingComponent,
    CreditCardComponent,
    EstimationComponent,
    EstimationStepsComponent,
    EstimationStepsProductsComponent,
    EstimationStepsProductComponent,
    EstimationsComponent,
    EstimationTermsComponent,
    EstimationSubmittedComponent,
    EstimationDetailsComponent,
    JobBoardComponent,
    JobBoardBuilderComponent,
    JobBoardInstallerComponent,
    CreateJobTypeComponent,
    JobBoardPostVisitComponent,
    JobBoardPostNoVisitComponent,
    MyJobPostsComponent,
    MyJobBidsComponent,
    JobBidDetailsComponent,
    MyJobPostDetailsComponent,
    JobDetailsComponent,
    EstimatorConfirmationComponent,
    CustomerJobPostsComponent,
    JobBoardTabsComponent,
    CustomerJobDetailComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    NgxGoogleAnalyticsModule.forRoot(environment.firebase.measurementId),
    AngularFireAuthModule,
    AngularFireAuthGuardModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    PipesModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    GooglePlaceModule,
    NgOptimizedImage,
    MatCheckboxModule,
    MatDividerModule,
    MatListModule,
    MatExpansionModule,
  ],
  providers: [
    AuthHelper,
    HubspotService,
    DatabaseHelper,
    PaymentsHelper,
    UtilityHelper,
    AnalyticsHelper,
    ProductService,
    OrderService,
    CanActivateAccountActive,
    { provide: REGION, useValue: 'australia-southeast1' },
    { provide: MAT_DIALOG_DATA, useValue: null },
    { provide: MatDialogRef, useValue: null },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: 'googleTagManagerId', useValue: environment.gtmId },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
