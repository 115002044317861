<div class="page-container">
  <div class="content-container">
    <app-header title="Choose a product" handleBackButton="true" (onBack)="back()" class="titleOnly"></app-header>
    <mat-spinner class="page-loading" *ngIf="loading" diameter="35" strokeWidth="1"></mat-spinner>
    <div class="content hide-scrollbar" *ngIf="!loading">
      <div class="image-column hide-phone">
        <img [src]="product.fullCoverImageUrl | safeUrl">
        <img class="hover-style" [ngClass]="{hide: !showHover}" [src]="hoverStyle">
      </div>
      <div class="content-column">
        <div class="small-button hide-phone" (click)="back()">Back</div>
        <h1 class="title">{{product.productCollection}}</h1>
        <p class="category"
          [innerHTML]="product.brand + (product.subBrand ? ' - '+product.subBrand : '') +' | '+ (product.productCategory) | safeHtml">
        </p>
        <div class="price" *ngIf="product.retailPrice">{{product.retailPrice}}/m<sup
            style="align-self: flex-start;">2</sup></div>
        <p class="description">{{product.productDescription}} {{product.productTypeIso}} {{product.chemicalResistance}}
        </p>

        <div class="image-column show-phone">
          <img [src]="product.fullCoverImageUrl | safeUrl">
          <img class="hover-style" [ngClass]="{hide: !showHover}" [src]="hoverStyle">
        </div>

        <div class="files hide-scrollbar">
          <div class="secondary-button" *ngFor="let file of product.files" (click)="downloadFile(file)"><img
              class="icon" src="../../../assets/images/download_icon.png">{{file.fileType}}</div>
        </div>

        <div class="selected-style">{{product.productDesign}}{{product.variations['Size'] ? ' - '+product.variations['Size'].option : ''}}</div>
        <div class="styles-container hide-scrollbar">
          <img *ngFor="let style of collection.products" (click)="selectStyle(style)" class="style"
            (mouseover)="hoverStyle = style.fullThumbnailImageUrl; showHover = true" (mouseout)="showHover = false"
            [ngClass]="{selected: product.productCode === style.productCode}" [src]="style.thumbnailImageUrl">
        </div>

        <div class="cta">
          <div class="primary-button" [ngClass]="{loading: selectingProduct}" (click)="selectProduct()">Select</div>
        </div>

      </div>
    </div>
  </div>
</div>