import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartItem } from 'src/app/classes/CartItem';
import { Order } from 'src/app/classes/Order';
import { Product } from 'src/app/classes/Product';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { AuthHelper } from 'src/app/helpers/AuthHelper';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';
import { UtilityHelper } from 'src/app/helpers/UtilityHelper';
import { User } from 'src/app/classes/User';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {
  errors: any = {};
  discountBoxExpanded = false;
  loadingDiscountCode = false;
  discountCode = '';

  loading = true;

  addingWeldRod = false;
  hasMulticolouredWeldRods = ['Tarkett'];
  weldRodEditing = -1;
  user: User = new User('');
  public productClass = Product;

  constructor(private router: Router) {}

  ngOnInit(): void {
    //this.initOrder();
    AnalyticsHelper.instance.pageView('Cart');

    AuthHelper.instance.isLoggedIn().then(() => {
      this.user = AuthHelper.instance.user;

      this.updateDiscountCodeData();
      console.log('in ngOnInit in cart component');
      console.log('this.user', this.user);
      console.log('this.user.cart', this.user.cart);
      if (this.user.cart.length > 0) {
        this.user.cart.forEach((cartItem) => {
          if (cartItem.product.unitType === 'Box') {
            cartItem.quantity = this.calculateBoxQuantity(cartItem);
          }
          this.updateItemTotal(cartItem);
        });
      }
      this.loading = false;
    });
  }

  updateDiscountCodeData() {
    //Refresh the discount that's been applied
    if (this.order.discountData) {
      this.order.applyDiscountCode(this.order.discountData);
    }
  }

  //  What was the point of this when promises exist?
  // initOrder() {
  //   if (this.loading) {
  //     setTimeout(() => {
  //       this.initOrder();
  //     }, 100);
  //   }
  // }

  checkout() {
    this.user.cart.forEach((el) => {
      //For each cut check to see if the quantity is 0 and remove the cut if it is
      if (el.product.cuts) {
        el.product.cuts.forEach((cut) => {
          console.log('CHECKING CUTS = ', cut);
          if (!cut || cut === 0) {
            el.product.cuts.splice(el.product.cuts.indexOf(cut), 1);
          }
        });
      }
      el.lineItems = el.lineItems.filter((el2) => el2.quantity !== 0);
    });
    this.user.currentOrder.fulfilmentStatus = 'Checkout';
    this.saveUser();
    this.router.navigateByUrl('shipping');
  }

  continueShopping() {
    this.router.navigateByUrl('industry');
  }

  deleteCut(index: number, cartItem: CartItem) {
    //Remove the cut from this.product.cuts at the specified index
    cartItem.product.cuts.splice(index, 1);
    this.calculateBoxQuantity(cartItem);
    this.updateItemTotal(cartItem);
  }

  updateItemTotal(cartItem: CartItem) {
    console.log('UDPAING TOTAL START! = ' + cartItem.total);
    if (cartItem.product.unitType === 'LM') {
      console.log('CUT ADDING NOW!');
      // this.quantity = this.product.cuts.
      //Update the total quantity with cuts total
      var totalQuantity = 0;
      cartItem.product.cuts.forEach((cut) => {
        if (cut > 0) {
          totalQuantity = totalQuantity + cut;
        }
      });
      cartItem.quantity = +(+totalQuantity).toFixed(3);
    }
    let finalQuantity =
      cartItem.product.unitType === 'Box'
        ? this.calculateBoxQuantity(cartItem)
        : cartItem.quantity;
    if (cartItem.product.unitType === 'LM') {
      console.log('UDPAING TOTAL NOW! = ' + cartItem.product.cuts);
      cartItem.total = UtilityHelper.formatPrice(
        finalQuantity *
          UtilityHelper.parsePrice(
            Product.calculateRetailCost(cartItem.product, cartItem.quantity),
          ) +
          this.calculateLmCutPrice(cartItem.product),
      );
      console.log('UDPAING TOTAL AFTER! = ' + cartItem.total);
    } else if (this.isLessThanARoll(cartItem)) {
      cartItem.total = UtilityHelper.formatPrice(
        4 * cartItem.quantity +
          cartItem.quantity *
            UtilityHelper.parsePrice(
              Product.calculateRetailCost(cartItem.product, cartItem.quantity),
            ),
      );
    } else {
      console.log(
        'My CART = ' +
          JSON.stringify(cartItem) +
          ' AND ' +
          cartItem.constructor.name,
      );
      cartItem.total = UtilityHelper.formatPrice(
        finalQuantity *
          UtilityHelper.parsePrice(
            Product.calculateRetailCost(cartItem.product, cartItem.quantity),
          ),
      );
    }
    this.updateDiscountCodeData();
  }

  saveUser() {
    let i = this.user.cart.findIndex(
      (el) =>
        el.quantity === 0 &&
        (el.lineItems.length === 0 ||
          el.lineItems.length ===
            el.lineItems.filter((el2) => el2.quantity === 0).length),
    );
    if (i >= 0) {
      this.user.cart.splice(i, 1);
    }
    this.user.cart.forEach((el) => {
      if (el.product.unitType === 'Box') {
        el.quantity = this.calculateBoxQuantity(el);
      }
      CartItem.calculateTotal(el);
      CartItem.calculateSupplierTotal(el);
      CartItem.calculateSupplierTotalWithGst(el);
      el.lineItems.forEach((item) => {
        CartItem.calculateTotal(item);
        CartItem.calculateSupplierTotal(item);
        CartItem.calculateSupplierTotalWithGst(item);
      });
    });
    this.updateDiscountCodeData();
    this.user.save();
  }

  deleteCartItem(index: number) {
    this.user.cart.splice(index, 1);
    this.updateDiscountCodeData();
    this.saveUser();
  }

  applyDiscountCode() {
    if (!this.discountCode) {
      this.errors.discountCode = true;
      return;
    }
    AnalyticsHelper.instance.logEvent('discount_code_entered', {
      discount_code: this.discountCode,
    });
    this.loadingDiscountCode = true;
    DatabaseHelper.instance
      .applyCouponToOrder(this.user, this.discountCode)
      .then((val) => {
        if (val.error) {
          this.errors.discountCode = true;
        } else {
          this.discountBoxExpanded = false;
          this.order.applyDiscountCode(val);
        }
      })
      .catch(() => {
        this.errors.discountCode = true;
      })
      .finally(() => (this.loadingDiscountCode = false));
  }

  calculateBoxQuantity(item: CartItem) {
    if (item.product.unitType === 'Box') {
      if (!item.quantity || item.quantity === 0) {
        return 0;
      } else {
        return UtilityHelper.roundNearest(
          item.quantity,
          this.surfacePerBox(item),
        );
      }
    }
    return 0;
  }

  surfacePerBox(item: CartItem): number {
    return +item.product.surfacePerBox;
  }

  isLessThanARoll(item: CartItem) {
    if (item.product.unitType === 'Roll') {
      if (
        item.quantity &&
        item.quantity < (+item.product.width * +item.product.length) / 10000
      ) {
        return true;
      }
    }
    return false;
  }

  //  get user() {
  //    return AuthHelper.instance.user;
  //  }

  get order() {
    return this.user.currentOrder;
  }

  //  get loading() {
  //    return !AuthHelper.instance.userLoaded;
  //  }

  weldRodRequired(product: Product) {
    return Array.isArray(product.addOns) && product.addOns;
    //return UtilityHelper.weldRodRequired(product);
  }

  editWeldRod(i: number) {
    if (this.weldRodEditing === i) {
      this.weldRodEditing = -1;
      this.saveUser();
    } else {
      this.weldRodEditing = i;
    }
  }

  createWeldRodCartItem(product: Product): CartItem {
    let weldRodProduct = new Product();

    weldRodProduct.productDesign = product.productDesign;
    //weldRodProduct.retailPrice = '$101.12';
    weldRodProduct.retailPrice = product.addOns[0].retailPrice;
    weldRodProduct.supplierUnitPrice = product.addOns[0].supplierUnitPrice;
    weldRodProduct.productCollection = product.addOns[0].productCollection;
    weldRodProduct.unitType = product.addOns[0].unitType;
    weldRodProduct.color = product.addOns[0].color;
    weldRodProduct.brand = product.brand;
    let weldRodCartItem = new CartItem();
    weldRodCartItem.product = weldRodProduct;
    weldRodCartItem.quantity = 0;
    CartItem.calculateTotal(weldRodCartItem);
    CartItem.calculateSupplierTotal(weldRodCartItem);
    CartItem.calculateSupplierTotalWithGst(weldRodCartItem);
    return weldRodCartItem;
  }

  populateCuts(product: Product) {
    if (!product.cuts || product.cuts.length <= 0) {
      product.cuts = [''];
    }
    return true;
  }

  calculateLmCutPrice(product: Product) {
    var totalCutPrice = 0;
    var supplierCutFee = 0;
    var supplierRollFee = 0;
    var supplierCutFeeQuantity = 0;
    var supplierRollFeeQuantity = 0;
    product.cuts.forEach((cut) => {
      if (cut < 30 && cut > 0) {
        totalCutPrice = totalCutPrice + cut * 11 + 16.5;
        supplierCutFee = supplierCutFee + cut * 9;
        supplierRollFee = supplierRollFee + 13.5;
        supplierCutFeeQuantity = supplierCutFeeQuantity + cut;
        supplierRollFeeQuantity = supplierRollFeeQuantity + 1;
      }
    });
    product.cutPrice = totalCutPrice;
    product.supplierCutFee = supplierCutFee.toFixed(2);
    product.supplierRollFee = supplierRollFee.toFixed(2);
    product.supplierCutFeeQuantity = supplierCutFeeQuantity;
    product.supplierRollFeeQuantity = supplierRollFeeQuantity;
    return +totalCutPrice.toFixed(2);
  }

  addCutButtonPressed(product: Product) {
    product.cuts.push('');
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  addWeldRodForProduct(item: CartItem) {
    item.lineItems.push(this.createWeldRodCartItem(item.product));
    this.weldRodEditing = this.user.cart.indexOf(item);
    this.updateDiscountCodeData();
    this.user.save();
  }

  removeWeldRodForProduct(item: CartItem) {
    let weldRodIndex = item.lineItems.findIndex(
      (i) => i.product.productCollection === 'Weld Rod',
    );
    if (weldRodIndex >= 0) {
      item.lineItems.splice(weldRodIndex, 1);
    }
    this.weldRodEditing = -1;
    this.updateDiscountCodeData();
    this.user.save();
  }

  toggleWeldRod(cartItem: CartItem) {
    if (this.addedWeldRodForProduct(cartItem)) {
      this.removeWeldRodForProduct(cartItem);
    } else {
      this.addWeldRodForProduct(cartItem);
    }
  }

  unitTypeToMeasure(unitType: string) {
    return UtilityHelper.unitTypeToMeasure(unitType);
  }

  addedWeldRodForProduct(item: CartItem) {
    let weldRodIndex = item.lineItems.findIndex(
      (i) => i.product.productCollection === 'Weld Rod',
    );
    if (weldRodIndex >= 0) {
      return item.lineItems[weldRodIndex];
    }
    return null;
  }

  get isClientView() {
    return DatabaseHelper.instance.isClientView();
  }
}
