<div class="page-container">
  <app-menu active="My Hub" *ngIf="!isChoosingEstimation()"></app-menu>
  <div class="content-container">
    <app-header title="My Hub" *ngIf="!isChoosingEstimation()"></app-header>
    <app-my-hub-tabs
      active="Estimations"
      *ngIf="!isChoosingEstimation()"
    ></app-my-hub-tabs>
    <div
      class="filters"
      *ngIf="
        !loading &&
        estimations &&
        estimations.length > 0 &&
        !isChoosingEstimation()
      "
    >
      <div
        (click)="filterClicked('All')"
        class="filter"
        [ngClass]="{ selected: filter == 'All' }"
      >
        All
      </div>
      <div
        (click)="filterClicked('Pending')"
        class="filter"
        [ngClass]="{ selected: filter == 'Pending' }"
      >
        Pending
        <div
          class="pending-count"
          [ngClass]="{ selected: filter == 'Pending' }"
        >
          {{ pendingEstimationsLength }}
        </div>
      </div>
    </div>
    <!-- <div id="scrollContent" class="content" *ngIf="!loading && estimations.length > 0"> -->
    <div class="header" *ngIf="isChoosingEstimation()">
      <div class="header-title">Choose an estimate</div>
      <div class="spacer"></div>
      <img
        class="close-icon"
        src="../../../assets/images/close_icon.png"
        (click)="close()"
      />
    </div>
    <div id="scrollContent" class="content">
      <div
        class="estimation-cell"
        *ngFor="let estimate of estimations"
        (click)="viewEstimate(estimate)"
      >
        <div class="estimation-cell-header">
          <h2 class="estimation-cell-title">{{ estimate.jobName }}</h2>
          <div
            *ngIf="estimate.estimationStatus == 'PENDING'"
            class="status-label"
            [ngClass]="{ highlighted: estimate.estimationStatus == 'PENDING' }"
          >
            <p>{{ estimate.estimationStatus }}</p>
          </div>
        </div>
        <p>
          {{
            estimate.estimationStatus == "PENDING" ? "Submitted" : "Completed"
          }}
          {{ estimate.estimationDate | formattedDate }}
        </p>
      </div>
    </div>
    <mat-spinner
      class="loading"
      *ngIf="loading"
      diameter="35"
      strokeWidth="1"
    ></mat-spinner>
    <div
      *ngIf="!loading && estimations && estimations.length === 0"
      class="empty-placeholder"
    >
      <div class="message">You haven't requested any estimations yet</div>
      <div class="secondary-button" (click)="goTo('/estimation')">
        Request an estimation
      </div>
    </div>
  </div>
</div>
