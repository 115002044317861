<!-- <app-job-board-builder *ngIf="this.user.type == 'Builder'"></app-job-board-builder>
<app-job-board-installer *ngIf="this.user.type == 'Installer' || this.user.type === 'Admin'"></app-job-board-installer> -->
<div class="page-container">
  <app-menu active="Job Board"></app-menu>
  <div class="content-container">
    <app-header title="Job Board"></app-header>
    <app-job-board-tabs active="Customer Jobs"></app-job-board-tabs>

    <div
      *ngIf="this.user.type == 'Installer' || this.user.type == 'Admin'"
      class="inner-container"
    >
      <div
        class="filters-container hide-scrollbar hide-phone"
        [ngClass]="{ hideFilters: hideFilters }"
      >
        <p *ngIf="loadingFilters">Loading filters...</p>
        <div *ngIf="!loadingFilters">
          <div
            class="filter-option"
            [ngClass]="{ expanded: filters[filter].expanded }"
            *ngFor="let filter of keysForObject(filters)"
            (click)="filters[filter].expanded = !filters[filter].expanded"
          >
            <div class="filter-label">{{ filter }}</div>
            <img
              class="arrow-icon"
              src="../../../assets/images/down_arrow.png"
            />
            <!-- <div class="options-container" [style]="'height: fit-content'"> -->
            <div
              class="options-container"
              [style]="
                'height: ' + getSubItems(filters[filter].items) * 40 + 'px;'
              "
            >
              <div
                class="options"
                *ngFor="let item of filters[filter].items"
                [ngClass]="{
                  'expanded-sub-option': item.inputField && item.selected
                }"
              >
                <div
                  class="flex"
                  (click)="
                    filterSelected(filter, item); $event.stopPropagation()
                  "
                >
                  <div
                    class="check-box"
                    [ngClass]="{ selected: item.selected }"
                  >
                    <img src="../../../assets/images/check_icon.png" />
                  </div>
                  <div class="item-name">{{ item.name }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="secondary-button small-button clear-filters-button"
          *ngIf="filterIsSelected()"
          (click)="clearAllFilter()"
        >
          <img class="icon" src="../../../assets/images/close_icon.png" />Clear
          all filters
        </div>
      </div>
      <div id="scrollContent" class="content">
        <div class="search-container">
          <div
            class="secondary-button small-button show-phone filter-button"
            (click)="filtersModal()"
          >
            <img class="icon" src="../../../assets/images/filter_icon.png" />
            Filters
          </div>
          <div
            class="filter-toggle hide-phone"
            [ngClass]="{ filtersShowing: !hideFilters }"
            (click)="hideFilters = !hideFilters"
          >
            <img src="../../../assets/images/filter_icon.png" />{{
              hideFilters ? "Show" : "Hide"
            }}
            Filters
          </div>
          <!-- <div class="sort-selector">Sort<img src="../../../assets/images/down_arrow.png"></div> -->
        </div>
        <div class="jobs-container">
          <div
            class="job-card"
            *ngFor="let job of jobs; let i = index"
            (click)="openJob(job)"
          >
            <div class="inner-card">
              <div class="card-head">
                <span class="color-orange heading"
                  >{{ job.jobName }}
                  <div *ngIf="job.urgency === 'Urgent'" class="urgent">
                    URGENT
                  </div>
                  <div *ngIf="job.urgency === 'Quick Start'" class="quick">
                    Quick Start
                  </div>
                </span>
                <br />
                <span class="company-name">{{ job.company }}</span>
                <span class="posted small"
                  >Posted
                  {{ utilityHelper.formatDate(job.submittedDate) }}</span
                >
              </div>

              <div class="card-description">
                {{ job.jobDescription }}
              </div>
              <!-- card boxes -->

              <div class="card-footer">
                <div class="location">
                  <img
                    src="../../../assets/images/location.png"
                    width="12"
                    height="17"
                    alt="Location"
                  />
                  {{ job.suburb }}, {{ job.state }}
                </div>
                <div class="category" *ngFor="let item of job.materialType">
                  {{ item }}
                </div>
                <div class="category">{{ job.industry }}</div>
                <div class="category">{{ job.jobSize }}</div>
              </div>
              <!-- card-footer -->
            </div>
          </div>
          <!-- job-card -->
        </div>
        <div *ngIf="!loading && jobs.length === 0" class="loading">
          No customer job posts available
        </div>
        <mat-spinner
          class="loading"
          *ngIf="loading"
          diameter="35"
          strokeWidth="3"
        ></mat-spinner>
      </div>
    </div>
  </div>
</div>
