import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EstimationCart } from 'src/app/classes/EstimationCart';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';
import * as xmlToJson from 'xml2js';

@Component({
  selector: 'app-estimator-confirmation',
  templateUrl: './estimator-confirmation.component.html',
  styleUrls: ['./estimator-confirmation.component.scss']
})
export class EstimatorConfirmationComponent implements OnInit {

  estimation: EstimationCart = new EstimationCart();
  products: string[] = ["", "", ""];
  expandedSection: number = -1;
  loading: boolean = false;
  invalid: boolean = false;

  deletedFiles : any[] = [];

  uploadingCostingSheet: boolean = false;
  uploadingProjectFiles: boolean = false;
  submittingEstimate: boolean = false;

  // Vinyl Sheet/ Linoleum/ Marmoleum
  questionSet1 = ['Linoleum', 'Heterogeneous Vinyl', 'Homogeneous Vinyl', 'Safety Floors', 'Static Control', 'Indoor Sports Vinyl'];
  // Wall Vinyl
  questionSet2 = ['Wallcoverings'];
  /// LVT/ Vinyl Tiles
  questionSet3 = ['Luxury Vinyl Tiles'];
  // Hybrid/ Laminate / Tarkett Click Lock Vinyl Tiles - not in use at the moment
  questionSet4 = ['Hybrid'];
  // Engineered Timber (Currently Multiflex M only)
  questionSet5 = ['Indoor Sports Timber', 'Timber'];
  // Carpet Tiles
  questionSet6 = ['Modular Carpet'];
  // Broadloom Carpet
  questionSet7 = ['Carpet Rolls'];
  //Epoxy Warehouse
  questionSet8 = ['Epoxy Warehouse'];

  //http://localhost:4200/estimator-confirmation?estimate=100006&key=1671080261266&code=SMWdRRJYiZ1tScrE2fhIkAMKr8nnPyKY

  constructor(private router: Router, private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      console.log('params = ' + JSON.stringify(params));
      if (JSON.stringify(params) == '{}') {
        //Error
      } else {
        this.loading = true;
        DatabaseHelper.instance.getEstimate(params['estimate']).then(val => {
          var estimate: any = val;
          var invalidData = true;
          console.log('Estimate::'+estimate);
          console.log('Estimate::'+estimate.timestamp);
          console.log("Comparing = " + estimate.timestamp + " vs " + params['key']);
          if (estimate.timestamp == params['key']) {
            console.log("Comparing = " + estimate.code + " vs " + params['code']);
            if (estimate.code == params['code']) {
              if (estimate.estimateStatus != 'PENDING') {
                this.estimation = estimate;
                if (this.estimation.projectFiles === undefined) {
                  this.estimation.projectFiles = [];
                }
                this.loading = false;
                invalidData = false;
                console.log("Good to view estimate");
              }
            }
          }
        });
      }
    });
  }

  getCompulsoryAddons(product: any) {

    let category = product.product.productCategory
    let subCategory = product.product.subProductCategory
    let brand = product.product.brand
    let productCollection = product.product.productCollection

    // Force map special case for Indoor Sports
    // productCollection = Multiflex M is Timber
    // any other product under Indoor Sports are Vinyl
    if( category == 'Indoor Sports' || subCategory == 'Indoor Sports' ){
      if( productCollection == 'Multiflex M'){
        category = 'Indoor Sports Timber'
      }else{
        category = 'Indoor Sports Vinyl'
      }
    }
    if( category == 'Luxury Vinyl Tiles' || subCategory == 'Luxury Vinyl Tiles' ){
      if( productCollection == 'iD Inspiration Ultimate'){
        category = 'Hybrid'
      }
    }
    if( brand == 'Epoxy Warehouse' ){
      category = 'Epoxy Warehouse'
    }

    if (this.questionSet1.includes(category) || this.questionSet1.includes(subCategory)) {
      return ['Adhesive (Net m2)', 'Feather Finish (Net m2)', 'Weld Rod (Min Box quantity 50lm)', 'Transitions (Between Ceramic tiles, Carpet, ALL PRODUCTS)'];
    }
    else if (this.questionSet2.includes(category) || this.questionSet2.includes(subCategory)) {
      return ['Adhesive (Net m2)', 'Primer (Net m2)', 'Weld Rod (Min Box Quantity 50lm)'];
    }
    else if (this.questionSet3.includes(category) || this.questionSet3.includes(subCategory)) {
      return ['Adhesive (Net m2)', 'Feather Finish (Net m2)', 'Transitions (Between Ceramic tiles, Carpet, ALL PRODUCTS)'];
    }
    else if(this.questionSet4.includes(category) || this.questionSet4.includes(subCategory)) {
       return ['Transitions (Between Ceramic tiles, Carpet, ALL PRODUCTS)','Transitions (Doors windows and external doors)']
    }
    else if (this.questionSet5.includes(category) || this.questionSet5.includes(subCategory)) {
      return ['Transitions (Between Ceramic tiles, Carpet, ALL PRODUCTS)', 'Transitions (Doors windows and external doors)'];
    }
    else if (this.questionSet6.includes(category) || this.questionSet6.includes(subCategory)) {
      return ['Adhesive (Net m2)', 'Transitions (Between Ceramic tiles, Carpet, ALL PRODUCTS)'];
    }
    else if (this.questionSet7.includes(category) || this.questionSet7.includes(subCategory)) {
      return ['Adhesive (Net m2)', 'Transitions (Between Ceramic tiles, Carpet, ALL PRODUCTS)'];
    }
    else if (this.questionSet8.includes(category) || this.questionSet8.includes(subCategory)) {
      return ['Find area (m2) on plans','Find Perimeter (for coving/silicone/vinyl skirt)'];
    }
    else {
      return ['Standard Add-ons'];
    }
  }

  getTitle(product: any) {

    let category = product.product.productCategory
    let subCategory = product.product.subProductCategory
    let brand = product.product.brand
    let productCollection = product.product.productCollection

    // Force map special case for Indoor Sports
    // productCollection = Multiflex M is Timber
    // any other product under Indoor Sports are Vinyl
    if( category == 'Indoor Sports' || subCategory == 'Indoor Sports' ){
      if( productCollection == 'Multiflex M'){
        category = 'Indoor Sports Timber'
      }else{
        category = 'Indoor Sports Vinyl'
      }
    }
    if( category == 'Luxury Vinyl Tiles' || subCategory == 'Luxury Vinyl Tiles' ){
      if( productCollection == 'iD Inspiration Ultimate'){
        category = 'Hybrid'
      }
    }
    if(brand == 'Epoxy Warehouse')
    {
      category = 'Epoxy Warehouse'
    }
    if (this.questionSet1.includes(category) || this.questionSet1.includes(subCategory)) {
      return "Vinyl Sheet/Linoleum/Marmoleum";
    }
    else if (this.questionSet2.includes(category) || this.questionSet2.includes(subCategory)) {
      return "Wall Vinyl";
    }
    else if(this.questionSet3.includes(category) || this.questionSet3.includes(subCategory)) {
       return "Hybrid/Laminate/Click Lock Vinyl Tile";
    }
    else if (this.questionSet4.includes(category) || this.questionSet4.includes(subCategory)) {
      return "LVT/Vinyl Tiles";
    }
    else if (this.questionSet5.includes(category) || this.questionSet5.includes(subCategory)) {
      return "Engineered Timber";
    }
    else if (this.questionSet6.includes(category) || this.questionSet6.includes(subCategory)) {
      return "Carpet Tiles";
    }
    else if (this.questionSet7.includes(category) || this.questionSet7.includes(subCategory)) {
      return "Broadloom Carpet";
    }
    else if (this.questionSet8.includes(category) || this.questionSet8.includes(subCategory)) {
      return "Epoxy";
    }
    else {
      return "Product";
    }
  }

  ngOnInit(): void {
  }

  openUrl(url: string) {
    window.open(url, "_blank");
  }

  uploadCostingSheet(event: any) {
    if (event.target.files[0] === undefined) {
      return
    }
    this.uploadingCostingSheet = true;
    let reader = new FileReader();
    reader.readAsText(event.target.files[0]);
    reader.onload = (e) => {
      const name = event.target.files[0].name;
      DatabaseHelper.instance.uploadFile("EstimationFiles/" + this.estimation.estimationNumber + "/" + name, event.target.files[0]).then(val => {
        this.estimation.costingSheetUrl = val;
        this.processCostingSheet(String(e.target?.result)).catch(err => {
          setTimeout(() => {
            console.error('err:',err);
            // Incase anything goes wrong
            alert("Could not parse XML file. Please try again. Error: " + JSON.stringify(err));
            this.deleteCostingSheet();
          }, 500);
        });
        event.target.value = "";
      });
    };
  }

  //>>>Instructions<<<
  // The StyleName for flooring products must match the product name in the estimation file exactly - ie. "VIN01 - FLRHUB Luxury Vinyl Tile 250x1000mm"
  // Scale Checks can be included and are ignored during processing
  // The RoomID must match the room number in the estimation file exactly
  // The Qty must be a number
  // The SaleUnit must be either "SM" or "LM" for square meters or linear meters respectively


  // The costing sheet must be in XML format
  // The costing sheet must contain the following format:
  // <MeasureExport>
  //   <MeasureItems>
  //     <MeasureItem>
  //       <StyleName>VIN01 - FLRHUB Luxury Vinyl Tile 250x1000mm</StyleName>
  //       <SaleUnit>SM</SaleUnit>
  //       <MeasureUsages>
  //         <MeasureUsage>
  //           <Qty>10</Qty>
  //           <RoomID>1</RoomID>
  //         </MeasureUsage>
  //       </MeasureUsages>
  //     </MeasureItem>
  //   </MeasureItems>
  // </MeasureExport>
  // The MeasureExport tag must be included for each product
  // The MeasureItems tag must be included for each product
  // The MeasureItem tag must be included for each product
  // The MeasureUsage tag must be included for each product
  async processCostingSheet(xml: string) {

    // If reverting to CSV in the future refer to commit '*Costing sheet CSV final commit before converting to XML' for CSV parsing code.

    const rawJsonArray = await xmlToJson.parseStringPromise(xml);
    console.log(rawJsonArray);

    let errors: any[] = [];

    var measureItems = rawJsonArray.MeasureExport.MeasureItems[0].MeasureItem.filter(((e: any) => !e.StyleName.includes("Scale Checked")));
    const roomNumbersMap: Map<string, string> = new Map(); // Map of products for each room number e.g. {"1": "F2"}

    this.estimation.jobItems.forEach(item => {
      console.log("Item code = "+item.code);
      item.code = item.code.split(' ').join('-');
    })
    console.log("Job items = "+JSON.stringify(this.estimation.jobItems));

    // re-order measureItems to put first any items where item.StyleName[0].split(" ")[0] is equal to jobItem.code in this.estimation.jobItems
    let measureItemsOrdered: any[] = [];
    measureItems.forEach((item: any) => {
      let jobItem = this.estimation.jobItems.find(e => e.code === item.StyleName[0].split(" ")[0]);
      if (jobItem) {
        //Push item to the start of measureItemsOrdered
        measureItemsOrdered.unshift(item);
      }
      else
      {
        //Push the item to the end of measureItemsOrdered
        measureItemsOrdered.push(item);
      }
    });
    measureItems = measureItemsOrdered;
    measureItems.forEach((item: any) => {
      let jobItem = this.estimation.jobItems.find(e => e.code === item.StyleName[0].split(" ")[0]);
      let isFloor = false;
      if (jobItem) {
        // This item is a floor because it's one of the products in the job items
        isFloor = true;
      }
      item.MeasureUsages[0].MeasureUsage.forEach((usage: any) => {
        if (isFloor) {
          roomNumbersMap.set(usage.RoomID[0], jobItem!.code);
        } else {
          // Get job item from mapped room number as this item is not a floor
          jobItem = this.estimation.jobItems.find(e => e.code === roomNumbersMap.get(usage.RoomID[0]));
        }
        if (jobItem) {
          const estimateItem = jobItem.estimates.find(e => e.description === (isFloor ? jobItem!.code : item.StyleName[0]));
          if (estimateItem) {
            // Increment the quantity if we have already created the estimate item
            estimateItem.quantity = (parseFloat(estimateItem.quantity) + parseFloat(usage.Qty[0])).toString();//estimateItem.quantity += +usage.Qty[0];
          } else {
            // Create the estimate item
            let unit = "";
            if (item.SaleUnit[0] === "SM") {
              unit = "m²";
            } else if (item.SaleUnit[0] === "LM") {
              unit = "m";
            }
            jobItem.estimates.push({ description: isFloor ? jobItem.code : item.StyleName[0], quantity: (+usage.Qty[0]).toString(), unit: unit });
          }
        } else {
          errors.push("Could not find job item related to product: " + item.StyleName[0]);
        }
      });
    });

    // Check if there was an error
    setTimeout(() => {
      if (errors.length > 0) {
        alert(errors.join('\n'));
        // Clear all job item estimates
        this.deleteCostingSheet();
      } else if (this.estimation.jobItems.some(e => (e.estimates.length === 0))) {
        // No estimates found for a product
        const map = this.estimation.jobItems.filter(e => e.estimates.length === 0).map(e => e.code);
        alert("Missing estimates for the following products:\n" + map.join("\n"));
        this.deleteCostingSheet();
      } else if (this.estimation.jobItems.some(e => !e.estimates.map(f => f.description).includes(e.code))) {
        // Contains sub products but no estimates found for the product itself
        const map = this.estimation.jobItems.filter(e => !e.estimates.map(f => f.description).includes(e.code)).map(e => e.code);
        alert("Missing estimates for the following products:\n" + map.join("\n"));
        this.deleteCostingSheet();
      } else {
        // No error found
        alert("Costing sheet successfully uploaded and processed.");
        console.log(roomNumbersMap);
        console.log(this.estimation.jobItems);
      }
    }, 200);
  }

  deleteCostingSheet() {
    this.uploadingCostingSheet = true;
    let url = this.estimation.costingSheetUrl;
    this.estimation.costingSheetUrl = "";
    DatabaseHelper.instance.deleteFile(url).finally(() => {
      this.estimation.costingSheetUrl = "";
      this.estimation.jobItems.forEach(jobItem => jobItem.estimates = []);
      this.uploadingCostingSheet = false;
    });
  }

  uploadProjectFiles(event: any) {
    if (event.target.files[0] === undefined) {
      return
    }
    this.uploadingProjectFiles = true;
    const promises: any[] = [];
    Array.from(event.target.files).forEach((file: any) => {
      promises.push(new Promise((res: any, rej: any) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          const name = file.name;
          
          DatabaseHelper.instance.uploadFile("EstimationFiles/" + this.estimation.estimationNumber + "/ProjectFiles/" +new Date().getTime()+"_"+ name, file).then(val => {
            this.estimation.projectFiles.push({ name: name, url: val });
            res(true);
          });
        };
      }));
    });
    Promise.all(promises).then(() => {
      this.uploadingProjectFiles = false;
      event.target.value = "";
    });

  }

  deleteProjectFile(url: string) {
    this.deletedFiles.push(url);
    this.estimation.projectFiles.splice(this.estimation.projectFiles.findIndex(e => e.url === url), 1);
  }

  validate() {
    if (!this.estimation.costingSheetUrl) {
      alert("Please upload costing sheet");
      return false;
    } else if (!this.estimation.projectFiles || this.estimation.projectFiles.length === 0) {
      alert("Please upload project files");
      return false;
    }
    return true;
  }

  submit() {
    if (!this.validate()) {
      return;
    }
    this.submittingEstimate = true;
    this.estimation.estimationStatus = "COMPLETED";

    this.uploadingProjectFiles = true;
    this.deletedFiles.forEach(url => {
      DatabaseHelper.instance.deleteFile(url).then(() => {  
        this.uploadingProjectFiles = false;
      });
    });
    this.deletedFiles = [];
    console.log('submitted estimation:'+this.estimation)
    DatabaseHelper.instance.submitEstimation(this.estimation).then(val => {
      if(val.success) {
        alert("Submit the estimate successful");
        window.location.reload();
      } else {
        alert("Submit the estimate failed: " + (val.message || "Unknown error"));
      }
    }).catch(err => {
      alert("Something went wrong when submitting the estimate. Error: " + JSON.stringify(err));
    });
  }

  formatFilename(url: string) {
    const selectedDocHref = url as string
    if (selectedDocHref != '') {
      const clean1 = selectedDocHref.split('/').pop() as string
      const clean2 = clean1.split('#')[0].split('?')[0];
      const clean3 = clean2.replace('FloorPlansFiles%2F', '')
      const clean4 = clean3.indexOf('-')
      const clean5 = clean3.substring(clean4 + 1);
      return clean5;
    }
    return 'Download Floorplan for quoting';
  }

}
