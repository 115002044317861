import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Job } from 'src/app/classes/Job';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { AuthHelper } from 'src/app/helpers/AuthHelper';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';
import { UtilityHelper } from 'src/app/helpers/UtilityHelper';
import { FiltersComponent } from 'src/app/modals/filters/filters.component';

@Component({
  selector: 'app-customer-job-posts',
  templateUrl: './customer-job-posts.component.html',
  styleUrls: ['./customer-job-posts.component.scss']
})
export class CustomerJobPostsComponent implements OnInit {

  hideFilters = false;

  loading = true;
  allLoaded = false;
  perPage = 12;
  page = 0;
  timeout: any;
  pageHasScrolled: boolean = false;
  loadingFilters: boolean = false;

  pageloaded = false;

  jobs: Job[] = [];

  constructor(
    private router: Router,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authHelper: AuthHelper,
  ) { }

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView('Job Board');
    this.loadJobs();
    this.loadFilters();
  }

  ngAfterViewInit() {
    document
      .getElementById('scrollContent')
      ?.addEventListener('scroll', (event) => {
        let element: HTMLElement = event.target as HTMLElement;
        if (
          element.scrollHeight - element.scrollTop - element.clientHeight <=
          50
        ) {
          console.log('We are deep down, time to load more. ' + this.allLoaded);
          if (!this.loading && !this.allLoaded) {
            this.page++;
            this.loadJobs();
          }
        }
      });
  }

  checkForLargeViews() {
    setTimeout(() => {
      var x: any = document.getElementById('scrollContent')?.clientHeight;
      var y: any = document.getElementById('scrollContent')?.scrollHeight;
      console.log('document has scroll: ', y > x);
      if (
        y <= x &&
        !this.pageHasScrolled &&
        this.jobs.length > 0 &&
        !this.allLoaded
      ) {
        //Load more products as the user can't scroll yet
        this.page++;
        this.loadJobs();
      } else {
        this.pageHasScrolled = true;
      }
    }, 1000);
  }

  loadJobs(reload = false) {
    this.loading = true;
    if (reload) {
      this.pageHasScrolled = false;
      this.allLoaded = false;
      this.jobs = [];
    }
    let lastDocumentId = '';
    if (this.jobs.length > 0) {
      lastDocumentId = this.jobs[this.jobs.length - 1].closingDateTime + '';
    }
    const filtersToSend: any = {};
    Object.keys(this.filters).forEach((key) => {
      filtersToSend[key] = this.filters[key].items
        .filter((el) => el.selected)
        .map((el) => el.name);
    });
    console.log('filters to send' + JSON.stringify(filtersToSend));
    console.log(this.perPage, lastDocumentId, filtersToSend);
    DatabaseHelper.instance
      .listCustomerJobPosts(this.perPage, lastDocumentId, filtersToSend)
      .then((val) => {
        console.log('job posts');
        console.log(val.jobPosts);
        if (val.jobPosts.length < this.perPage) this.allLoaded = true;
        this.jobs = this.jobs.concat(val.jobPosts);
        this.loading = false;
        this.checkForLargeViews();
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
      });
  }

  openJob(job: Job) {
    this.router.navigateByUrl('/customer-job-details', { state: { job: job } });
  }

  filtersModal() {
    DatabaseHelper.instance.dataCallbacks.dataCallback2 = (filters: any) => {
      DatabaseHelper.instance.jobBoardFilters = filters;
      this.loadJobs(true);
    };
    this.dialog.open(FiltersComponent, {
      disableClose: true,
      height: '100vh',
      width: '100vh',
      maxWidth: '100vw',
      maxHeight: '-webkit-fill-available',
      position: { bottom: '0', left: '0' },
      enterAnimationDuration: '0',
      panelClass: ['profile-modal', 'grey-bg', 'no-scroll'],
      id: 'filters-modal',
      data: {
        isJobBoard: true,
      },
    });
  }

  loadFilters() {
    this.loadingFilters = true;
    console.log({ filters: this.filters });
    if (this.filters['Material Type'].items.length === 0) {
      this.loadingFilters = false;
      DatabaseHelper.instance.getJobBoardFilters().then((val) => {
        DatabaseHelper.instance.jobBoardFilters['Industries'].items =
          val.filters.industry.map((e: string) => ({
            selected: false,
            name: e,
          }));
        DatabaseHelper.instance.jobBoardFilters['Material Type'].items =
          val.filters.materialTypes.map((e: string) => ({
            selected: false,
            name: e,
          }));
        DatabaseHelper.instance.jobBoardFilters['Location'].items =
          val.filters.location.map((e: string) => ({
            selected: false,
            name: e,
          }));
        DatabaseHelper.instance.jobBoardFilters['Job Size'].items =
          val.filters.jobSizes.map((e: string) => ({
            selected: false,
            name: e,
          }));
        DatabaseHelper.instance.jobBoardFilters['Urgency'].items =
          val.filters.urgency.map((e: string) => ({
            selected: false,
            name: e,
          }));
      });
    } else {
      this.loadingFilters = false;
    }
  }

  getSubItems(items: any) {
    var count = items.length;
    items.forEach((item: any) => {
      if (item.inputField && item.selected) {
        count = count + 2;
      }
    });
    return count;
  }

  filterIsSelected() {
    for (let key in this.filters) {
      for (let item in this.filters[key].items)
        if (this.filters[key].items[item].selected) {
          return true;
        }
    }
    return false;
  }

  clearAllFilter() {
    for (let key in this.filters) {
      for (let item in this.filters[key].items)
        this.filters[key].items[item].selected = false;
    }
    this.loadJobs(true);
  }

  filterSelected(filter: string, item: any) {
    const newFilterState = !item.selected;
    AnalyticsHelper.instance.logEvent('filter_clicked', {
      selected: newFilterState,
      filter: filter,
    });
    this.processFilter(filter);
    item.selected = newFilterState;
    this.loadJobs(true);
  }

  processFilter(filter: string) {
    if (this.filters[filter].singleSelectionOnly) {
      this.clearAllFiltersForGroup(filter);
    }
  }

  clearAllFiltersForGroup(group: string) {
    for (let item in this.filters[group].items)
      this.filters[group].items[item].selected = false;
  }

  keysForObject(object: any) {
    return Object.keys(object);
  }

  get isPhone() {
    return UtilityHelper.isPhone();
  }

  get filters() {
    return DatabaseHelper.instance.jobBoardFilters;
  }

  get user() {
    return AuthHelper.instance.user;
  }

  get utilityHelper() {
    return UtilityHelper;
  }
}

