<div class="page-container">
    <div class="content-container">
        <div *ngIf="!loading && !submittingEstimate" class="content">
        <!-- <div *ngIf="!loading && ((!submittingEstimate && estimation.estimationStatus !== 'COMPLETED') || submittingEstimate)" class="content"> -->
            <div class="small-header">
                <img src="../../../assets/images/logo.png">
                <h1>ESTIMATION SERVICES</h1>
            </div>
            <div class="estimation-content">
                <div class="submitted-content">
                    <div class="download-files">
                        <p class="section-title">Floor Plans</p>
                        <div class="file" *ngIf="estimation.jobDocument">
                            <img src="../../../assets/images/download_icon.png">
                            <a href="{{estimation.jobDocument}}" target="_blank">
                                <p>Download Floorplan for quoting</p>
                            </a>
                        </div>
                        <div class="file" *ngFor="let document of estimation.jobDocuments">
                          <img src="../../../assets/images/download_icon.png" alt="">
                          <a href="{{ document.url }}" target="_blank">{{ formatFilename(document.name) }}</a>
                        </div>
                    </div>
                    <div class="collapsable-product" *ngFor="let product of estimation.jobItems; let i = index;">
                        <p class="section-title">{{getTitle(product)}}</p>
                        <div class="product-row">
                            <div class="product-row-details"
                                (click)="expandedSection == i ? expandedSection = -1 : expandedSection = i">
                                <div class="product-item">
                                    <img src={{product.product.thumbnailImageUrl}}>
                                    <p>{{product.code}} = FLRHUB {{product.product.productCategory}}</p>
                                </div>
                                <div class="collapsable-button">
                                    <img [ngClass]="{flip: expandedSection == i}"
                                        src="../../../assets/images/down-arrow_icon.png">
                                </div>
                            </div>
                            <div [ngClass]="{collapsed: expandedSection != i}">
                            <div class="product-info">
                                <table>
                                    <tr>
                                        <td style="text-align: start;">
                                            <p>Thickness</p>
                                        </td>
                                        <td style="text-align: start;">
                                            <p>{{(+product.product.thickness).toFixed(2)}}mm</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="text-align: start;">
                                            <p>Width</p>
                                        </td>
                                        <td style="text-align: start;">
                                            <p>{{(+product.product.width).toFixed(2)}}cm</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="text-align: start;">
                                            <p>Length</p>
                                        </td>
                                        <td style="text-align: start;">
                                            <p>{{(+product.product.length).toFixed(2)}}cm</p>
                                        </td>
                                    </tr>
                                    <tr *ngIf="product.product.unitType == 'Box'||product.product.unitType == 'Pack'" >
                                        <td style="text-align: start;">
                                            <p>Box Quantity</p>
                                        </td>
                                        <td style="text-align: start;">
                                            <p>{{(+product.product.surfacePerBox).toFixed(2)}}m<sup>2</sup> per box</p>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div class="estimating-rules">
                                <p class="section-title">Estimating Rules</p>
                                <table>
                                    <tr>
                                        <td style="text-align: start;">
                                            <p>Cut Square</p>
                                        </td>
                                        <td style="text-align: start;">
                                            <p>Yes</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="text-align: start;">
                                            <p>No Budget Option</p>
                                        </td>
                                        <td style="text-align: start;">
                                            <p>Yes</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="text-align: start;">
                                            <p>Tile Count Method</p>
                                        </td>
                                        <td style="text-align: start;">
                                            <p>Yes</p>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div class="compulsory-add-ons">
                                <p class="section-title">Compulsory Add Ons</p>
                                <div *ngFor="let addon of getCompulsoryAddons(product)" class="add-on-row">
                                    <img src="../../../assets/images/tick_icon.png">
                                    <p>{{addon}}</p>
                                </div>
                            </div>
                            <div class="requirements">
                                <p class="section-title">Requirements</p>
                                <table>
                                    <tr *ngFor="let question of product.questions">
                                        <td style="text-align: start;">
                                            <p>{{question.alt_title ? question.alt_title : question.question}}</p>
                                        </td>
                                        <td style="text-align: start;">
                                            <p>{{question.answer}} <a *ngIf="question.fileUrl && question.answer !== 'No'" target="_blank" [href]="question.fileUrl">Download linked file</a></p>
                                        </td>
                                    </tr>
                                    <!-- <tr>
                                        <td style="text-align: start;">
                                            <p>Lay direction</p>
                                        </td>
                                        <td style="text-align: start;">
                                            <p>S-N</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="text-align: start;">
                                            <p>Edging products</p>
                                        </td>
                                        <td style="text-align: start;">
                                            <p>Beading</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="text-align: start;">
                                            <p>Underlay Requirements</p>
                                        </td>
                                        <td style="text-align: start;">
                                            <p>Standard</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="text-align: start;">
                                            <p>Leveling</p>
                                        </td>
                                        <td style="text-align: start;">
                                            <p>Yes</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="text-align: start;">
                                            <p>Stair Nossings Required</p>
                                        </td>
                                        <td style="text-align: start;">
                                            <p>Yes</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="text-align: start;">
                                            <p>Floor Removal Required</p>
                                        </td>
                                        <td style="text-align: start;">
                                            <p>Yes</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="text-align: start;">
                                            <p>Floor Protection Required</p>
                                        </td>
                                        <td style="text-align: start;">
                                            <p>Yes</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="text-align: start;">
                                            <p>Estimate to be completed wall
                                                to wall or cut into joinery</p>
                                        </td>
                                        <td style="text-align: start;">
                                            <p>Wall to wall</p>
                                        </td>
                                    </tr> -->
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="submission-form">
                    <div class="job-info">
                        <p class="section-title">Client Reference</p>
                        <table>
                            <tr>
                                <td style="text-align: start;">
                                    <p>Client Reference Number</p>
                                </td>
                                <td style="text-align: start;">
                                    <p>FLRHUB</p>
                                </td>
                            </tr>
                            <tr>
                                <td style="text-align: start;">
                                    <p>Status</p>
                                </td>
                                <td style="text-align: start;">
                                    <p>{{estimation.estimationStatus}}</p>
                                </td>
                            </tr>
                            <tr>
                                <td style="text-align: start;">
                                    <p>Job Number</p>
                                </td>
                                <td style="text-align: start;">
                                    <p>{{estimation.estimationNumber}}</p>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="job-files">
                        <div class="files-header">
                            <div class="header-text">
                                <p>Costing sheet</p>
                                <p><i>Supported file type: XML</i></p>
                            </div>
                            <label for="estimateCSV" *ngIf="!estimation.costingSheetUrl">
                                <div class="upload-button" [ngClass]="{loadingData: uploadingCostingSheet}">
                                    <img src="../../../assets/images/upload_icon.png">
                                    Upload estimates
                                </div>
                            </label>
                            <input *ngIf="!estimation.costingSheetUrl" id="estimateCSV" class="upload-input" type="file" accept=".xml" (change)="uploadCostingSheet($event)">
                        </div>
                        <div class="uploaded-section">
                            <img class="placeholder-image" *ngIf="!estimation.costingSheetUrl" src="../../../assets/images/folder_icon.png">
                            <div class="uploaded-file" *ngIf="estimation.costingSheetUrl">
                                <img src="../../../assets/images/file_icon.png">
                                <p (click)="openUrl(estimation.costingSheetUrl)">Costing sheet</p>
                                <img (click)="deleteCostingSheet()" class="cross-icon" src="../../../assets/images/thin_cross.png">
                            </div>
                        </div>
                        <p>Costing Sheet Instructions:</p>
                        <ul>
                            <li><i>The StyleName for flooring products must match the product name in the estimation file exactly - ie. "VIN01 - FLRHUB Luxury Vinyl Tile 250x1000mm"</i></li>
                            <li><i>Scale Checks can be included and are ignored during processing</i></li>
                            <li><i>The RoomID must match the room number in the estimation file exactly</i></li>
                            <li><i>The Qty must be a number</i></li>
                            <li><i>Vinyl must be displayed in m2</i></li>
                            <li><i>Only broadloom carpet rolls will be displayed in LM</i></li>
                            <li><i>The SaleUnit must be either "SM" or "LM" for square meters or linear meters respectively</i></li>
                        </ul>
                        <div class="files-header">
                            <p class="header-text">Project Files</p>
                            <label for="projectFiles">
                                <div class="upload-button" [ngClass]="{loadingData: uploadingProjectFiles}">
                                    <img src="../../../assets/images/upload_icon.png">
                                    Upload files
                                </div>
                            </label>
                            <input id="projectFiles" class="upload-input" type="file" multiple="true" (change)="uploadProjectFiles($event)">
                        </div>
                        <div class="upload-section">
                            <img src="../../../assets/images/folder_icon.png">
                            <p><i>eg. Quantified plans, Product summaries, Quantisafe checklists</i></p>
                            <div class="uploaded-file" *ngFor="let file of estimation.projectFiles">
                                <img src="../../../assets/images/file_icon.png">
                                <p (click)="openUrl(file.url)">{{file.name}}</p>
                                <img (click)="deleteProjectFile(file.url)" class="cross-icon" src="../../../assets/images/thin_cross.png">
                            </div>
                        </div>
                        <div class="button-group">
                            <div class="primary-button" [ngClass]="{
                                loadingData: submittingEstimate,
                                disabled: estimation.estimationStatus === 'COMPLETED'
                            }"
                            (click)="estimation.estimationStatus === 'COMPLETED' ? null : submit()">Submit Estimate</div>
                            <!-- <div class="secondary-button" (click)="save()" [ngClass]="{loadingData: loading}">Save</div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <p class="message" *ngIf="loading">Loading...</p>
        <!-- <p class="message" *ngIf="!submittingEstimate && estimation.estimationStatus === 'COMPLETED'">This estimation has been completed.</p> -->
    </div>
</div>