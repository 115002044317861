<div class="page-container">
    <app-menu *ngIf="!isClientView" active="Marketplace"></app-menu>
    <div class="content-container">
        <app-header showBackButton="true" title="Marketplace"></app-header>
        <div *ngIf="loading" class="page-loading">Loading...</div>
        <div class="content" *ngIf="!loading && user.cart.length > 0">
            <div class="cart-column">
                <div class="title-row hide-phone">
                    <div class="title">Your Cart</div>
                    <div class="secondary-button small-button" (click)="continueShopping()">Continue shopping</div>
                </div>
                <div class="cart-item" *ngFor="let cartItem of user.cart; let i = index;">
                    <img class="cart-item-image" [src]="cartItem.product.thumbnailImageUrl">
                    <div class="item-container">
                        <div class="item-name-row">
                            <div [innerHTML]="cartItem.product.productCollection | safeHtml" class="item-name"></div>
                            <div class="item-price" *ngIf="!isClientView">{{productClass.calculateRetailCost(cartItem.product, cartItem.quantity)}}<span
                                    *ngIf="cartItem.product.unitType !== 'Quantity'">/{{unitTypeToMeasure(cartItem.product.unitType)}}</span></div>
                            <div class="spacer"></div>
                            <div class="secondary-button small-button hide-phone" (click)="deleteCartItem(i)">Delete
                            </div>
                        </div>
                        <p class="category" [innerHTML]="cartItem.product.brand + (cartItem.product.subBrand ? ' - '+cartItem.product.subBrand : '') +' | '+ (cartItem.product.productCategory) | safeHtml"></p>
                        <p class="design">{{cartItem.product.productDesign}}{{cartItem.product.variations && cartItem.product.variations['Size'] ? ' - '+cartItem.product.variations['Size'].option : ''}}</p>
                        <div *ngIf="this.cartItem.product.unitType !== 'LM'" class="quantity-container hide-phone">
                            <input class="quantity-field" [ngClass]="{'quantity-error': errors.quantity}"
                                [(ngModel)]="cartItem.quantity"
                                (ngModelChange)="updateItemTotal(cartItem); errors.quantity = false" (blur)="saveUser()"
                                type="number" min="0" placeholder="0">
                            <div *ngIf="cartItem.product.unitType !== 'Quantity' && cartItem.product.unitType !== 'Each' && cartItem.product.unitType !== 'LM'" class="unit">m<sup>2</sup></div>
                            <div *ngIf="cartItem.product.unitType === 'Quantity' || cartItem.product.unitType === 'Each'" class="unit"> Units</div>
                            <div *ngIf="cartItem.product.unitType === 'LM'" class="unit"> LM</div>
                        </div>
                        <div class="cuts-container" *ngIf="cartItem.product.unitType === 'LM' && populateCuts(cartItem.product)">
                            <div class="cut-row" *ngFor="let cut of cartItem.product.cuts; let i = index; trackBy: trackByFn" >
                              <div class="quantity-container" *ngIf="cartItem.product.retailPrice">
                                <input
                                  class="quantity-field"
                                  [ngClass]="{ 'quantity-error': errors.quantity }"
                                  [(ngModel)]="cartItem.product.cuts[i]"
                                  (ngModelChange)="errors.quantity = false; updateItemTotal(cartItem)"
                                  type="number"
                                  min="0"
                                  placeholder="0"
                                />
                                <div class="unit">
                                  LM (Cut {{i+1}})
                                </div>
                                <img *ngIf="cartItem.product.cuts.length > 1" (click)="deleteCut(i,cartItem)" src="../../../assets/images/thin_cross.png" alt="" class="cross-icon">
                            </div>
                           </div>
                           <div class="secondary-button small-button add-cut-button" (click)="addCutButtonPressed(cartItem.product)">+ Add another cut</div>
                        </div>
                        <div class="total-row" *ngIf="!isClientView">
                            <div class="box-total" *ngIf="cartItem.product.unitType === 'Box'"><i>Box Total:</i>
                                {{(calculateBoxQuantity(cartItem) / surfacePerBox(cartItem)).toFixed(0)}} =
                                {{calculateBoxQuantity(cartItem)}}m<sup>2</sup></div>
                            <div class="box-total" *ngIf="cartItem.product.unitType === 'Roll' && isLessThanARoll(cartItem)"><i>Roll cut price:</i>
                                ${{(cartItem.quantity * 4).toFixed(2)}}</div>
                            <div class="box-total" *ngIf=" cartItem.product.unitType === 'LM' && calculateLmCutPrice(cartItem.product) > 0 && !isClientView">
                                Cut price: <span>${{ calculateLmCutPrice(cartItem.product) }}</span>
                            </div>
                            <div class="tab hide-phone"><p></p></div>
                            <div class="box-total" *ngIf="cartItem.product.unitType === 'LM'">Total LM: {{(cartItem.quantity).toFixed(3)}}</div>                                
                            <div class="spacer hide-phone"></div>
                            <div class="total">{{cartItem.total}}</div>
                        </div>
                        <div class="weld-rod-container hide-phone" *ngIf="false">
                            <div class="toggle-box weld-rod-toggle"
                                [ngClass]="{selected: addedWeldRodForProduct(cartItem), expanded: weldRodEditing === i}">
                                <div class="main-toggle-content">
                                    <div class="toggle" (click)="toggleWeldRod(cartItem)">
                                        <div class="thumb">
                                            <img class="check" src="../../../assets/images/check_icon_orange.png">
                                            <img class="cross" src="../../../assets/images/close_icon_orange.png">
                                        </div>
                                    </div>
                                    <div class="select-description">
                                        <div class="title" *ngIf="!addedWeldRodForProduct(cartItem)">Add weld rod with
                                            this product</div>
                                        <div class="title" *ngIf="addedWeldRodForProduct(cartItem)">Weld rod added
                                            ({{addedWeldRodForProduct(cartItem)!.quantity}}
                                            roll{{addedWeldRodForProduct(cartItem)!.quantity !== 1 ? 's' : ''}})</div>
                                    </div>
                                    <div *ngIf="addedWeldRodForProduct(cartItem)" class="small-button edit-button"
                                        (click)="editWeldRod(i)">{{weldRodEditing === i ? 'Done' : 'Edit'}}</div>
                                </div>
                                <div class="expanded-toggle-content" *ngIf="addedWeldRodForProduct(cartItem)">
                                    <div class="colour-options">
                                        <div
                                            (click)="addedWeldRodForProduct(cartItem)!.product.color= 'Single colour'; saveUser()">
                                            <div class="check-box"
                                                [ngClass]="{selected: addedWeldRodForProduct(cartItem)!.product.color === 'Single colour'}">
                                                <img src="../../../assets/images/check_icon.png">
                                            </div>
                                            <div>Single colour</div>
                                        </div>
                                        <div *ngIf="hasMulticolouredWeldRods.includes(cartItem.product.brand)"
                                            (click)="addedWeldRodForProduct(cartItem)!.product.color = 'Multi colour'; saveUser()">
                                            <div class="check-box"
                                                [ngClass]="{selected: addedWeldRodForProduct(cartItem)!.product.color === 'Multi colour'}">
                                                <img src="../../../assets/images/check_icon.png">
                                            </div>
                                            <div>Multi colour<div style="font-size: 10px;">(Where available)</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="quantity-text">How many weld rods do you require? <span *ngIf="!isClientView">($101.12/50m roll)</span></div>
                                    <div class="quantity-container">
                                        <input class="quantity-field"
                                            [(ngModel)]="addedWeldRodForProduct(cartItem)!.quantity" type="number"
                                            min="0" placeholder="0">
                                        <div class="unit">rolls</div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="addedWeldRodForProduct(cartItem) && !isClientView" class="total">
                                {{addedWeldRodForProduct(cartItem)!.total}}</div>
                        </div>
                    </div>
                    <div class="show-phone phone-quantity-container">
                        <div class="phone-quantity">
                            <div *ngIf="cartItem.product.unitType !== 'LM'" class="quantity-container">
                                <input class="quantity-field" [ngClass]="{'quantity-error': errors.quantity}"
                                    [(ngModel)]="cartItem.quantity"
                                    (ngModelChange)="updateItemTotal(cartItem); errors.quantity = false"
                                    (blur)="saveUser()" type="number" min="0" placeholder="0">
                                <div *ngIf="cartItem.product.unitType !== 'Quantity'" class="unit">m<sup>2</sup></div>
                            </div>
                            <div class="cuts-container" *ngIf="cartItem.product.unitType === 'LM' && populateCuts(cartItem.product)">
                                <div class="cut-row" *ngFor="let cut of cartItem.product.cuts; let i = index; trackBy: trackByFn" >
                                  <div class="quantity-container" *ngIf="cartItem.product.retailPrice">
                                    <input
                                      class="quantity-field"
                                      [ngClass]="{ 'quantity-error': errors.quantity }"
                                      [(ngModel)]="cartItem.product.cuts[i]"
                                      (ngModelChange)="errors.quantity = false; updateItemTotal(cartItem)"
                                      type="number"
                                      min="0"
                                      placeholder="0"
                                    />
                                    <div class="unit">
                                      LM (Cut {{i+1}})
                                    </div>
                                    <img *ngIf="cartItem.product.cuts.length > 1" (click)="deleteCut(i,cartItem)" src="../../../assets/images/thin_cross.png" alt="" class="cross-icon">
                                </div>
                               </div>
                               <div class="secondary-button small-button add-cut-button" (click)="addCutButtonPressed(cartItem.product)">+ Add another cut</div>
                            </div>
                            <div class="secondary-button small-button" (click)="deleteCartItem(i)">Delete
                            </div>
                        </div>
                        <div class="weld-rod-container" *ngIf="weldRodRequired(cartItem.product)">
                            <div class="toggle-box weld-rod-toggle"
                                [ngClass]="{selected: addedWeldRodForProduct(cartItem), expanded: weldRodEditing === i}">
                                <div class="main-toggle-content">
                                    <div class="toggle" (click)="toggleWeldRod(cartItem)">
                                        <div class="thumb">
                                            <img class="check" src="../../../assets/images/check_icon_orange.png">
                                            <img class="cross" src="../../../assets/images/close_icon_orange.png">
                                        </div>
                                    </div>
                                    <div class="select-description">
                                        <div class="title" *ngIf="!addedWeldRodForProduct(cartItem)">Add weld rod with
                                            this product</div>
                                        <div class="title" *ngIf="addedWeldRodForProduct(cartItem)">Weld rod
                                          <span *ngIf="!isClientView">
                                            ({{addedWeldRodForProduct(cartItem)!.quantity}}
                                            roll{{addedWeldRodForProduct(cartItem)!.quantity !== 1 ? 's' : ''}} -
                                            <b>{{addedWeldRodForProduct(cartItem)!.total}}</b>)
                                          </span>
                                          </div>
                                    </div>
                                    <div *ngIf="addedWeldRodForProduct(cartItem)" class="small-button edit-button"
                                        (click)="editWeldRod(i)">{{weldRodEditing === i ? 'Done' : 'Edit'}}</div>
                                </div>
                                <div class="expanded-toggle-content" *ngIf="addedWeldRodForProduct(cartItem)">
                                    <div class="colour-options">
                                        <div
                                            (click)="addedWeldRodForProduct(cartItem)!.product.color= 'Single colour'; saveUser()">
                                            <div class="check-box"
                                                [ngClass]="{selected: addedWeldRodForProduct(cartItem)!.product.color === 'Single colour'}">
                                                <img src="../../../assets/images/check_icon.png">
                                            </div>
                                            <div>Single colour</div>
                                        </div>
                                        <div *ngIf="hasMulticolouredWeldRods.includes(cartItem.product.brand)"
                                            (click)="addedWeldRodForProduct(cartItem)!.product.color = 'Multi colour'; saveUser()">
                                            <div class="check-box"
                                                [ngClass]="{selected: addedWeldRodForProduct(cartItem)!.product.color === 'Multi colour'}">
                                                <img src="../../../assets/images/check_icon.png">
                                            </div>
                                            <div>Multi colour<div style="font-size: 10px;">(Where available)</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="quantity-text">How many weld rods do you require? <span *ngIf="!isClientView">($101.12/50m roll)</span></div>
                                    <div class="quantity-container">
                                        <input class="quantity-field"
                                            [(ngModel)]="addedWeldRodForProduct(cartItem)!.quantity" type="number"
                                            min="0" placeholder="0">
                                        <div class="unit">rolls</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="total-column" *ngIf="!isClientView">
                <div class="cart-total-container" *ngIf="!loading">
                    <div class="title hide-phone">Cart Total</div>
                    <div class="title show-phone">Your Cart</div>
                    <div class="total-rows">
                        <div class="total-item">Subtotal:</div>
                        <div class="total-cost">{{order.getSubTotal()}}</div>
                    </div>
                    <div class="total-rows" *ngIf="order.getBulkDiscount()">
                        <div class="total-item">Discount (Bulk order):</div>
                        <div class="total-cost">-{{this.order.getBulkDiscount()}}</div>
                    </div>
                    <div class="total-rows" *ngIf="this.order.discountCodeAmount">
                        <div class="total-item">Discount ({{this.order.discountCode}}):</div>
                        <div class="total-cost">-{{this.order.discountCodeAmount}}</div>
                    </div>
                    <div class="total-rows">
                        <div class="total-item">Shipping:</div>
                        <div class="total-cost shipping-cost">(This will be calculated on the next step)</div>
                    </div>
                    <div class="total-rows">
                        <div class="total-item">GST:</div>
                        <div class="total-cost">{{order.getGst()}}</div>
                    </div>
                    <div class="total-rows">
                        <div class="total-item">Total:</div>
                        <div class="total-cost">{{order.getTotalWithGst()}}</div>
                    </div>
                    <div class="discounts-apply-text">Discounts apply for bulk orders</div>
                    <div class="pay-row">
                        <img class="lock-icon" src="../../../assets/images/lock_icon.png">
                        <div class="secure-text">
                            <div class="secure-text-1">Secure checkout</div>
                            <div class="secure-text-2">Pay safely with SSL technology.</div>
                        </div>
                        <div class="spacer"></div>
                        <div class="primary-button" gaEvent="check_out_button" gaCategory="click_event" (click)="checkout()">Check out</div>
                    </div>
                </div>
                <!-- <div class="toggle-box small-toggle fh-credit-toggle" [ngClass]="{selected: order.useFHCredit}"
                    (click)="order.useFHCredit = !order.useFHCredit;">
                    <div class="toggle">
                        <div class="thumb">
                            <img class="check" src="../../../assets/images/check_icon_orange.png">
                            <img class="cross" src="../../../assets/images/close_icon_orange.png">
                        </div>
                    </div>
                    <div class="select-description">
                        <div class="title">I want to use FLRHUB Credit</div>
                    </div>
                </div> -->
                <div class="expand-box" [ngClass]="{expanded: discountBoxExpanded}"
                    (click)="discountBoxExpanded = !discountBoxExpanded">
                    <div class="title">Discount code</div>
                    <img class="down-arrow" src="../../../assets/images/down_arrow.png">
                    <div class="discount-input-row">
                        <div class="input-field" [ngClass]="{error: errors.discountCode}"
                            (click)="$event.stopPropagation()">
                            <input [(ngModel)]="discountCode" (ngModelChange)="errors.discountCode = false"
                                placeholder="Type here..." type="text">
                        </div>
                        <div class="secondary-button small-button" [ngClass]="{loading: loadingDiscountCode}"
                            (click)="applyDiscountCode(); $event.stopPropagation()">Apply Discount</div>
                    </div>
                </div>
                <div class="payment-icons">
                    <img src="../../../assets/images/visa_icon.png">
                    <img src="../../../assets/images/amex_icon.png">
                    <img src="../../../assets/images/mastercard_icon.png">
                    <div class="spacer"></div>
                    <img class="bank-transfer-icon" src="../../../assets/images/bank_transfer_icon.png">
                    <!-- <img class="leda-icon" src="../../../assets/images/leda_icon.png"> -->
                </div>
            </div>
        </div>
        <div *ngIf="!loading && user.cart.length === 0" class="empty-placeholder">
            <div class="message">Your cart is empty</div>
            <div class="secondary-button" (click)="continueShopping()">Browse Marketplace</div>
        </div>
    </div>
</div>
