import { Product } from './Product';

export class CustomerJob {
  jobPostNumber: string;
  jobName: string;
  company: string;
  jobDescription: string;
  industry: string;
  materialType: string[];
  closingDate: string;
  closingTime: string;
  closingDateTime: number;
  jobLocation: string;
  jobLocationLat: string;
  jobLocationLng: string;
  suburb: string;
  state: string;
  jobSize: string;
  constructionPeriod: string;
  workingDays: string;
  specificationDrawings: { name: string; url: string }[];
  otherDocuments: { name: string; url: string }[];
  scopeOfWorks: string[];
  specialConditions: string[];
  siteVisitRequired: boolean;
  siteVisitType: 'Contact' | 'Custom';
  siteVisitInfo: string;
  urgency: string;

  submittedDate: string;
  postStatus: string;
  quotesSubmitted: number;

  products: {
    product: Product;
    quantity: string;
    subItems: { name: string; quantity: string }[];
  }[];

  userId: string;

  winnerQuoteNumber: string;
  siteContactName: any;
  siteContactNumber: any;

  installerContactRequired: boolean;

  name: string;
  email: string;
  contactNumber: string;


  constructor() {
    this.jobPostNumber = '';
    this.jobName = '';
    this.company = '';
    this.jobDescription = '';
    this.industry = '';
    this.materialType = [];
    this.closingDate = '';
    this.closingTime = '';
    this.closingDateTime = 0;
    this.jobLocation = '';
    this.jobLocationLat = '';
    this.jobLocationLng = '';
    this.suburb = '';
    this.state = '';
    this.jobSize = '';
    this.constructionPeriod = '';
    this.workingDays = '';
    this.specificationDrawings = [];
    this.otherDocuments = [];
    this.scopeOfWorks = [''];
    this.specialConditions = [''];
    this.submittedDate = '';
    this.products = [];
    this.postStatus = '';
    this.quotesSubmitted = 0;
    this.userId = '';
    this.winnerQuoteNumber = '';
    this.siteVisitRequired = false;
    this.siteVisitType = 'Contact';
    this.siteVisitInfo = '';
    this.urgency = 'Standard';
    this.siteContactName = '';
    this.siteContactNumber = '';
    this.installerContactRequired = false;
    this.name = '';
    this.email = '';
    this.contactNumber = '';
  }
}
