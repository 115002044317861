import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { CartItem } from 'src/app/classes/CartItem';
import { Product } from 'src/app/classes/Product';
import { ProductCollection } from 'src/app/classes/ProductCollection';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { AuthHelper } from 'src/app/helpers/AuthHelper';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';
import { UtilityHelper } from 'src/app/helpers/UtilityHelper';
import { AlertViewComponent } from 'src/app/modals/alert-view/alert-view.component';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent implements OnInit {
  selectedProductCode = '';
  selectedExtraImage = '';
  product: Product = new Product();
  collection: ProductCollection = new ProductCollection();
  loading = true;
  addingToCart = false;
  errors: any = {};
  quantity: any;
  boxQuantity = 0;
  hoverStyle = '';
  showHover = false;

  addingWeldRod = false;
  weldRodAdded = false;
  hasMulticolouredWeldRods = ['Tarkett'];
  weldRodSelected = false;
  weldRodColour = 'Single colour';
  weldRodQuantity: any;

  selectedVariations: any = {};

  public productClass = Product;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
  ) {
    console.log('route', this.route);
    if (this.data) {
      console.log('product details constructor', this.data);
      this.collection = this.data.productCollection;
      this.selectedProductCode = this.collection.products[0].productCode;
      this.loadProduct();
    } else if (
      this.router.getCurrentNavigation() &&
      this.router.getCurrentNavigation()?.extras.state
    ) {
      console.log('current navigation');
      this.collection =
        this.router.getCurrentNavigation()?.extras.state!['productCollection'];
      this.selectedProductCode = this.collection.products[0].productCode;
      this.loadProduct();
      //load product from productCode given as query param
    } else if (this.route.snapshot.queryParams['productCode']) {
      this.loadProductCollection(
        this.route.snapshot.queryParams['productCode'],
      );
    } else {
      this.router.navigateByUrl('/marketplace');
    }
  }

  ngOnInit(): void {
    // AnalyticsHelper.instance.pageView('Product Details');
    // AnalyticsHelper.instance.logEvent("view_product_collection", { product_collection: this.collection.collectionName });
  }

  isReplacingProduct() {
    if (this.data && !this.data.choosingProduct) {
      return true;
    } else {
      return false;
    }
  }

  getBreakpointCost(breakpoint: string) {
    if (breakpoint.includes('@')) {
      return UtilityHelper.formatPrice(
        UtilityHelper.parsePrice(breakpoint.split('@')[0]) *
          (this.product.margin ? +this.product.margin : 1.28),
      );
    } else {
      return '';
    }
  }

  getBreakpointQuantity(breakpoint: string) {
    if (breakpoint.includes('@')) {
      return breakpoint.split('@')[1];
    } else {
      return '';
    }
  }

  isSelectingProduct() {
    if (this.data && this.data.choosingProduct) {
      return true;
    } else {
      return false;
    }
  }

  async loadProductCollection(collectionName: string) {
    DatabaseHelper.instance.getProductCollection(collectionName).then((val) => {
      this.collection = val;
      this.selectedProductCode = this.collection.products[0].productCode;
      this.loadProduct();
    });
  }

  loadProduct() {
    // this.loading = true;
    console.log('loading product data = ' + this.selectedProductCode);
    DatabaseHelper.instance.getProduct(this.selectedProductCode).then((val) => {
      this.product = val;
      console.log('Got product = ', JSON.stringify(val));
      this.getVariations().forEach((variation) => {
        this.selectedVariations[variation] =
          this.product.variations[variation].option;
      });
      console.log('Hierarchy = ' + this.product.brandHierarchy);
      if (this.product.supplierUnitPrice) {
        this.product.retailPrice = UtilityHelper.formatPrice(
          /* TODO Can we set this in the product constructor, so every product model has access to retailPrice by default?
           * Could also move the 1.28 to a constant we can use throughout the project
           */
          UtilityHelper.parsePrice(
            Product.calculateRetailCost(this.product, 0),
          ),
        );
      } else {
        this.product.retailPrice = '';
      }
      // this.product.supplierUnitPrice = UtilityHelper.formatPrice(UtilityHelper.parsePrice(this.product.retailPrice)*0.7);
      this.loading = false;
      this.weldRodSelected = false;
      if (this.addedWeldRodForProduct) {
        this.weldRodColour = this.addedWeldRodForProduct.product.color;
      }
    });
  }

  selectStyle(style: any) {
    this.selectedProductCode = style.productCode;
    this.loadProduct();
  }

  selectExtraImage(extraImage: any) {
    this.selectedExtraImage = extraImage;
  }

  getButtonTitle() {
    return this.isReplacingProduct()
      ? 'Replace product'
      : this.isSelectingProduct()
        ? 'Select'
        : 'Add to cart';
  }

  addToCart(cartConfirmed?: boolean) {
    if (!cartConfirmed && this.product.unitType === 'LM' && this.quantity > 0) {
      this.dialog.open(AlertViewComponent, {
        maxWidth: '400px',
        panelClass: ['alert-modal', 'no-padding'],
        id: 'alert-modal',
        data: {
          title: 'Confirm cut wastage',
          message:
            'Please confirm you have added in sufficient waste for cuts in your order quantity. All cut lengths specified through our estimation service are net quantities and do not allow wastage cuts. FLRHUB recommends adding 100mm per cut length for cut waste.',
          primaryButton: 'Confirm',
          secondaryButton: 'Update cuts',
        },
      });
      DatabaseHelper.instance.dataCallbacks.alertViewCallback = (
        button: string,
      ) => {
        if (button === 'Confirm') {
          this.addToCart(true);
        } else if (button === 'Update cuts') {
        }
      };
    } else {
      if (this.isSelectingProduct()) {
        let data: any = { page: 'selectingProduct', product: this.product };
        DatabaseHelper.instance.dataCallbacks.dataCallback(data);
        return;
      }
      if (
        ((!this.quantity || this.quantity === 0) &&
          !this.isReplacingProduct()) ||
        (this.isReplacingProduct() &&
          !this.weldRodQuantity &&
          (!this.quantity || this.quantity === 0))
      ) {
        this.errors.quantity = true;
        return;
      }
      //For each cut check to see if the quantity is 0 and remove the cut if it is
      if (this.product.cuts) {
        this.product.cuts.forEach((cut) => {
          console.log('CHECKING CUTS = ', cut);
          if (!cut || cut === 0) {
            this.product.cuts.splice(this.product.cuts.indexOf(cut), 1);
          }
        });
      }
      let finalQuantity =
        this.product.unitType === 'Box'
          ? this.boxQuantity
          : +(+this.quantity).toFixed(2);
      if (this.isReplacingProduct()) {
        let data: any = {
          page: 'replaceProduct',
          product: this.product,
          quantity: finalQuantity,
        };
        if (this.weldRodSelected && this.weldRodQuantity > 0) {
          data.weldRod = this.createWeldRodCartItem();
        }
        DatabaseHelper.instance.dataCallbacks.dataCallback(data);
        console.log(
          'Replacing product go go go! <<<<<<<<< ' + JSON.stringify(data),
        );
      } else {
        this.addingToCart = true;
        let index = this.user.cart.findIndex(
          (i) => i.product.productCode === this.product.productCode,
        );
        AnalyticsHelper.instance.logEvent('add_to_cart', {
          product_collection: this.product.productCollection,
          product_design: this.product.productDesign,
          product_quantity: this.quantity,
        });
        if (index >= 0) {
          this.user.cart[index].product = this.product;
          this.user.cart[index].quantity += finalQuantity;
          CartItem.calculateTotal(this.user.cart[index]);
          CartItem.calculateSupplierTotal(this.user.cart[index]);
          CartItem.calculateSupplierTotalWithGst(this.user.cart[index]);
          this.user.save().then((val) => {
            this.addingToCart = false;
            this.router.navigateByUrl('/cart');
          });
        } else {
          let item = new CartItem();
          item.product = this.product;
          item.quantity = finalQuantity;
          item.total = this.total;
          CartItem.calculateSupplierTotal(item);
          CartItem.calculateSupplierTotalWithGst(item);
          this.user.cart.push(item);
          this.user
            .save()
            .then((val) => {
              this.addingToCart = false;
              this.router.navigateByUrl('/cart');
            })
            .catch((err) => {
              console.error('Error saving cart', err);
              this.addingToCart = false;
              //this.router.navigateByUrl('/cart');
            });
        }
      }
    }
  }

  back() {
    if (this.isReplacingProduct() || this.isSelectingProduct()) {
      DatabaseHelper.instance.dataCallbacks.dataCallback({
        page: 'dismissProductDetails',
      });
    } else {
      window.history.back();
    }
  }

  downloadFile(file: any) {
    AnalyticsHelper.instance.logEvent('file_downloaded', {
      file_url: file.fileUrl,
      file_type: file.fileType,
    });
    window.open(file.fileUrl, '_blank');
  }

  addWeldRod() {
    if (!this.weldRodQuantity || this.weldRodQuantity === 0) {
      this.errors.weldRodQuantity = true;
      return;
    }
    this.addingWeldRod = true;
    let index = this.user.cart.findIndex(
      (i) => i.product.productCode === this.product.productCode,
    );
    if (index >= 0) {
      // We already have the product in cart
      if (this.addedWeldRodForProduct) {
        // We also have Weldrod
        this.addedWeldRodForProduct.quantity += this.weldRodQuantity;
        CartItem.calculateTotal(this.addedWeldRodForProduct);
        CartItem.calculateSupplierTotal(this.addedWeldRodForProduct);
        CartItem.calculateSupplierTotalWithGst(this.addedWeldRodForProduct);
      } else {
        let weldRodIndex = this.user.cart[index].lineItems.findIndex(
          (i) =>
            i.product.productCollection === 'Weld Rod' &&
            i.product.productDesign === this.product.productDesign &&
            i.product.color !== this.weldRodColour,
        );
        if (weldRodIndex >= 0) {
          // We have a weld rod but it's a different color. So let's create another product.
          let item = new CartItem();
          item.product = this.product;
          item.quantity = 0;
          item.total = '$0.00';
          item.lineItems.push(this.createWeldRodCartItem());
          CartItem.calculateSupplierTotal(item);
          CartItem.calculateSupplierTotalWithGst(item);
          this.user.cart.push(item);
        } else {
          // We do not have a weld rod
          this.user.cart[index].lineItems.push(this.createWeldRodCartItem());
        }
      }
    } else {
      let item = new CartItem();
      item.product = this.product;
      item.quantity = 0;
      item.total = '$0.00';
      item.lineItems.push(this.createWeldRodCartItem());
      CartItem.calculateSupplierTotal(item);
      CartItem.calculateSupplierTotalWithGst(item);
      this.user.cart.push(item);
    }
    this.user.save().then((val) => {
      this.addingWeldRod = false;
      this.weldRodAdded = true;
      setTimeout(() => {
        this.weldRodAdded = false;
      }, 2000);
    });
  }

  createWeldRodCartItem(): CartItem {
    let weldRodProduct = new Product();
    weldRodProduct.productDesign = this.product.productDesign;
    weldRodProduct.retailPrice = '$101.12';
    weldRodProduct.supplierUnitPrice = '$79.00';
    weldRodProduct.productCollection = 'Weld Rod';
    weldRodProduct.unitType = 'Rolls';
    weldRodProduct.color = this.weldRodColour;
    weldRodProduct.brand = this.product.brand;
    let weldRodCartItem = new CartItem();
    weldRodCartItem.product = weldRodProduct;
    weldRodCartItem.quantity = this.weldRodQuantity;
    CartItem.calculateTotal(weldRodCartItem);
    CartItem.calculateSupplierTotal(weldRodCartItem);
    CartItem.calculateSupplierTotalWithGst(weldRodCartItem);
    return weldRodCartItem;
  }

  populateCuts() {
    if (!this.product.cuts || this.product.cuts.length <= 0) {
      this.product.cuts = [''];
    }
    return true;
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  calculateBoxQuantity() {
    if (this.product.unitType === 'Box') {
      if (!this.quantity || this.quantity === 0) {
        this.boxQuantity = 0;
      } else {
        this.boxQuantity = UtilityHelper.roundNearest(
          this.quantity,
          this.surfacePerBox,
        );
      }
    } else if (this.product.unitType === 'LM') {
      console.log('CUT ADDING NOW!');
      // this.quantity = this.product.cuts.
      //Update the total quantity with cuts total
      var totalQuantity = 0;
      this.product.cuts.forEach((cut) => {
        if (cut) {
          totalQuantity = totalQuantity + cut;
        }
      });
      this.quantity = +(+totalQuantity).toFixed(3);
    }
  }

  deleteCut(index: number) {
    //Remove the cut from this.product.cuts at the specified index
    this.product.cuts.splice(index, 1);
    this.calculateBoxQuantity();
  }

  //calculate the cost price of the product based on any cuts that are less than 30. The cost will be the total cut value multiplied by $11 and adding a flat fee of $16.50
  calculateLmCutPrice() {
    var totalCutPrice = 0;
    var supplierCutFee = 0;
    var supplierRollFee = 0;
    var supplierCutFeeQuantity = 0;
    var supplierRollFeeQuantity = 0;
    this.product.cuts.forEach((cut) => {
      if (cut < 30 && cut > 0) {
        totalCutPrice = totalCutPrice + cut * 11 + 16.5;
        supplierCutFee = supplierCutFee + cut * 9;
        supplierRollFee = supplierRollFee + 13.5;
        supplierCutFeeQuantity = supplierCutFeeQuantity + cut;
        supplierRollFeeQuantity = supplierRollFeeQuantity + 1;
      }
    });
    this.product.cutPrice = totalCutPrice;
    this.product.supplierCutFee = supplierCutFee.toFixed(2);
    this.product.supplierRollFee = supplierRollFee.toFixed(2);
    this.product.supplierCutFeeQuantity = supplierCutFeeQuantity;
    this.product.supplierRollFeeQuantity = supplierRollFeeQuantity;
    return +totalCutPrice.toFixed(2);
  }

  addCutButtonPressed() {
    this.product.cuts.push('');
  }

  get isLessThanARoll() {
    if (this.product.unitType === 'Roll') {
      if (
        this.quantity &&
        this.quantity < (+this.product.width * +this.product.length) / 10000
      ) {
        return true;
      }
    }
    return false;
  }

  get surfacePerBox(): number {
    return +this.product.surfacePerBox;
  }

  get user() {
    return AuthHelper.instance.user;
  }

  get total() {
    if (!this.quantity) return '$0.00';
    if (this.product.unitType === 'Box') {
      return UtilityHelper.formatPrice(
        UtilityHelper.parsePrice(
          Product.calculateRetailCost(this.product, this.boxQuantity),
        ) * this.boxQuantity,
      );
    } else if (this.product.unitType === 'LM') {
      return UtilityHelper.formatPrice(
        UtilityHelper.parsePrice(
          Product.calculateRetailCost(this.product, this.quantity),
        ) *
          this.quantity +
          this.calculateLmCutPrice(),
      );
    } else {
      if (this.isLessThanARoll) {
        return UtilityHelper.formatPrice(
          4 * this.quantity +
            UtilityHelper.parsePrice(
              Product.calculateRetailCost(this.product, this.quantity),
            ),
        );
      }
      return UtilityHelper.formatPrice(
        UtilityHelper.parsePrice(
          Product.calculateRetailCost(this.product, this.quantity),
        ) * this.quantity,
      );
    }
  }

  weldRodRequired(product: Product) {
    return UtilityHelper.weldRodRequired(product);
  }

  get addedWeldRodForProduct() {
    let index = this.user.cart.findIndex(
      (i) =>
        i.product.productCode === this.product.productCode &&
        i.lineItems.findIndex(
          (i) =>
            i.product.productCollection === 'Weld Rod' &&
            i.product.productDesign === this.product.productDesign &&
            i.product.color === this.weldRodColour,
        ) >= 0,
    );
    if (index >= 0) {
      let weldRodIndex = this.user.cart[index].lineItems.findIndex(
        (i) =>
          i.product.productCollection === 'Weld Rod' &&
          i.product.productDesign === this.product.productDesign &&
          i.product.color === this.weldRodColour,
      );
      if (weldRodIndex >= 0) {
        return this.user.cart[index].lineItems[weldRodIndex];
      }
    }
    return null;
  }

  get weldRodTotal() {
    if (this.weldRodQuantity === undefined || this.weldRodQuantity === 0) {
      return '$0.00';
    }
    return UtilityHelper.formatPrice(this.weldRodQuantity * 101.12);
  }

  arrayToString(array: []) {
    var batchesAvailable = '';
    array.forEach((stockBatch: any) => {
      batchesAvailable =
        batchesAvailable + Number(stockBatch.stockAvailable).toFixed(2) + ', ';
    });
    batchesAvailable = batchesAvailable.substring(
      0,
      batchesAvailable.length - 2,
    );
    return batchesAvailable;
  }

  get formattedDescription() {
    return this.product.productDescription.replace(/\\n/g, '\n');
  }

  get isClientView() {
    return DatabaseHelper.instance.isClientView();
  }

  getVariations(): Array<string> {
    if (this.product.variations) {
      // console.log("Product has variations!");
      return Object.keys(this.product.variations);
    }
    return [];
  }

  getVariantList(forVariant: any): Array<any> {
    var variantList = new Array();
    //For each product in the collection
    console.log('Collection = ' + JSON.stringify(this.collection));
    console.log('BEGIN For Variant ' + forVariant + '--------------');
    this.collection.products.forEach((product) => {
      console.log('Now Checking Product = ' + product.productCode);
      //Get variant options for the forVariant where product.variations[!forVariant] == currentProduct.variations[!forVariant]
      //Check each variations available for the currently selected product
      // console.log("P variant = "+JSON.stringify(product.variations));
      var matches = true;
      Object.keys(this.product.variations).forEach((currentVariation: any) => {
        // console.log("Now Checking Variations = "+JSON.stringify(currentVariation));
        //If the variations we need all options for is what we're looking at then ignore it:
        //Otherwise check to see if the currently selected products value exists for this variant and if it does then allow the match to continue
        // console.log("About to error "+JSON.stringify(product.variations[currentVariation])+" and "+JSON.stringify(this.product.variations[currentVariation]));
        if (forVariant !== currentVariation) {
          if (
            !product.variations[currentVariation] ||
            product.variations[currentVariation].option !==
              this.product.variations[currentVariation].option
          ) {
            //This is not the same so lets use add the other options to this field using the forVariant
            matches = false;
          }
        } else {
        }
      });
      if (matches) {
        if (
          product.variations[forVariant] &&
          product.variations[forVariant].option
        ) {
          console.log('MATCHES! = ' + product.variations[forVariant].option);
          variantList.push(product.variations[forVariant].option);
        } else {
          console.log(
            "Couldn't find option for variant = " +
              JSON.stringify(product.variations[forVariant]),
          );
        }
      }
    });
    return variantList;
  }

  loadProductForVaration() {
    console.log('LOAD VARIATION PRODUCT NOW!');
    //For each product let's check if the product should be the one that's displaying
    this.collection.products.forEach((product) => {
      console.log('Checking PRODUCT = ' + product.productCode);
      var matches = true;
      //Loop through the select variations and see if the product matches
      Object.keys(this.selectedVariations).forEach(
        (selectedVariationString) => {
          console.log(
            'Checking PRODUCT variation = ' +
              selectedVariationString +
              ' vs ' +
              this.selectedVariations[selectedVariationString] +
              ' vs ' +
              product.variations[selectedVariationString].option,
          );
          if (
            this.selectedVariations[selectedVariationString] !==
            product.variations[selectedVariationString].option
          ) {
            console.log("-Doesn't match");
            matches = false;
          } else {
            console.log('-Match');
          }
        },
      );
      if (matches) {
        console.log('Found a Match');
        //This product matches all of our selected variations so load it if required
        //Only load the product if it's not the one we're already looking at
        if (this.product.productCode !== product.productCode) {
          console.log('GOT A NEW PRODUCT!');
          this.selectedProductCode = product.productCode;
          this.loadProduct();
        }
      }
    });
  }

  unitTypeToMeasure(unitType: string) {
    return UtilityHelper.unitTypeToMeasure(unitType);
  }

  getStockText() {
    const product = this.product,
      enquiryOnly = product.stockLevel == 'Enquiry Only',
      stockAvailable = Number(product.stockAvailable);
    let text = '';

    if (enquiryOnly) {
      text =
        'Made to order (Delivery times to be quoted before order confirmation)';
    } else {
      if (product.brand == 'Pentarch Forestry') {
        // Don't show stock levels for Pentarch
        if (stockAvailable > 250) {
          text = 'In Stock';
        } else if (stockAvailable > 0) {
          text = 'Low Stock';
        } else {
          text = 'Out of Stock';
        }
      }
      if (
        product.brand == 'Epoxy Warehouse' ||
        product.brand == 'GH Commercial'
      ) {
        // Always show products as in stock for Epoxy Warehouse
        text = 'In Stock';
      } else {
        stockAvailable > 0
          ? (text = `Stock available: ${stockAvailable.toFixed(2)}m²`)
          : (text =
              'Available for backorder (Delivery times to be quoted before order confirmation)');

        // If product has batches append:
        if (stockAvailable > 0 && product.stockBatches.length > 1) {
          text += ` - Batches: ${this.arrayToString(product.stockBatches)}`;
        }
      }

      // Append to all brands when stock is available
      if (stockAvailable > 0) {
        text +=
          ' (Larger orders will be backordered with delivery times to be quoted before order confirmation)';
      }
    }
    return text;
  }
}
