import { Injectable } from '@angular/core';

import { HttpService } from 'src/app/http.service';
@Injectable({
  providedIn: 'root',
})
export class ShippingService {
  constructor(private http: HttpService) {}

  calculateShipping(shipping?: string) {
    const body = {
      params: {
        shipping,
      },
    };
    return this.http.get('/calculate-shipping', body);
  }
}
