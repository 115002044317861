<div class="tabs">
  <div
    class="items"
    [ngClass]="{ active: active === 'Job Board' }"
    (click)="goTo('/job-board')"
  >
    Jobs
  </div>
  <div
    class="items"
    *ngIf="user.type === 'Installer' || user.type === 'Admin'"
    [ngClass]="{ active: active === 'Customer Jobs' }"
    (click)="goTo('/customer-jobs')"
  >
    Customer Jobs
  </div>
</div>
