import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/classes/User';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { AuthHelper } from 'src/app/helpers/AuthHelper';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';
import { UtilityHelper } from 'src/app/helpers/UtilityHelper';
import { HubspotService } from '../../helpers/HubspotService';
import { CookieService } from 'ngx-cookie-service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-sign-up-company-details',
  templateUrl: './sign-up-company-details.component.html',
  styleUrls: ['./sign-up-company-details.component.scss'],
})
export class SignUpCompanyDetailsComponent implements OnInit {
  password: string = '';
  showPassword: boolean = false;
  errors: any = {};
  startValidation: boolean = false;
  errorMessage: string[] = [];
  imageUploading = false;
  imageSizeError = false;
  licenceUploading = false;
  insuranceUploading = false;
  loading = false;

  constructor(
    private authHelper: AuthHelper,
    public router: Router,
    private hubspotService: HubspotService,
    private cookieService: CookieService,
  ) {}

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView('Sign Up Company Details');
  }

  validate(): boolean {
    this.errors = {};
    if (!this.startValidation) return false;
    if (!this.user.companyName) {
      this.errors.companyName = true;
    }
    if (!UtilityHelper.isABNValid(this.user.abn)) {
      this.errors.abn = true;
    }
    if (!this.user.companyAddress) {
      this.errors.companyAddress = true;
    }
    if (!this.user.licenceUrl && this.user.type === 'Builder') {
      this.errors.licenceUrl = true;
    }
    if (!this.user.insuranceUrl) {
      this.errors.insuranceUrl = true;
    }
    if (!this.user.companyBankrupcy) {
      this.errors.companyBankrupcy = true;
      this.errors.companyBankrupcyMessage = '';
    }
    //No longer needed
    // if (this.user.companyBankrupcy === 'Yes') {
    //   this.errors.companyBankrupcy = true;
    //   // Check if this error message is right
    //   this.errors.companyBankrupcyMessage = 'Please contact us to sign up`';
    //   // Notify FH that yes has been selected
    // }
    return Object.keys(this.errors).length === 0;
  }

  async signup() {
    this.startValidation = true;
    this.loading = true;

    if (this.validate()) {
      //await this.user.save();
      AuthHelper.instance
        .updateUserCompany(this.user)
        .then((userResponse) => {
          console.log('User Response:', userResponse);
          AuthHelper.instance.logout();
          this.router.navigateByUrl('/account-created');
        })
        .catch((error) => {
          let errorMsg =
            error instanceof HttpErrorResponse
              ? error.error.error
              : error.message;
          this.loading = false;
          this.errorMessage.push('Oops, something went wrong.'); //Something went wrong while creating your account, please try again shortly.')
        });
    } else {
      this.loading = false;
    }
  }

  uploadImage(event: any) {
    this.imageUploading = true;
    this.imageSizeError = false;

    var reader = new FileReader();

    //Read the contents of Image File.
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (e) => {
      //Initiate the JavaScript Image object.
      var image = new Image();

      //Set the Base64 string return from FileReader as source.
      image.src = e.target!.result!.toString();

      //Validate the File Height and Width.
      image.onload = () => {
        var height = image.height;
        var width = image.width;
        if (height < 300 || width < 300) {
          this.imageSizeError = true;
          this.imageUploading = false;
          return false;
        }
        const name = event.target.files[0].name;
        const lastDot = name.lastIndexOf('.');
        const ext = name.substring(lastDot + 1);

        const fileName = this.user.firstName + new Date().getTime() + '.' + ext;

        DatabaseHelper.instance
          .uploadFile('CompanyLogos/' + fileName, event.target.files[0])
          .then((val) => {
            this.user.companyLogoUrl = val;
            this.imageUploading = false;
          });
        return true;
      };
    };
  }

  uploadDocument(event: any, fileType: string) {
    console.log('Uploading things 1');
    if (event.target.files.length != 0) {
      console.log('Uploading things 2');
      if (fileType === 'insuranceUrl') {
        console.log('insurance loading');
        this.insuranceUploading = true;
      } else if (fileType === 'licenceUrl') {
        console.log('licence loading');
        this.licenceUploading = true;
      }

      var reader = new FileReader();

      //Read the contents of Image File.
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e) => {
        const name = event.target.files[0].name;
        const lastDot = name.lastIndexOf('.');
        const ext = name.substring(lastDot + 1);
        const fileName =
          this.user.firstName +
          this.user.lastName +
          new Date().getTime() +
          '.' +
          ext;

        DatabaseHelper.instance
          .uploadFile('CompanyFiles/' + fileName, event.target.files[0])
          .then((val) => {
            if (fileType === 'insuranceUrl') {
              console.log('Updated insurance url = ' + val);
              this.user.insuranceUrl = val;
            } else if (fileType === 'licenceUrl') {
              console.log('Updated licence url = ' + val);
              this.user.licenceUrl = val;
            }
            this.insuranceUploading = false;
            this.licenceUploading = false;
          });
        return true;
      };
    }
  }

  handleAddressChange(address: any) {
    console.log('Address change');
    console.log('Address change = ' + JSON.stringify(address));
    // this.userAddress = address.formatted_address
    // this.userLatitude = address.geometry.location.lat()
    // this.userLongitude = address.geometry.location.lng()
    var state = '';
    var suburb = '';
    address.address_components.forEach((component: any) => {
      component.types.forEach((type: any) => {
        if (type === 'administrative_area_level_1') {
          state = component.short_name;
        }
        if (type === 'locality') {
          suburb = component.short_name;
        }
      });
    });
    console.log('state = ' + state);
    console.log('suburb = ' + suburb);
    this.user.state = state;
    this.user.companyAddress = address.formatted_address;
  }

  goTo(url: string) {
    this.router.navigateByUrl(url);
  }

  get user() {
    return AuthHelper.instance.user;
  }

  back() {
    window.history.back();
  }
}
