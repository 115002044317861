import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthHelper } from 'src/app/helpers/AuthHelper';
import { MatDialog } from '@angular/material/dialog';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { Job } from 'src/app/classes/Job';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';
import { AlertViewComponent } from 'src/app/modals/alert-view/alert-view.component';

@Component({
  selector: 'app-job-board-post-visit',
  templateUrl: './job-board-post-visit.component.html',
  styleUrls: ['./job-board-post-visit.component.scss'],
})
export class JobBoardPostVisitComponent implements OnInit {
  activeTab = 1;
  loadingNextSteps = false;
  job: Job = new Job();
  uploadingOtherDocuments = false;
  errors: any = {};
  saving = false;
  updating = false;
  editingDraft = false;

  constructor(
    public router: Router,
    private authHelper: AuthHelper,
    public dialog: MatDialog
  ) {
    if (
      this.router.getCurrentNavigation() &&
      this.router.getCurrentNavigation()?.extras.state
    ) {
      this.job = this.router.getCurrentNavigation()?.extras.state!['job'];
      this.updating = true;
      this.editingDraft = true;
    } else {
      this.job.userId = this.user.uid;
      this.job.siteVisitRequired = true;
    }
  }

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView('Job Board Post Visit');
    this.loadFilters();
  }

  loadFilters() {
    if (this.jobMaterialTypes.length === 0) {
      DatabaseHelper.instance.getJobBoardFilters().then((val) => {
        DatabaseHelper.instance.jobBoardFilters['Material Type'].items =
          val.filters.materialTypes.map((e: string) => ({
            selected: false,
            name: e,
          }));
        DatabaseHelper.instance.jobBoardFilters['Location'].items =
          val.filters.location.map((e: string) => ({
            selected: false,
            name: e,
          }));
        DatabaseHelper.instance.jobBoardFilters['Job Size'].items =
          val.filters.jobSizes.map((e: string) => ({
            selected: false,
            name: e,
          }));
      });
    }
  }

  returnToJobBoard() {
    if (!this.job.jobName) {
      this.router.navigateByUrl('my-job-posts');
      return;
    }

    this.dialog.open(AlertViewComponent, {
      maxWidth: '400px',
      panelClass: ['alert-modal', 'no-padding'],
      id: 'alert-modal',
      data: {
        title: 'Exit or Save Draft',
        message: 'Do you want to save this job post as a draft?',
        primaryButton: 'Save Draft',
        secondaryButton: 'Exit',
      },
    });

    DatabaseHelper.instance.dataCallbacks = (button: string) => {
      if (button === 'Exit') {
        this.router.navigateByUrl('my-job-posts');
      } else if (button === 'Save Draft') {
        this.job.postStatus = 'Draft';
        this.saving = true;
        DatabaseHelper.instance
          .submitJobPost(this.job, this.updating, false)
          .then((val) => {
            this.saving = false;
            this.router.navigateByUrl('my-job-posts');
          });
      }
    };
  }

  handleAddressChange(address: any) {
    var state = '';
    var suburb = '';
    address.address_components.forEach((component: any) => {
      component.types.forEach((type: any) => {
        if (type === 'administrative_area_level_1') {
          state = component.short_name;
        }
        if (type === 'locality') {
          suburb = component.short_name;
        }
      });
    });
    this.job.jobLocation = address.formatted_address;
    this.job.state = state;
    this.job.suburb = suburb;
    this.job.jobLocationLat = address.geometry.location.lat();
    this.job.jobLocationLng = address.geometry.location.lng();
  }

  openUrl(url: string) {
    window.open(url, '_blank');
  }

  deleteFile(index: number) {
    DatabaseHelper.instance.deleteFile(this.job.otherDocuments[index].url);
    this.job.otherDocuments.splice(index, 1);
  }

  uploadFile(event: any) {
    if (event.target.files[0] === undefined) {
      return;
    }

    this.uploadingOtherDocuments = true;
    const promises: any[] = [];
    Array.from(event.target.files).forEach((file: any) => {
      promises.push(
        new Promise((res: any, rej: any) => {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (e) => {
            const name = file.name;
            DatabaseHelper.instance
              .uploadFile(
                'JobFiles/' +
                  this.user.uid +
                  '/' +
                  new Date().getTime() +
                  '_' +
                  name,
                file
              )
              .then((val) => {
                this.job.otherDocuments.push({ name: name, url: val });
                res(true);
              });
          };
        })
      );
    });
    Promise.all(promises).then(() => {
      this.uploadingOtherDocuments = false;
      event.target.value = '';
    });
  }

  proceedToStep(step: number) {
    if (this.activeTab === 1 && !this.validateStep1()) {
      return;
    }
    this.activeTab = step;
  }

  submitJob() {
    this.loadingNextSteps = true;
    this.job.submittedDate = new Date().getTime().toString();
    this.job.closingDateTime = new Date(
      this.job.closingDate + 'T' + this.job.closingTime+ 'Z',
    ).getTime();
    this.job.postStatus = 'Active';
    DatabaseHelper.instance
      .submitJobPost(this.job, this.updating, this.editingDraft)
      .then((val) => {
        this.loadingNextSteps = false;
        this.activeTab = 3;
        DatabaseHelper.instance.notifyInstallersOnNewJobPost();
      });
  }

  scopeAddAnotherRow($event: any) {
    var template =
      '<div class="scope-item counter-item">' +
      '<div class="input-field">' +
      '<input type="text" placeholder="Type here..."/>' +
      '</div>' +
      '</div>';

    var scopeItemContainer = $event.target
      .previousElementSibling as HTMLElement;
    scopeItemContainer.insertAdjacentHTML('beforeend', template);
  }

  deleteJobItem(item: any) {}

  openProductSelector() {}

  continueQuotingRequirement() {}

  addAnotherQuoteRequirement() {
    var template =
      '<div class="map-item item-custom">' +
      '<div class="name">' +
      '<div class="detail"><input type="text" name="key" placeholder="Type here..."></div>' +
      '</div>' +
      '<div class="input">' +
      '<input type="text" name="value">' +
      '</div>' +
      '</div>';
    var materialItems = document.querySelector(
      '.map-material-job-board'
    ) as HTMLElement;
    materialItems.insertAdjacentHTML('beforeend', template);
  }

  goTo(url: string) {
    this.router.navigateByUrl(url);
  }

  validateStep1() {
    // return true;
    this.errors = {};
    const fieldsToValidate = [
      'jobName',
      'jobDescription',
      'industry',
      'materialType',
      'closingDate',
      'closingTime',
      'jobLocation',
      'jobSize',
      'constructionPeriod',
      'workingDays',
      'scopeOfWorks',
      'siteVisitInfo',
    ];
    fieldsToValidate.forEach((field) => {
      if (!(this.job as any)[field] || (this.job as any)[field].length === 0) {
        // no error for siteVisitInfo if siteVisitType is Contact
        if (field === 'siteVisitInfo' && this.job.siteVisitType === 'Contact') {
          return;
        }
        this.errors[field] = true;
      }
      if (
        field === 'scopeOfWorks' &&
        !(this.job as any)[field].some((item: string) => item.length > 0)
      ) {
        this.errors[field] = true;
      }
    });
    if (Object.keys(this.errors).length === 0) {
      this.job.scopeOfWorks = this.job.scopeOfWorks.filter((e) => e.length > 0);
      this.job.specialConditions = this.job.specialConditions.filter(
        (e) => e.length > 0
      );
    }
    this.job.company = this.user.companyName;
    return Object.keys(this.errors).length === 0;
  }

  get user() {
    return AuthHelper.instance.user;
  }

  get jobMaterialTypes() {
    return DatabaseHelper.instance.jobBoardFilters['Material Type'].items.map(
      (e) => e.name
    );
  }

  trackByFn(index: any, item: any) {
    return index;
  }
}
