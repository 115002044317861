import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Order } from 'src/app/classes/Order';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { AuthHelper } from 'src/app/helpers/AuthHelper';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';
import { UtilityHelper } from 'src/app/helpers/UtilityHelper';
import { SearchComponent } from 'src/app/modals/search/search.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  // Original properties
  orders: Order[] = [];
  loading = true;
  perPage = 10;
  allLoaded = false;
  page = 0;
  backgroundImages = [
    '../../../assets/images/homepage-icons/card1-BG-image.jpeg',
    '../../../assets/images/homepage-icons/card2-BG-image.jpeg',
    '../../../assets/images/homepage-icons/card3-BG-image.png'
  ];
  guides = [
    { id: 1, title: 'What is FLRHUB?', link: '/guide/1' },
    { id: 2, title: 'How to start a new project', link: '/guide/2' },
    { id: 3, title: 'How to make a job posting', link: '/guide/3' },
    { id: 4, title: 'How to make an estimation using FLRHUB', link: '/guide/4' },
    { id: 5, title: 'How to use our marketplace', link: '/guide/5' }
  ];
  
  // Added properties from industry component
  searchTerm = '';
  userType: string = '';

  constructor(
    private router: Router,
    private dialog: MatDialog,  // Added for search modal
    private authHelper: AuthHelper
  ) { }

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView("Home");
    
    // Check if user is logged in
    this.authHelper.isLoggedIn().then(isLoggedIn => {
      if (!isLoggedIn) {
        this.router.navigate(['login']);
      }
    }).catch(error => {
      console.error('Error checking login status:', error);
      this.router.navigate(['login']);
    });
  }
  getUserType(): string {
    return AuthHelper.instance.user?.type || '';
  }
  
  // Original method
  goTo(url: string) {
    this.router.navigateByUrl(url);
  }

  // Added methods from industry component
  searchModal() {
    DatabaseHelper.instance.dataCallbacks.dataCallback = (search: string) => {
      this.router.navigateByUrl('marketplace', {
        state: { searchTerm: search },
      });
    };
    this.dialog.open(SearchComponent, {
      disableClose: true,
      height: '100vh',
      width: '100vh',
      maxWidth: '100vw',
      maxHeight: '-webkit-fill-available',
      position: { bottom: '0', left: '0' },
      enterAnimationDuration: '0',
      panelClass: ['profile-modal', 'grey-bg', 'no-scroll'],
      id: 'search-modal',
      autoFocus: false,
      data: { searchTerm: this.searchTerm },
    });
  }

  selectIndustry(industry: string) {
    this.router.navigate(['marketplace'], {
      queryParams: { industry },
    });
  }

  // Added getters from industry component
  get isPhone() {
    return UtilityHelper.isPhone();
  }

  getCardItems() {
    const isInstallerUser = this.getUserType() === 'Installer';
    const secondCard = isInstallerUser 
      ? {
          title: 'Job Board',
          description: 'Find jobs that match your skills and availability',
          icon: "../../../assets/images/homepage-icons/job_icon.svg",
          url: '/job-board',
          image: '../../../assets/images/homepage-icons/card2-BG-image.jpeg',
          gaEvent: 'job_board_card'
        }
      : {
          title: 'My Orders',
          description: 'Place and view your orders in one central location',
          icon: "../../../assets/images/homepage-icons/order_icon.svg",
          url: '/orders',
          image: '../../../assets/images/homepage-icons/card2-BG-image.jpeg',
          gaEvent: 'orders_card'
        };
    
    const thirdCard = isInstallerUser
      ? {
          title: 'My Orders',
          description: 'Place and view your orders in one central location',
          icon: "../../../assets/images/homepage-icons/order_icon.svg",
          url: '/orders',
          image: '../../../assets/images/homepage-icons/card3-BG-image.png',
          gaEvent: 'orders_card'
        }
      : {
          title: 'My Jobs',
          description: 'Get quality leads on our Job Hub',
          icon: "../../../assets/images/homepage-icons/job_icon.svg",
          url: '/my-job-posts',
          image: '../../../assets/images/homepage-icons/card3-BG-image.png',
          gaEvent: 'jobs_card'
        };

    const cardItems = [
      {
        title: 'Marketplace',
        description: "Access Australia's leading flooring marketplace",
        icon: "../../../assets/images/homepage-icons/marketplace_icon.svg",
        url: '/marketplace',
        image: '../../../assets/images/homepage-icons/card1-BG-image.jpeg',
        gaEvent: 'marketplace_card'
      },
      secondCard,
      thirdCard
    ];
    
    return cardItems;
  }

  get isClientView() {
    return DatabaseHelper.instance.isClientView();
  }

 
  isAdmin(): boolean {
    return this.getUserType() === 'Admin';
  }
  
  // Helper methods for other user types if needed
  isBuilder(): boolean {
    return this.getUserType() === 'Builder';
  }
  
  isInstaller(): boolean {
    return this.getUserType() === 'Installer';
  }
}
