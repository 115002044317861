import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Job } from 'src/app/classes/Job';
import { User } from 'src/app/classes/User';
import { JobQuote } from 'src/app/classes/JobQuote';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { AuthHelper } from 'src/app/helpers/AuthHelper';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';
import { UtilityHelper } from 'src/app/helpers/UtilityHelper';
import { AlertViewComponent } from '../alert-view/alert-view.component';
import { Product } from 'src/app/classes/Product';

@Component({
  selector: 'app-job-bid-details',
  templateUrl: './job-bid-details.component.html',
  styleUrls: ['./job-bid-details.component.scss'],
})
export class JobBidDetailsComponent implements OnInit {
  jobQuote: JobQuote = new JobQuote();
  builderJob: Job = new Job(); // Contains job details if builder is viewing this quote.
  jobProfile: any = null;
  activeTab = 'My Quotes';
  errors: any = { price: [], subItems: [] };
  submittingQuote = false;
  acceptingQuote = false;
  didNotWin = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView('Job Details');
    if (this.data && this.data.job) {
      if (this.data.job.jobQuoteNumber) {
        // builder
        this.jobQuote = this.data.job;
        if (this.data.job.uid) {
          DatabaseHelper.instance
            .getUser(this.data.job.uid)
            .then(async (response) => {
              console.log('response:', response);
              let val = response.user;
              if (val.uid) {
                this.jobProfile = val;
              }
            })
            .catch((error) => {
              console.error('error retrieving profile of job poster:', error);
            });
        }
        if (this.data.builderJob) {
          this.builderJob = this.data.builderJob;
        } else {
          this.checkDidNotWin();
        }
      } else {
        // installer
        if (this.data.job.uid) {
          DatabaseHelper.instance
            .getUser(this.data.job.uid)
            .then(async (response) => {
              let val = response.user;
              if (val.uid) {
                this.jobProfile = val;
              }
            })
            .catch((error) => {
              console.error('error retrieving profile of job poster:', error);
            });
        }
        this.jobQuote.job = this.data.job;
        this.jobQuote.uid = this.user.uid;
        this.jobQuote.products = JSON.parse(
          JSON.stringify(this.jobQuote.job.products),
        );
        this.jobQuote.installerName = this.user.companyName;

        this.jobQuote.installerLocation =
        this.user.companyAddress?(this.user.companyAddress.split(', ').length>2?this.user.companyAddress.split(', ')[
          this.user.companyAddress.split(', ').length - 2
        ]:this.user.companyAddress):'Unknown location';
      }
    } else if (this.data && this.data.jobQuote) {
      this.jobQuote = this.data.jobQuote;
    }
  }

  close() {
    this.dialog.getDialogById('job-bid-details')?.addPanelClass('animate-out');
    setTimeout(() => {
      this.dialog.getDialogById('job-bid-details')?.close();
    }, 500);
  }

  addRow(i: number) {
    this.jobQuote.products[i].subItems.push({
      name: '',
      price: '',
      quantity: '',
      installerAdded: true,
    });
  }

  addProduct() {
    this.jobQuote.products.push({
      product: new Product(),
      quantity: '',
      price: '',
      subItems: [],
    });
  }

  isNumber(val: any) {
    var isNumber = !Number.isNaN(Number(val));
    // console.log("IS NUMBER "+val+" = "+isNumber);
    return isNumber;
  }

  validate() {
    this.errors = { price: [], subItems: [] };
    let hasError = false;
    let partialQuote = false;
    for (let i = 0; i < this.jobQuote.products.length; i++) {
      let item = this.jobQuote.products[i];
      if (!item.price && item.product.productCollection) {
        this.errors.price.push(i);
        hasError = true;
      } else {
        partialQuote = true;
      }
      this.errors.subItems.push({ price: [], name: [], quantity: [] });
      for (let j = 0; j < item.subItems.length; j++) {
        let subItem = item.subItems[j];
        if (subItem.name || subItem.price || subItem.quantity) {
          if (!subItem.price && subItem.name) {
            this.errors.subItems[i].price.push(j);
            hasError = true;
          } else {
            partialQuote = true;
          }
          //Custom sub item check
          if (!subItem.name && subItem.installerAdded) {
            this.errors.subItems[i].name.push(j);
            hasError = true;
          } else if (!subItem.quantity && subItem.installerAdded) {
            this.errors.subItems[i].quantity.push(j);
            hasError = true;
          } else if (subItem.price) {
            //Custom sub item setup correctly
            partialQuote = true;
          }
        }
      }
      item.subItems = item.subItems.filter(
        (subItem) => subItem.name || subItem.price || subItem.quantity,
      );
    }
    this.jobQuote.products = this.jobQuote.products.filter(
      (product) => product.product.productCollection,
    );
    console.log(this.errors);
    if (!hasError) {
      partialQuote = false;
    }
    return { error: !hasError, partialQuote: partialQuote };
  }

  checkDidNotWin() {
    DatabaseHelper.instance
      .getUserJobPost(this.jobQuote.job.jobPostNumber)
      .then((val) => {
        this.didNotWin = val.jobPost.winnerQuoteNumber.includes(
          this.jobQuote.jobQuoteNumber,
        );
      });
  }

  submitQuote() {
    var validationResponse: any = this.validate();
    console.log('validation - ' + JSON.stringify(validationResponse));
    if (
      validationResponse.partialQuote &&
      !this.jobQuote.job.siteVisitRequired
    ) {
      //User has entered a partial quote so notify them that they can submit a partial quote
      console.log('partial quote thanks!');
      this.dialog.open(AlertViewComponent, {
        maxWidth: '400px',
        panelClass: ['alert-modal', 'no-padding'],
        id: 'alert-modal',
        data: {
          title: 'Submit partial quote',
          message:
            'Do you want to submit a partial quote? You will only be eligible to complete part of this job and will need to work with other installers on this job.',
          primaryButton: 'Submit partial quote',
          secondaryButton: 'Cancel',
        },
      });
      DatabaseHelper.instance.dataCallbacks.alertViewCallback = (
        button: string,
      ) => {
        if (button === 'Cancel') {
        } else if (button === 'Submit partial quote') {
          this.submittingQuote = true;
          this.jobQuote.quoteSubmittedDate = new Date().getTime().toString();
          this.jobQuote.quotedAmount = this.totalQuoteAmount;
          DatabaseHelper.instance
            .submitJobQuote(this.jobQuote)
            .then((val) => {
              this.submittingQuote = false;
            })
            .catch((error) => {
              console.error('error submitting quote:', error);
            });
        }
      };
      return;
    } else if (!validationResponse.error) {
      return;
    } else {
      this.submittingQuote = true;
      this.jobQuote.quoteSubmittedDate = new Date().getTime().toString();
      this.jobQuote.quotedAmount = this.totalQuoteAmount;
      DatabaseHelper.instance
        .submitJobQuote(this.jobQuote)
        .then((val) => {
          this.submittingQuote = false;
        })
        .catch((error) => {
          console.error('error submitting quote:', error);
        });
    }
  }

  acceptQuote() {
    //If the quote is partial then ask the user if they want to close the job or not
    if (this.isPartialQuote) {
      //Should the job now be closed or will the user accept more quotes?
      this.dialog.open(AlertViewComponent, {
        maxWidth: '400px',
        panelClass: ['alert-modal', 'no-padding'],
        id: 'alert-modal',
        data: {
          title: 'Close job?',
          message: 'Is this the last quote you will be accepting for this job?',
          primaryButton: 'Yes, close this job',
          secondaryButton: 'No',
        },
      });
      DatabaseHelper.instance.dataCallbacks.alertViewCallback = (
        button: string,
      ) => {
        if (button === 'Yes, close this job') {
          this.acceptingQuote = true;
          this.jobQuote.quoteAcceptedDate = new Date().getTime().toString();
          this.jobQuote.won = true;
          var quoteStatus = 'Closed';
          DatabaseHelper.instance
            .acceptJobQuote(this.jobQuote, quoteStatus)
            .then((val) => {
              this.acceptingQuote = false;
              DatabaseHelper.instance.dataCallbacks.dataCallback({
                reloadJobPost: true,
              });
            });
        } else if (button === 'No') {
          this.acceptingQuote = true;
          this.jobQuote.quoteAcceptedDate = new Date().getTime().toString();
          this.jobQuote.won = true;
          var quoteStatus = 'Active';
          DatabaseHelper.instance
            .acceptJobQuote(this.jobQuote, quoteStatus)
            .then((val) => {
              this.acceptingQuote = false;
              DatabaseHelper.instance.dataCallbacks.dataCallback({
                reloadJobPost: true,
              });
            });
        }
      };
    } else {
      this.acceptingQuote = true;
      this.jobQuote.quoteAcceptedDate = new Date().getTime().toString();
      this.jobQuote.won = true;
      var quoteStatus = 'Closed';
      DatabaseHelper.instance
        .acceptJobQuote(this.jobQuote, quoteStatus)
        .then((val) => {
          this.acceptingQuote = false;
          DatabaseHelper.instance.dataCallbacks.dataCallback({
            reloadJobPost: true,
          });
        });
    }
  }

  openUrl(url: string) {
    window.open(url, '_blank');
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  get totalQuoteAmount() {
    let amount = 0;
    this.jobQuote.products.forEach((p) => {
      amount += +p.quantity * +p.price || 0;
      p.subItems.forEach((s) => {
        if (!this.isNumber(s.quantity)) {
          //Add the price
          amount += +s.price || 0;
        } else if (s.name && s.price && s.quantity) {
          amount += +s.quantity * +s.price || 0;
        }
      });
    });
    return this.utilityHelper.formatPrice(amount);
  }

  get isQuoteSubmitted() {
    console.log(
      'Is quote submitted? = ' + this.jobQuote.quoteSubmittedDate &&
        this.jobQuote.uid === this.user.uid &&
        !this.submittingQuote,
    );
    return (
      this.jobQuote.quoteSubmittedDate &&
      this.jobQuote.uid === this.user.uid &&
      !this.submittingQuote
    );
  }

  get isBuilder() {
    return (
      AuthHelper.instance.user.type === 'Builder' ||
      this.builderJob.jobPostNumber
    );
  }

  get user() {
    return AuthHelper.instance.user;
  }

  get utilityHelper() {
    return UtilityHelper;
  }

  get isPartialQuote() {
    var isPartialQuote = false;
    this.jobQuote.products.forEach((product) => {
      if (!product.price) {
        isPartialQuote = true;
      }

      product.subItems.forEach((subItem) => {
        if (!subItem.price) {
          isPartialQuote = true;
        }
      });
    });
    return isPartialQuote;
  }
}
