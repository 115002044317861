<div class="header">
  <div (click)="cancel()"><img src="../../../assets/images/close_icon.png" class="close-icon"></div>
</div>

<div id="scrollContainerEstimationSteps" class="container-holder">
  <div class="container">
    <div class="steps-heading">
      <h1><img src="../../../assets/images/FLRHUB-Logo_UPDATED.svg" width="135" height="41" class="logo"><span>Estimation
          services</span></h1>
      <div class="steps-counter">
        <ul>
          <li class="active" data-index="1">1</li>
          <li data-index="2">2</li>
          <li data-index="3">3</li>
        </ul>
      </div>
    </div>

    <div class="steps-holder" id="step-chose-materials" data-step="1">
      <div class="steps-context">
        <!-- <div class="back-button"><a class="secondary-button small-button">Back</a></div> -->
        <div class="head">
          <h2>Job Name</h2>
          <input type="text" name="project_name" placeholder="Enter Job Name Here ..." [(ngModel)]="user.estimationCart.jobName" (ngModelChange)="checkJobName()">
          <div class="error-message">Please add the Job Name</div>
        </div>
        <div class="entry-details">
          <h2>Choose Materials for the Job</h2>
          <div class="materials-selected">
            <div class="material-item" *ngFor="let jobItems of user.estimationCart.jobItems; let i = index;">
              <div class="inner">
                <div class="thumb"><img class="job-item-image" [src]="jobItems.product.thumbnailImageUrl"></div>
                <div class="details">
                  <div class="item-name">
                    <div class="the-name">{{jobItems.product.productCollection}}</div>
                  </div>
                  <div class="item-category">{{jobItems.product.productCategory}}</div>
                  <div class="item-design">{{jobItems.product.productDesign}}{{jobItems.product.variations && jobItems.product.variations['Size'] ? ' - '+jobItems.product.variations['Size'].option : ''}}</div>
                </div>
                <div class="item-delete">
                  <div class="secondary-button small-button" (click)="deleteJobItem(i)">Delete</div>
                </div>
              </div>
            </div>
          </div>
          <div class="dropbox-product">
            <a class="materials-add" (click)="openProductSelector()" [ngClass]="{loading: !user.firstName}">+ Select
              Materials</a>
          </div>
          <div *ngIf="user.estimationCart.jobItems.length > 0" class="next-steps">
            <a class="primary-button" (click)="continueUploadDocument()"
              [ngClass]="{loading: loadingNextSteps}">Next</a>
            <div class="message">*You’ll have the option to submit these materials for order later</div>
          </div>
        </div><!-- entry-details -->
      </div><!-- steps-context -->
      <div class="steps-sidebar"></div>
    </div><!-- steps-holder -->

    <div class="steps-holder" id="step-upload-document" data-step="2" style="display:none">
      <div class="steps-context">
        <div class="back-button"><a class="secondary-button small-button" (click)="backToChooseMaterials()">Back</a></div>
        <div class="entry-details">
          <div class="instruction">
            <h2>Upload your floor plans</h2>
            <p>Please use the codes to mark where specific flooring types go on your floor plans. You can change the codes if your plans are already marked.</p>
          </div>
          <div class="map-material">
            <div class="map-item" *ngFor="let jobItems of user.estimationCart.jobItems; let n = index;">
              <div class="input">
                <input class="bottom-border" *ngIf="user.estimationCart.jobItems[n].code" type="text" name="map_code_[{{n}}]"
                  [(ngModel)]="user.estimationCart.jobItems[n].code"
                  [placeholder]="dynamicPlaceholder(n, user.estimationCart.jobItems.length)">
                <input class="bottom-border" *ngIf="! user.estimationCart.jobItems[n].code" type="text" name="map_code_[{{n}}]"
                  [value]="dynamicPlaceholder(n, user.estimationCart.jobItems.length)">
              </div>
              <div class="name"><img class="map-item-image" [src]="jobItems.product.thumbnailImageUrl">
                <span>{{jobItems.product.productCollection}} - {{jobItems.product.productCategory}} -
                  {{jobItems.product.productDesign}}{{jobItems.product.variations && jobItems.product.variations['Size'] ? ' - '+jobItems.product.variations['Size'].option : ''}}</span></div>
            </div>
            <div class="message error" style="display:none">Codes must be filled and unique for each material.</div>
          </div>

          <!-- <div class="map-document">
            <div class="input-section">
              <label for="uploadFloorPlans">
                <input id="uploadFloorPlans" class="file-input" type="file"
                  (change)="uploadFloorPlans($event, 'floorPlansUrl')">
                <div class="secondary-button upload-button-icon" [ngClass]="{loading: estimationDocumentUploading}">
                  {{user.estimationCart.jobDocument ? 'Change document' : 'Upload document'}}</div>
                <div class="input-description size-error" *ngIf="errors.floorPlansUrl">Please upload your floor plans
                </div>
              </label>
            </div>
            <div class="message error" style="display:none">Please upload floor plan.</div>
          </div> -->

          <div class="map-document">
            <div class="input-section">
              <label for="uploadFloorPlans">
                <input id="uploadFloorPlans" class="file-input" type="file"
                  (change)="uploadFloorPlans($event, 'floorPlansUrl')">
                <div class="secondary-button upload-button-icon" [ngClass]="{loading: estimationDocumentUploading}">
                  Upload document</div>
                <div class="input-description size-error" *ngIf="errors.floorPlansUrl">Please upload your floor plans
                </div>
              </label>
            </div>
            <div class="message error" style="display:none">Please upload floor plan.</div>

            <div class="upload-section">
              <img src="../../../assets/images/folder_icon.png" alt="">
              <div class="uploaded-file" *ngFor="let file of user.estimationCart.jobDocuments">
                <img src="../../../assets/images/file-icon.png" alt="">
                <p (click)="openUrl(file.url)">{{ file.name }}</p>
                <img (click)="deleteFloorPlans(file.url)" src="../../../assets/images/thin_cross.png" alt="" class="cross-icon">
              </div>
            </div>
          </div>

          <div *ngIf="user.estimationCart.jobItems.length > 0" class="next-steps left-aligned">
            <a class="primary-button" (click)="continueAssignDocument(2)"
              [ngClass]="{loading: loadingNextSteps}">Next</a>
            <div class="error-message show-default">Please ensure to label which materials are to be laid on which part of the floor plans and check areas are coloured in the correct places or the process may be delayed.</div>
          </div>

        </div><!-- entry-details -->
      </div><!-- steps-context -->
      <div class="steps-sidebar">
        <div class="sidebar-box">
          <div class="inner">
            <h3>Selected Materials</h3>
            <div class="selected-materials">
              <div class="material-item" *ngFor="let jobItems of user.estimationCart.jobItems; let i = index;">
                <div class="inner">
                  <div class="thumb"><img class="job-item-image" [src]="jobItems.product.thumbnailImageUrl"></div>
                  <div class="details">
                    <div class="item-name">
                      <span>{{jobItems.product.productCollection}} - {{jobItems.product.productCategory}} -
                        {{jobItems.product.productDesign}}{{jobItems.product.variations && jobItems.product.variations['Size'] ? ' - '+jobItems.product.variations['Size'].option : ''}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div><!-- sidebar-box -->

      </div><!-- steps-sidebar -->
    </div><!-- steps-holder -->

    <div class="steps-holder" id="step-assign-code-document" data-step="3" style="display:none">
      <div class="steps-context">
        <div class="back-button"><a class="secondary-button small-button" (click)="backToUploadDocument(1)">Back</a></div>
        <div class="entry-details">

          <div class="question-collections">
            <div class="question-items" [attr.data-code]="user.estimationCart.jobItems[i].code" *ngFor="let jobItems of user.estimationCart.jobItems; let i = index;">
              <div class="question-inner">
                <div class="question-head">
                  <div class="code">{{user.estimationCart.jobItems[i].code}}</div>
                  <div class="product-name">{{jobItems.product.productCollection}} -
                    {{jobItems.product.productCategory}} - {{jobItems.product.productDesign}}</div>
                </div>
                <div class="question-details">

                  <div class="group-questions">
                    <div class="question-item"
                      *ngFor="let qProduct of user.estimationCart.jobItems[i].product | mapQuestion; let qindex = index"
                      [attr.data-trigger-condition]="qProduct.check_on_change"
                      [attr.data-condition-target]="qProduct.condition?.target"
                      [attr.data-condition-value]="qProduct.condition?.value" [attr.data-type]="qProduct.type"
                      [attr.data-job-item-index]="i"
                      [attr.data-question-index]="qindex"
                      [class.hide-default]="qProduct.condition?.target" [class.required]="qProduct.required == true">

                      <div class="question" *ngIf="qProduct.question">{{qProduct.question}}</div>
                      <div class="description" *ngIf="qProduct.desc != ''" [innerHTML]="qProduct.desc"></div>

                      <div class="question-field type-boolean type-{{qProduct.type}}"
                        *ngIf="qProduct.type == 'boolean_extra'">
                        <label>
                          <input type="radio" name="q{{i+'-'+qindex}}" value="Yes" #item
                            [ngModel]="getBooleanExtra( user.estimationCart.jobItems[i].questions[qindex].answer, 0 )"
                            (ngModelChange)="booleanExtraCheckLogic($event, item, qProduct.extra_field_condition, i, qindex)">
                          <div class="label">Yes</div>
                        </label>
                        <label>
                          <input type="radio" name="q{{i+'-'+qindex}}" value="No" #item
                            [ngModel]="getBooleanExtra( user.estimationCart.jobItems[i].questions[qindex].answer, 0 )"
                            (ngModelChange)="booleanExtraCheckLogic($event, item, qProduct.extra_field_condition, i, qindex)">
                          <div class="label">No</div>
                        </label>
                        <div class="extra-field {{qProduct.extra_field_condition}}" *ngIf="qProduct.extra_field"
                          [ngClass]="qProduct.extra_field_required == true ? 'required' : ''"
                          [ngStyle]="checkBooleanDisplay( user.estimationCart.jobItems[i].questions[qindex].answer )">
                          <label *ngIf="qProduct.extra_field_label">{{qProduct.extra_field_label}}</label>
                          <div class="input-field">
                            <input type="text" name="extra_{{i+'-'+qindex}}" placeholder="Type here..." #item
                              [ngModel]="getBooleanExtra( user.estimationCart.jobItems[i].questions[qindex].answer, 1)"
                              (ngModelChange)="checkExtraFieldChanges($event, item, qProduct.extra_field_condition, i, qindex)">
                          </div>
                        </div>
                      </div><!-- type-boolean-with-extra -->

                      <div class="question-field type-boolean type-{{qProduct.type}}"
                        *ngIf="qProduct.type == 'boolean_extra_with_file_upload'">
                        <label>
                          <input type="radio" name="q{{i+'-'+qindex}}" value="Yes" #item
                            [ngModel]="getBooleanExtra( user.estimationCart.jobItems[i].questions[qindex].answer, 0 )"
                            (ngModelChange)="booleanExtraCheckLogic($event, item, qProduct.extra_field_condition, i, qindex)">
                          <div class="label">Yes</div>
                        </label>
                        <label>
                          <input type="radio" name="q{{i+'-'+qindex}}" value="No" #item
                            [ngModel]="getBooleanExtra( user.estimationCart.jobItems[i].questions[qindex].answer, 0 )"
                            (ngModelChange)="booleanExtraCheckLogic($event, item, qProduct.extra_field_condition, i, qindex)">
                          <div class="label">No</div>
                        </label>
                        <div class="extra-field {{qProduct.extra_field_condition}}" *ngIf="qProduct.extra_field"
                          [ngClass]="qProduct.extra_field_required == true ? 'required' : ''"
                          [ngStyle]="checkBooleanDisplay( user.estimationCart.jobItems[i].questions[qindex].answer )">
                          <label *ngIf="qProduct.extra_field_label">{{qProduct.extra_field_label}}</label>
                          <div class="input-field">
                            <input type="text" name="extra_{{i+'-'+qindex}}" placeholder="Type here..." #item
                              [ngModel]="getBooleanExtra( user.estimationCart.jobItems[i].questions[qindex].answer, 1)"
                              (ngModelChange)="checkExtraFieldChanges($event, item, qProduct.extra_field_condition, i, qindex)">
                          </div>
                          <div class="input-section">
                            <label [for]="'levelFloorPlan_'+i+'_'+qindex">
                              <input [id]="'levelFloorPlan_'+i+'_'+qindex" class="file-input" type="file"
                                (change)="uploadLevelFloorPlan($event, user.estimationCart.jobItems[i].questions[qindex])">
                              <div class="secondary-button upload-button-icon" [ngClass]="{loading: estimationDocumentUploading}">
                                {{user.estimationCart.jobItems[i].questions[qindex].fileUrl ? 'Change document' : 'Upload document'}}</div>
                            </label>
                          </div>
                        </div>
                      </div><!-- type-boolean-with-extra-and-file-input -->

                      <div class="question-field type-boolean type-{{qProduct.type}}"
                        *ngIf="qProduct.type == 'textarea_with_file_upload'">
                        <div class="extra-field">
                          <label *ngIf="qProduct.question">{{qProduct.question}}</label>
                          <div class="input-field">
                            <input type="text" name="extra_{{i+'-'+qindex}}" placeholder="Type here..." #item
                              [ngModel]="user.estimationCart.jobItems[i].questions[qindex].answer"
                              (ngModelChange)="user.estimationCart.jobItems[i].questions[qindex].answer = $event">
                          </div>
                          <div class="input-section">
                            <label [for]="'extraFiles_'+i+'_'+qindex">
                              <input [id]="'extraFiles_'+i+'_'+qindex" class="file-input" type="file"
                                (change)="uploadLevelFloorPlan($event, user.estimationCart.jobItems[i].questions[qindex])">
                              <div class="secondary-button upload-button-icon" [ngClass]="{loading: estimationDocumentUploading}">
                                {{user.estimationCart.jobItems[i].questions[qindex].fileUrl ? 'Change document' : 'Upload document'}}</div>
                            </label>
                          </div>
                        </div>
                      </div><!-- textarea-with-extra-and-file-input -->

                      <div class="question-field type-{{qProduct.type}}" *ngIf="qProduct.type == 'boolean'">
                        <label>
                          <input type="radio" name="q{{i+'-'+qindex}}" value="Yes" #item
                            [(ngModel)]="user.estimationCart.jobItems[i].questions[qindex].answer"
                            (ngModelChange)="booleanCheckLogic($event, item, qProduct.extra_field_condition, i)">
                          <div class="label">Yes</div>
                        </label>
                        <label>
                          <input type="radio" name="q{{i+'-'+qindex}}" value="No" #item
                            [(ngModel)]="user.estimationCart.jobItems[i].questions[qindex].answer"
                            (ngModelChange)="booleanCheckLogic($event, item, qProduct.extra_field_condition, i)">
                          <div class="label">No</div>
                        </label>
                      </div><!-- type-boolean -->

                      <div class="question-field type-{{qProduct.type}}" *ngIf="qProduct.type == 'text'">
                        <div class="input-field">
                          <input type="text" name="q{{i+'-'+qindex}}" placeholder="Type here..." #item
                            [(ngModel)]="user.estimationCart.jobItems[i].questions[qindex].answer"
                            (ngModelChange)="inputCheckLogic($event, item, i)">
                        </div>
                      </div><!-- type-text -->

                      <div class="question-field type-{{qProduct.type}}" *ngIf="qProduct.type == 'textarea'">
                        <div class="input-field">
                          <textarea name="q{{i+'-'+qindex}}" placeholder="Type here..." #item
                            [(ngModel)]="user.estimationCart.jobItems[i].questions[qindex].answer"
                            (ngModelChange)="textareaCheckLogic($event, item, i)"></textarea>
                        </div>
                      </div><!-- type-textarea -->

                      <div class="question-field type-{{qProduct.type}}" *ngIf="qProduct.type == 'radio'">
                        <label *ngFor="let radioChoice of qProduct.choices; let cindex = index">
                          <input type="radio" name="q{{i+'-'+qindex}}" value="{{qProduct.choices[cindex]}}" #item
                            [(ngModel)]="user.estimationCart.jobItems[i].questions[qindex].answer"
                            (ngModelChange)="radioCheckLogic($event, item, qProduct.check_on_change, i)">
                          <div class="label">{{qProduct.choices[cindex]}}</div>
                        </label>
                      </div><!-- type-radio -->

                      <div class="question-field type-{{qProduct.type}}" *ngIf="qProduct.type == 'radio_extra'">
                        <label *ngFor="let radioChoice of qProduct.choices; let cindex = index">
                          <input type="radio" name="q{{i+'-'+qindex}}" value="{{qProduct.choices[cindex]}}" #item
                            [ngModel]="getBooleanExtra( user.estimationCart.jobItems[i].questions[qindex].answer, 0 )"
                            (ngModelChange)="radioExtraCheckLogic($event, item, qProduct.check_on_change, i, qindex)">
                          <div class="label">{{qProduct.choices[cindex]}}</div>
                        </label>
                        <div class="extra-field" *ngIf="showRadioExtra(i, qindex, qProduct)"
                          [ngClass]="qProduct.extra_field_required == true ? 'required' : ''">
                          <label *ngIf="qProduct.extra_field_label">{{qProduct.extra_field_label}}</label>
                          <div class="input-field">
                            <input type="text" name="extra_{{i+'-'+qindex}}" placeholder="Type here..." #item
                              [ngModel]="getBooleanExtra( user.estimationCart.jobItems[i].questions[qindex].answer, 1)"
                              (ngModelChange)="checkExtraFieldChanges($event, item, qProduct.extra_field_condition, i, qindex)">
                          </div>
                        </div>
                      </div><!-- type-radio_extra -->

                    </div><!-- question-item -->
                  </div><!-- group-questions -->

                </div><!-- question-details -->
              </div>
            </div><!-- question-item -->
          </div><!-- question-collection -->


          <div *ngIf="user.estimationCart.jobItems.length > 0" class="next-steps left-aligned">
            <a class="primary-button" (click)="continuePayment(3)" [ngClass]="{loading: loadingNextSteps}">Next</a>
            <div class="error-message">Please make sure you answer all required questions.</div>
          </div>

        </div><!-- entry-details -->
      </div><!-- steps-context -->
      <div class="steps-sidebar">
        <div class="sidebar-box">
          <div class="inner">
            <h3>Selected Materials</h3>
            <div class="selected-materials">
              <div class="material-item" [ngClass]="{active: jobItems.code == activeCode}" *ngFor="let jobItems of user.estimationCart.jobItems; let i = index;" (click)="scrollToProduct(jobItems.code)">
                <div class="inner">
                  <div class="thumb"><img class="job-item-image" [src]="jobItems.product.thumbnailImageUrl"></div>
                  <div class="details">
                    <div class="item-name">
                      <span>{{jobItems.product.productCollection}} - {{jobItems.product.productCategory}} -
                        {{jobItems.product.productDesign}}{{jobItems.product.variations && jobItems.product.variations['Size'] ? ' - '+jobItems.product.variations['Size'].option : ''}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div><!-- sidebar-box -->
        <div class="sidebar-box">
          <div class="inner">
            <h3>Floor Plan Documents</h3>
            <div class="selected-document" *ngFor="let file of user.estimationCart.jobDocuments">
              <div class="secondary-button file-icon" (click)="openUrl(file.url)"><img class="icon medium-size" src="../../../assets/images/file-icon.png">{{formatFilename(file.name)}}</div>
            </div>
          </div>
        </div><!-- sidebar-box -->

      </div><!-- steps-sidebar -->

    </div><!-- steps-holder -->

    <div class="steps-holder" id="step-payment" data-step="4" style="display:none">
      <div class="steps-context">
        <div class="back-button"><a class="secondary-button small-button" (click)="backToAssignCode(2)">Back</a></div>
        <div class="entry-details">
          <div class="payment-head">
            <h2>Enter your payment details</h2>
            <div class="price"><span>$</span>{{user.estimationCart.jobPrice.toFixed(2)}}</div>
          </div>
          <!-- <div class="payment-note">No commitment. Cancel anytime.</div> -->

          <div class="payment-box">
            <div class="card-payment-container">
              <div class="card-payment-heading">
                <div class="card-title">Credit Card</div>
                <div class="spacer"></div>
                <div class="secure">
                  <img class="lock-icon" src="../../../assets/images/lock_icon.png">
                  <div class="secure-text">
                    <div class="secure-text-1">Secure checkout</div>
                    <div class="secure-text-2">Pay safely with SSL technology.</div>
                  </div>
                </div>
                <div class="payment-icons">
                  <img src="../../../assets/images/visa_icon.png">
                  <img src="../../../assets/images/amex_icon.png">
                  <img src="../../../assets/images/mastercard_icon.png">
                </div>
              </div>
              <div class="input-field" [ngClass]="{error: errors.cardName}">
                <label for="name">Name On Card</label>
                <input id="name" [(ngModel)]="cardName" (ngModelChange)="validateCard()" placeholder="Type here..."
                  type="text" autocomplete="name">
              </div>
              <div class="input-field" [ngClass]="{error: errors.cardNumber}">
                <label for="cardNumber">Card Number</label>
                <input id="cardNumber" [(ngModel)]="cardNumber" (ngModelChange)="validateCard()"
                  placeholder="•••• •••• •••• ••••" type="text" maxlength="19" cc-number>
              </div>
              <div class="input-field-row">
                <div class="input-field" [ngClass]="{error: errors.cardExpiry}">
                  <label for="expiry">Expiration (MM/YY)</label>
                  <input id="expiry" [(ngModel)]="cardExpiry" (ngModelChange)="validateCard()" placeholder="MM/YY"
                    type="text" maxlength="5" cc-expiry>
                </div>
                <div class="input-field" [ngClass]="{error: errors.cardCvc}">
                  <label for="cvc">CVC</label>
                  <input id="cvc" [(ngModel)]="cardCvc" (ngModelChange)="validateCard()" placeholder="Type here..."
                    type="text" numbersOnly="true">
                </div>
              </div>
              <div *ngIf="paymentErrorMessage" style="margin-bottom: 35px;" class="error"
                [innerHTML]="paymentErrorMessage"></div>
              <div class="pay-row">
                <div class="terms-checkbox">
                  <div class="check-box" [ngClass]="{selected: termsAccepted}" (click)="termsAccepted = !termsAccepted; errors.termsAccepted = false;">
                    <img src="../../../assets/images/check_icon.png">
                  </div>
                  <div class="terms-text" [ngClass]="{'terms-error': errors.termsAccepted}">I agree to the <a href="/estimation-terms" target="_blank">terms and conditions</a></div>
                </div>
                <div class="spacer"></div>
                <div class="primary-button" [ngClass]="{loading: paymentLoading}" (click)="submitEstimationOrder()"><!--"submitEstimationOrder()">-->
                  Submit</div>
              </div>
            </div>
          </div><!-- payment-box -->

        </div><!-- entry-details -->
      </div><!-- steps-context -->

      <div class="steps-sidebar">
        <div class="sidebar-box">
          <div class="inner">
            <h3>Selected Materials</h3>
            <div class="selected-materials">
              <div class="material-item" *ngFor="let jobItems of user.estimationCart.jobItems; let i = index;">
                <div class="inner">
                  <div class="thumb"><img class="job-item-image" [src]="jobItems.product.thumbnailImageUrl"></div>
                  <div class="details">
                    <div class="item-name">
                      <span>{{jobItems.product.productCollection}} - {{jobItems.product.productCategory}} -
                        {{jobItems.product.productDesign}}{{jobItems.product.variations && jobItems.product.variations['Size'] ? ' - '+jobItems.product.variations['Size'].option : ''}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div><!-- sidebar-box -->
        <div class="sidebar-box">
          <div class="inner">
            <h3>Floor Plan Documents</h3>
            <div class="selected-document" *ngFor="let file of user.estimationCart.jobDocuments">
              <div class="secondary-button file-icon" (click)="openUrl(file.url)"><img class="icon medium-size" src="../../../assets/images/file-icon.png">{{formatFilename(file.name)}}</div>
            </div>
          </div>
        </div><!-- sidebar-box -->
      </div><!-- steps-sidebar -->
    </div><!-- steps-holder -->

  </div><!-- container -->
</div>
