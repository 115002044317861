import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AngularFireAuthGuard,
  redirectUnauthorizedTo,
  redirectLoggedInTo,
} from '@angular/fire/compat/auth-guard';
import { SplashComponent } from './pages/splash/splash.component';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { ProductDetailsComponent } from './pages/product-details/product-details.component';
import { ProductsComponent } from './pages/products/products.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { SignUpDetailsComponent } from './pages/sign-up-details/sign-up-details.component';
import { SignUpPaymentComponent } from './pages/sign-up-payment/sign-up-payment.component';
import { ProductIndustryComponent } from './pages/product-industry/product-industry.component';
import { CartComponent } from './pages/cart/cart.component';
import { ShippingComponent } from './pages/shipping/shipping.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { ReviewComponent } from './pages/review/review.component';
import { OrderSubmittedComponent } from './pages/order-submitted/order-submitted.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { OrderDetailsComponent } from './pages/order-details/order-details.component';
import { TermsComponent } from './pages/terms/terms.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { ReturnsComponent } from './pages/returns/returns.component';
import { DeliveryComponent } from './pages/delivery/delivery.component';
import { CanActivateAccountActive } from './guards/CanActivateAccountActive';
import { AccountPendingComponent } from './pages/account-pending/account-pending.component';
import { AccountDisabledComponent } from './pages/account-disabled/account-disabled.component';
import { AccountCancelledComponent } from './pages/account-cancelled/account-cancelled.component';
import { AccountCreatedComponent } from './pages/account-created/account-created.component';
import { SignUpCompanyDetailsComponent } from './pages/sign-up-company-details/sign-up-company-details.component';
import { OrderUpdatedComponent } from './pages/order-updated/order-updated.component';
import { OrderConfirmedComponent } from './pages/order-confirmed/order-confirmed.component';
import { LandingComponent } from './pages/landing/landing.component';
import { EstimationComponent } from './pages/estimation/estimation.component';
import { EstimationsComponent } from './pages/estimations/estimations.component';
import { EstimationTermsComponent } from './pages/estimation-terms/estimation-terms.component';
import { EstimationSubmittedComponent } from './pages/estimation-submitted/estimation-submitted.component';
import { EstimatorConfirmationComponent } from './pages/estimator-confirmation/estimator-confirmation.component';
import { JobBoardComponent } from './pages/job-board/job-board.component';
import { JobBoardPostVisitComponent } from './pages/job-board-post-visit/job-board-post-visit.component';
import { JobBoardPostNoVisitComponent } from './pages/job-board-post-no-visit/job-board-post-no-visit.component';
import { MyJobPostsComponent } from './pages/my-job-posts/my-job-posts.component';
import { MyJobBidsComponent } from './pages/my-job-bids/my-job-bids.component';
import { MyJobPostDetailsComponent } from './pages/my-job-post-details/my-job-post-details.component';
import { JobDetailsComponent } from './pages/job-details/job-details.component';
import { AuthGuard } from './auth.guard';
import { CustomerJobPostsComponent } from './pages/customer-job-posts/customer-job-posts.component';
import { CustomerJobDetailComponent } from './pages/customer-job-detail/customer-job-detail.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToHome = () => redirectLoggedInTo(['home']);

const routes: Routes = [
  {
    path: 'splash',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectLoggedInToHome,
    },
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'sign-up',
    component: SignUpComponent,
  },
  {
    path: 'sign-up-details',
    component: SignUpDetailsComponent,
  },
  {
    path: 'sign-up-company-details',
    component: SignUpCompanyDetailsComponent,
  },
  {
    path: 'sign-up-payment',
    component: SignUpPaymentComponent,
  },
  {
    path: 'terms',
    component: TermsComponent,
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
  },
  {
    path: 'returns',
    component: ReturnsComponent,
  },
  {
    path: 'estimator-confirmation',
    component: EstimatorConfirmationComponent,
  },
  {
    path: 'delivery',
    component: DeliveryComponent,
  },
  {
    path: 'account-pending',
    component: AccountPendingComponent,
    canActivate: [AuthGuard, CanActivateAccountActive],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'account-created',
    component: AccountCreatedComponent,
  },
  {
    path: 'account-cancelled',
    component: AccountCancelledComponent,
    canActivate: [AuthGuard, CanActivateAccountActive],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'account-disabled',
    component: AccountDisabledComponent,
    canActivate: [AuthGuard, CanActivateAccountActive],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'landing',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'estimation',
    component: EstimationComponent,
    canActivate: [AuthGuard, CanActivateAccountActive],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'job-board',
    component: JobBoardComponent,
    canActivate: [AuthGuard, CanActivateAccountActive],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'customer-jobs',
    component: CustomerJobPostsComponent,
    canActivate: [AuthGuard, CanActivateAccountActive],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'job-board-post-visit',
    component: JobBoardPostVisitComponent,
    canActivate: [AuthGuard, CanActivateAccountActive],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'job-board-post-no-visit',
    component: JobBoardPostNoVisitComponent,
    canActivate: [AuthGuard, CanActivateAccountActive],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  // {
  //   path: '',
  //   component: ProductIndustryComponent,
  //   canActivate: [AuthGuard, CanActivateAccountActive],
  //   //data: {
  //   //  authGuardPipe: redirectUnauthorizedToLogin,
  //   //},
  // },

  {
    path: 'industry',
    component: ProductIndustryComponent,
    canActivate: [AuthGuard, CanActivateAccountActive],
    // data: {
    //   authGuardPipe: redirectUnauthorizedToLogin,
    // },
  },
  {
    path: 'marketplace',
    component: ProductsComponent,
    canActivate: [AuthGuard, CanActivateAccountActive],
    // data: {
    //   authGuardPipe: redirectUnauthorizedToLogin,
    // },
  },
  {
    path: 'product-details',
    component: ProductDetailsComponent,
    canActivate: [AuthGuard, CanActivateAccountActive],
    //data: {
    //  authGuardPipe: redirectUnauthorizedToLogin,
    //},
  },
  {
    path: 'cart',
    component: CartComponent,
    canActivate: [AuthGuard, CanActivateAccountActive],
    //data: {
    //  authGuardPipe: redirectUnauthorizedToLogin,
    //},
  },
  {
    path: 'shipping',
    component: ShippingComponent,
    canActivate: [AuthGuard, CanActivateAccountActive],
    //data: {
    //  authGuardPipe: redirectUnauthorizedToLogin,
    //},
  },
  {
    path: 'payment',
    component: PaymentComponent,
    canActivate: [AuthGuard, CanActivateAccountActive],
    //data: {
    //  authGuardPipe: redirectUnauthorizedToLogin,
    //},
  },
  {
    path: 'review',
    component: ReviewComponent,
    canActivate: [AuthGuard, CanActivateAccountActive],
    //data: {
    //  authGuardPipe: redirectUnauthorizedToLogin,
    //},
  },
  {
    path: 'order-submitted',
    component: OrderSubmittedComponent,
    canActivate: [AuthGuard, CanActivateAccountActive],
    //data: {
    //  authGuardPipe: redirectUnauthorizedToLogin,
    //},
  },
  {
    path: 'order-updated',
    component: OrderUpdatedComponent,
    canActivate: [AuthGuard, CanActivateAccountActive],
    //data: {
    //  authGuardPipe: redirectUnauthorizedToLogin,
    //},
  },
  {
    path: 'order-confirmed',
    component: OrderConfirmedComponent,
    canActivate: [AuthGuard, CanActivateAccountActive],
    //data: {
    //  authGuardPipe: redirectUnauthorizedToLogin,
    //},
  },
  {
    path: 'orders',
    component: OrdersComponent,
    canActivate: [AuthGuard, CanActivateAccountActive],
    //data: {
    //  authGuardPipe: redirectUnauthorizedToLogin,
    //},
  },
  // {
  //   path: 'home',
  //   component: HomeComponent,
  //   canActivate: [AuthGuard, CanActivateAccountActive],
  //   //data: {
  //   //  authGuardPipe: redirectUnauthorizedToLogin,
  //   //},
  // },
  {
    path: 'order-details',
    component: OrderDetailsComponent,
    canActivate: [AuthGuard, CanActivateAccountActive],
    //data: {
    //  authGuardPipe: redirectUnauthorizedToLogin,
    //},
  },
  {
    path: 'estimations',
    component: EstimationsComponent,
    canActivate: [AuthGuard, CanActivateAccountActive],
    //data: {
    //  authGuardPipe: redirectUnauthorizedToLogin,
    //},
  },
  {
    path: 'estimation-terms',
    component: EstimationTermsComponent,
    canActivate: [AuthGuard, CanActivateAccountActive],
    //data: {
    //  authGuardPipe: redirectUnauthorizedToLogin,
    //},
  },
  {
    path: 'estimation-submitted',
    component: EstimationSubmittedComponent,
    canActivate: [AuthGuard, CanActivateAccountActive],
    //data: {
    //  authGuardPipe: redirectUnauthorizedToLogin,
    //},
  },
  {
    path: 'my-job-posts',
    component: MyJobPostsComponent,
    canActivate: [AuthGuard, CanActivateAccountActive],
    //data: {
    //  authGuardPipe: redirectUnauthorizedToLogin,
    //},
  },
  {
    path: 'my-job-post-details',
    component: MyJobPostDetailsComponent,
    canActivate: [AuthGuard, CanActivateAccountActive],
    //data: {
    //  authGuardPipe: redirectUnauthorizedToLogin,
    //},
  },
  {
    path: 'my-job-bids',
    component: MyJobBidsComponent,
    canActivate: [AuthGuard, CanActivateAccountActive],
    //data: {
    //  authGuardPipe: redirectUnauthorizedToLogin,
    //},
  },
  {
    path: 'job-details',
    component: JobDetailsComponent,
    canActivate: [AuthGuard, CanActivateAccountActive],
    //data: {
    //  authGuardPipe: redirectUnauthorizedToLogin,
    //},
  },
  {
    path: 'customer-job-details',
    component: CustomerJobDetailComponent,
    canActivate: [AuthGuard, CanActivateAccountActive],
    //data: {
    //  authGuardPipe: redirectUnauthorizedToLogin,
    //},
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '*',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard, CanActivateAccountActive],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
