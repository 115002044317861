import { Injectable } from "@angular/core";
import { Product } from "../classes/Product";

@Injectable()
export class UtilityHelper {
    static parsePrice(price: string) {
        if (!price) return 0;
        let parsed = Number.parseFloat(price.replace("$", "").replace(new RegExp(",", 'g'), ""));
        if (isNaN(parsed)) return 0;
        return parsed;
    }
    static formatPrice(price: number) {
        if (price === 0) return "$0.00";
        if (!price) return "";
        return "$" + price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }
    static formatCMtoMM(cm: string) {
        if (!cm) return "";
        return (Number.parseFloat(cm.replace("cm", "")) * 10).toFixed(0) + "mm";
    }
    static parseCM(cm: string) {
        if (cm === "") return 0;
        return Number.parseFloat(cm.replace("cm", "").replace("mm", ""));
    }
    static unitTypeToMeasure(unitType: string) {
        // console.log(unitType);
        switch (unitType) {
            case 'Each':
                return 'ea' 
            case 'LM':
                return 'LM' 
            default:
                return 'm²'
        }
    }
    static capitalizeFirstLetter(string: string) {
        // console.log(string);
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }
    static compareWidth(a: string, b: string) {
        return Number.parseFloat(a.replace("mm", "")) - Number.parseFloat(b.replace("mm", ""));
    }

    static isEmailValid(email: string) {
        return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
    }
    static isPhoneValid(number: string, country: string = 'AU') {
        if (country === 'AU') return /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/.test(number);
        else return /^(0|(\+64(\s|-)?)){1}(21|22|27){1}(\s|-)?\d{3}(\s|-)?\d{4}$/.test(number);
    }
    static isPostCodeValid(number: string, country: string) {
        if (country === 'AU') return /^(0[289][0-9]{2})|([1-9][0-9]{3})$/.test(number);
        else return number.length === 4;
    }
    static convertMonthToString(month: number, full: boolean = true) {
        const monthNames = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
        ];
        const monthNamesFull = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];
        return full ? monthNamesFull[month] : monthNames[month];
    }

    static convertDayToString(day: number, full: boolean) {
        const daysFull = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        return full ? daysFull[day] : days[day];
    }
    static formatDate(date: string) {
        let d: Date = new Date(Number(date));
        if (isNaN(Number(date))) {
            d = new Date(Date.parse(date));
        }
        return d.getDate() + " " + this.convertMonthToString(d.getMonth(), true) + " " + d.getFullYear();
    }
    static formatDateTime(d: Date) {
        return ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + " " + ("0" + d.getDate()).slice(-2) + "/" + ("0" + (d.getMonth() + 1)).slice(-2) + "/" + d.getFullYear();
    }
    static formatTime(date: string) {
        let d = this.parseTime(date);
        return d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    }
    static parseTime(date: string) {
        const d = new Date();
        d.setHours(+date.split(":")[0]);
        d.setMinutes(+date.split(":")[1]);
        return d;
    }
    static stripPhoneNumber(n: string) {
        return n.replace(new RegExp(" ", 'g'), "").replace(new RegExp("\\+", 'g'), "");
    }
    static isPhone() {
        return window.matchMedia("(max-width: 1000px)").matches;
    }
    static isABNValid(val: any) {
        val = val.replace(/[^0-9]/g, '');
        let weights = new Array(10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19);
        if (val.length === 11) {
            let sum = 0;
            weights.forEach(function (weight, position) {
                let digit = val[position] - (position ? 0 : 1);
                sum += weight * digit;
            });
            return sum % 89 == 0;
        }
        return false;
    }
    static roundNearest(value: number, nearest: number): number {
        return +(Math.ceil(+(value / nearest).toFixed(2)) * nearest).toFixed(2);
    };
    static getDistanceFromLatLonInKm(lat1 : any, lon1 : any, lat2 : any, lon2 : any) {
        var R = 6371; // Radius of the earth in km
        var dLat = UtilityHelper.deg2rad(lat2 - lat1);  // deg2rad below
        var dLon = UtilityHelper.deg2rad(lon2 - lon1);
        var a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(UtilityHelper.deg2rad(lat1)) * Math.cos(UtilityHelper.deg2rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2)
            ;
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c; // Distance in km
        return d;
    }
    static deg2rad(deg : any) {
        return deg * (Math.PI / 180)
    }
    static weldRodRequired(product: Product) {
        const weldRodCategories = ["Heterogeneous Vinyl", "Homogeneous Vinyl", "Indoor Sports", "Linoleum", "Safety Floors", "Static Control", "Wallcoverings"];
        return weldRodCategories.includes(product.productCategory) && !product.productCollection.includes("Multiflex M");
    }
}