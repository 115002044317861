import {
  AfterViewInit,
  Component,
  Inject,
  NgZone,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router, TitleStrategy, ActivatedRoute } from '@angular/router';
import { ProductCollection } from 'src/app/classes/ProductCollection';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { AuthHelper } from 'src/app/helpers/AuthHelper';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';
import { UtilityHelper } from 'src/app/helpers/UtilityHelper';
import { FiltersComponent } from 'src/app/modals/filters/filters.component';
import { SearchComponent } from 'src/app/modals/search/search.component';
import { Product } from '../../classes/Product';
import { NgOptimizedImage } from '@angular/common';
import { ProductService } from 'src/app/services/product.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Filter } from 'src/app/classes/Filter';
import { FilterItem } from 'src/app/classes/FilterItem';

enum Stock {
  IN_STOCK = 'In Stock',
  OUT_OF_STOCK = 'Out of Stock',
  MADE_TO_ORDER = 'Made to Order',
}

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('filterDialogTemplate') filterDialogTemplate!: TemplateRef<any>;
  dialogRef!: MatDialogRef<any>;
  hideFilters = false;

  private destroy$ = new Subject<void>();
  private loadProducts$ = new Subject<{ searchTerm: string; page: number }>();

  searchTerm: string = '';
  private searchSubject = new Subject<string>();
  productCollections: ProductCollection[] | any = [];
  allProductCollections: ProductCollection[] | any = [];
  products: ProductCollection[] | string | any = [];
  meta: any = [];
  loading = true;
  allLoaded = true;
  perPage = 12;
  page = 0;
  timeout: any;
  pageHasScrolled: boolean = false;
  sortOptions: any[] = [
    { value: 'product', order: 'asc', label: 'Product Name (A-Z)' },
    { value: 'product', order: 'desc', label: 'Product Name (Z-A)' },
    { value: 'price', order: 'asc', label: 'Price (low to high)' },
    { value: 'price', order: 'desc', label: 'Price (high to low)' },
    { value: 'brand', order: 'asc', label: 'Brand (A-Z)' },
    { value: 'brand', order: 'desc', label: 'Brand (Z-A)' },
  ];

  productSort: number = 0;

  pageloaded = false;

  localStockFilterApplied = false;

  productFilters: any = {};

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private zone: NgZone,
    private productService: ProductService,
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params['searchTerm']) {
        this.searchTerm = params['searchTerm'];
        //this.loadProductCollection(this.searchTerm);
      }
      if (params['industry']) {
        this.productFilters = {
          Industries: {
            items: {
              [params['industry']]: {
                name: params['industry'],
                selected: true,
              },
            },
          },
        };
      }

      if (params['clientView'] === 'true') {
        DatabaseHelper.instance.setClientView(params['clientView']);
      }

      //   if(params['industry'] && !productFilters) {
      //     this.productFilters = {
      //       'Industries': {
      //         items: params['industry']{
      //           selected: true
      //         }
      //       }
      //     }
      //   }
    });

    if (
      this.router.getCurrentNavigation()?.extras &&
      this.router.getCurrentNavigation()?.extras.state
    ) {
      if (this.router.getCurrentNavigation()?.extras.state!['searchTerm']) {
        this.searchTerm =
          this.router.getCurrentNavigation()?.extras.state!['searchTerm'];
      }
    }
  }

  isReplacingProduct() {
    if (this.data && !this.data.choosingProduct) {
      return true;
    } else {
      return false;
    }
  }

  isSelectingProduct() {
    if (this.data && this.data.choosingProduct) {
      return true;
    } else {
      return false;
    }
  }

  openFilterDialog(): void {
    this.dialogRef = this.dialog.open(this.filterDialogTemplate, {
      width: '400px', // Adjust width as needed
      autoFocus: false,
    });
  }

  closeDialog(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  ngOnInit(): void {
    // this.hideFilters = true;
    this.searchSubject
      .pipe(debounceTime(500))
      .subscribe((searchTerm) => this.loadProductCollection(searchTerm));

    //  this.loadProducts$
    //    .pipe(
    //      switchMap(({ searchTerm, page }) => {
    //        this.allLoaded = false;
    //        this.loading = true;
    //        return this.productService.getProducts(
    //          searchTerm,
    //          page ?? this.page,
    //          this.sortOptions[this.productSort],
    //          this.productFilters,
    //        );
    //      }),
    //      takeUntil(this.destroy$), // Cleanup when the component is destroyed
    //    )
    //    .subscribe({
    //      next: (products: any) => {
    //        this.meta = products.meta;
    //        this.page = this.meta.current_page;
    //        this.allProductCollections = products.data;
    //        this.productFilters = products.filters;
    //        this.allLoaded = true;
    //        this.loading = false;
    //        this.hideFilters = false;
    //      },
    //      error: (err) => {
    //        console.error('Error loading products', err);
    //        this.loading = false;
    //      },
    //    });

    this.hideFilters = true;
    this.loading = true;
    this.loadProductCollection(this.searchTerm, 1);
  }

  updateClientView(event: any) {
    DatabaseHelper.instance.setClientView(event.checked);

    // Update the URL with the clientView parameter
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { clientView: event.checked },
      queryParamsHandling: 'merge', // Keeps existing query params while updating 'clientView'
    });
    
    // Reload products with current filters to reflect the client view change
    this.loadProductCollection(this.searchTerm, this.page);
  }

  //loadProductCollection(searchTerm: string, page?: number): void {
  //  this.loadProducts$.next({ searchTerm, page: page ?? this.page });
  //}

  loadProductCollection(searchTerm: string, page?: number, filters?: any): void {
    this.loading = true;
    console.log('product sort', this.productSort);
    console.log('filters', this.filters);

    console.log(
      'sending these filters',
      this.filterToSelectedProducts(this.productFilters),
    ),
      this.productService
        .getProducts(
          searchTerm,
          page ?? this.page,
          this.sortOptions[this.productSort],
          this.filterToSelectedProducts(this.productFilters),
        )
        .toPromise()
        .then((products: any) => {
          this.meta = products.meta;
          this.page = this.meta.current_page;
          this.allProductCollections = products.data;
          this.products = JSON.stringify(products);
          this.productFilters = products.filters;
          this.hideFilters = false;
          this.loading = false;
        })
        .catch((err) => {
          console.error('Error loading products', err);
          this.loading = false;
        });
  }

  filterToSelectedProducts(productFilters: any): any {
    let selectedFilters: any = {};

    for (let key in productFilters) {
      selectedFilters[key] = { items: {} };
      
      for (let item in productFilters[key].items) {
        if (
          productFilters[key].items[item] != null &&
          productFilters[key].items[item].selected === true
        ) {
          selectedFilters[key].items[item] = productFilters[key].items[item];
        }
      }
      
      // Remove filter group if no items are selected
      if (Object.keys(selectedFilters[key].items).length === 0) {
        delete selectedFilters[key];
      }
    }

    return selectedFilters;
  }

  handlePageEvent(event: PageEvent) {
    console.log('event happened:', event);
    //This module uses the index of a page, rather than the actual page number
    this.page = event.pageIndex + 1;
    this.loadProductCollection(this.searchTerm, this.page, this.productFilters);
  }

  updateFilterItem(event: any, filter: any, item: any) {
    console.log('updateFilterItem', event, filter, item);
    console.log('productFilters', this.productFilters);
    this.productFilters[filter].items[item.name].selected = event.checked;
    this.page = 1;
    this.loadProductCollection(this.searchTerm, this.page, this.productFilters);
  }

  ngAfterViewInit() {
    document
      .getElementById('scrollContent')
      ?.addEventListener('scroll', (event) => {
        // console.log("Scroll!");
        let element: HTMLElement = event.target as HTMLElement;
        if (
          element.scrollHeight - element.scrollTop - element.clientHeight <=
          50
        ) {
          // console.log("We are deep down, time to load more. " + this.allLoaded);
          if (!this.loading && !this.allLoaded) {
            this.page++;
            //            this.loadProducts();
          }
        }
      });
  }

  onSearch(searchTerm: string) {
    this.searchSubject.next(searchTerm);
  }

  ngOnDestroy() {
    this.searchSubject.complete();
    this.destroy$.next();
    this.destroy$.complete();
  }

  stringify(object: Object) {
    return JSON.stringify(object);
  }

  checkProductCollectionExists(product: any): boolean {
    return this.productCollections.some(
      (p: any) => p.collectionName === product.collectionName,
    );
  }

  checkForLargeViews() {
    setTimeout(
      () => {
        var x: any = document.getElementById('scrollContent')?.clientHeight;
        var y: any = document.getElementById('scrollContent')?.scrollHeight;
        // console.log("document has scroll: ", y > x)
        if (
          (y <= x &&
            !this.pageHasScrolled &&
            this.productCollections.length > 0 &&
            !this.allLoaded) ||
          (this.localStockFilterApplied && !this.allLoaded)
        ) {
          //Load more products as the user can't scroll yet
          this.page++;
          // this.loadProducts();
        } else {
          this.pageHasScrolled = true;
        }
      },
      this.localStockFilterApplied && !this.allLoaded ? 50 : 1000,
    ); // speed up the check when localStockFilterApplied
  }

  viewProduct(collection: ProductCollection) {
    console.log('collection', collection);
    //This is some dodgy work around to get the productCode from a collection
    const product = collection.products[0];
    let productCode = '';
    console.log('product in viewProduct()', product);
    if (product instanceof Product) {
      console.log('in viewProduct() ' + product + ' is an instanceof Product');
      productCode = product.productCode;
    } else {
      productCode = collection.collectionName;
    }

    if (this.isReplacingProduct() || this.isSelectingProduct()) {
      DatabaseHelper.instance.dataCallbacks.dataCallback({
        page: 'product-details',
        productCollection: collection,
      });
    } else {
      this.router.navigateByUrl('/product-details?productCode=' + productCode, {
        //state: { productCollection: collection },
      });
    }
  }

  filtersModal() {
    DatabaseHelper.instance.dataCallbacks.dataCallback2 = (filters: any) => {
      DatabaseHelper.instance.marketPlaceFilters = filters;
      //this.loadProducts(true);
    };

    this.dialog.open(FiltersComponent, {
      disableClose: true,
      height: '100vh',
      width: '100vh',
      maxWidth: '100vw',
      maxHeight: '-webkit-fill-available',
      position: { bottom: '0', left: '0' },
      enterAnimationDuration: '0',
      panelClass: ['profile-modal', 'grey-bg', 'no-scroll'],
      id: 'filters-modal',
    });
  }

  //  getFilteredProducts(productCollection: ProductCollection) {
  //    //NOTE This has been moved to be called at the initial loading and filtering of products so it only happens once
  //    // NOTE: This function is not used since it and most of this codebase does not fit our standards. It is deprecated.
  //    var products: any[] = [];
  //    var selectedStockFilter = '';
  //    var selectedStockFilterValue = '1';
  //    this.filters['Stock'].items.forEach((filter) => {
  //      if (filter.selected) {
  //        selectedStockFilter = filter.name;
  //        if (filter.inputField?.dataEntered) {
  //          selectedStockFilterValue = filter.inputField?.dataEntered;
  //        }
  //      }
  //    });
  //
  //    var variationProducts: any[] = [];
  //    console.log('in getFilteredProducts()');
  //    console.log('Product Collection = ' + productCollection.collectionName);
  //    console.log('Product Collection = ' + JSON.stringify(productCollection));
  //    //Always push the first product so we have a reference
  //    if (
  //      productCollection.products[0].variations &&
  //      Object.keys(productCollection.products[0].variations).length > 0
  //    ) {
  //      variationProducts.push(productCollection.products[0]);
  //      //Only push products if variations don't exist for them.
  //      for (let index = 1; index < productCollection.products.length; index++) {
  //        const product = productCollection.products[index];
  //        // if(product.variations && Object.keys(product.variations).length <= 0)
  //        // {
  //        variationProducts.push(product);
  //        // }
  //      }
  //    }
  //
  //    var productsToFilter: any =
  //      variationProducts.length > 0
  //        ? variationProducts
  //        : productCollection.products;
  //    if (
  //      productCollection.collectionName ===
  //      'Sika 70sqm, Two Coats, Single Colour Epoxy Flooring Package'
  //    ) {
  //      console.log('GOT P = ' + JSON.stringify(productsToFilter));
  //    }
  //    if (selectedStockFilter !== '') {
  //      // console.log("Filter selected = "+selectedStockFilter + ' ' + selectedStockFilterValue);
  //      productsToFilter.forEach((product: any) => {
  //        if (selectedStockFilter === 'Made to order') {
  //          if (product.stockLevel === 'Enquiry Only') {
  //            // console.log('Push made to order');
  //            products.push(product);
  //          }
  //        } else if (selectedStockFilter === 'In stock') {
  //          // console.log(product.productCode+" Stock = "+product.stockAvailable);
  //          // console.log("Filter value = "+selectedStockFilterValue);
  //          if (
  //            (+product.stockAvailable >= +selectedStockFilterValue ||
  //              productCollection.brand === 'Epoxy Warehouse' ||
  //              productCollection.brand === 'GH Commercial') &&
  //            product.stockLevel !== 'Enquiry Only'
  //          ) {
  //            // console.log('In stock = '+ product.stockLevel + product.productCode);
  //            products.push(product);
  //          }
  //        } else if (
  //          selectedStockFilter === 'Out of stock' &&
  //          product.stockLevel !== 'Enquiry Only'
  //        ) {
  //          if (
  //            +product.stockAvailable >= 0 &&
  //            productCollection.brand !== 'Epoxy Warehouse' &&
  //            productCollection.brand !== 'GH Commercial'
  //          ) {
  //            // console.log('Out of stock');
  //            products.push(product);
  //          }
  //        }
  //      });
  //      productCollection.products = products;
  //      return productCollection.products;
  //    } else {
  //      return productsToFilter;
  //    }
  //  }

  loadFilters() {
    if (this.filters['Colour'].items.length === 0) {
      DatabaseHelper.instance.getFilters().then((val) => {
        console.log('Got filters = ' + JSON.stringify(val));
        this.filters['Brands'].items = Object.keys(
          val.filters.brandOptions,
        ).map((e: string) => ({
          selected: false,
          name: e,
          count: 0,
          subCategories: val.filters.brandOptions[e].map((s: string) => ({
            name: s,
            selected: false,
            count: 0,
          })),
        }));
        this.filters['Colour'].items = val.filters.colourOptions.map(
          (e: string) => ({ selected: false, name: e }),
        );
        // this.filters['Product Type'].items = val.filters.productTypes.map((e: string) => ({ selected: false, name: e }));
        this.filters['Product Type'].items = Object.keys(
          val.filters.productTypes,
        ).map((e: string) => ({
          selected: false,
          name: e,
          count: 0,
          subCategories: val.filters.productTypes[e].map((s: string) => ({
            name: s,
            selected: false,
            count: 0,
          })),
        }));
      });
    }
  }

  getFilterHeight(item: any) {
    if (item.inputField && item.selected) {
      return 90;
    } else if (item.subCategories && item.selected) {
      return Object.keys(item.subCategories).length * 32 + 40;
    } else {
      return 40;
    }
  }

  getSubItemsHeight(items: any) {
    var count = Object.entries(items).length * 40;
    if (items) {
      Object.entries(items).forEach((item: any) => {
        if (item.inputField && item.selected) {
          count = count + 2 * 40;
        }
        if (item.selected && item.subCategories) {
          item.subCategories.forEach((subbrand: any) => {
            count = count + 1 * 32;
          });
        }
      });
    }
    return count;
  }

  filterIsSelected() {
    for (let key in this.filters) {
      for (let item in this.filters[key].items)
        if (this.filters[key].items[item].selected) {
          return true;
        }
    }
    return false;
  }

  clearAllFilter() {
    for (let key in this.filters) {
      if (key === 'Product View') return;
      for (let item in this.filters[key].items) {
        this.filters[key].items[item].selected = false;
        if (this.filters[key].items[item].subCategories) {
          for (let subBrand in this.filters[key].items[item].subCategories) {
            this.filters[key].items[item].subCategories[subBrand] = false;
          }
        }
      }
    }
    //this.loadProducts(true);
  }

  filterSelected(filter: string, item: any) {
    const newFilterState = !item.selected;
    AnalyticsHelper.instance.logEvent('filter_clicked', {
      selected: newFilterState,
      filter: filter,
    });
    this.processFilter(filter);
    item.selected = newFilterState;
    //this.loadProducts(true);
  }

  subbrandFilterSelected(filter: string, item: any, subbrand: any) {
    const newFilterState = !subbrand.selected;
    AnalyticsHelper.instance.logEvent('subbrand_filter_clicked', {
      selected: newFilterState,
      filter: filter,
      subbrand: subbrand.name,
    });
    var index = item.subCategories.findIndex(
      (foundSubbrand: any) => foundSubbrand.name === subbrand.name,
    );
    item.subCategories[index].selected = newFilterState;
    //this.loadProducts(true);
  }

  stockRequiredUpdated(filter: string, item: any) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      clearTimeout(this.timeout);
      if (!this.loading) {
        const newFilterState = true;
        AnalyticsHelper.instance.logEvent('filter_input_updated', {
          inputData: item.inputField.inputData,
          filter: filter,
        });
        item.selected = newFilterState;
        // this.loadProducts(true);
      }
    }, 500);
  }

  processFilter(filter: string) {
    if (this.filters[filter].singleSelectionOnly) {
      this.clearAllFiltersForGroup(filter);
    }
  }

  clearAllFiltersForGroup(group: string) {
    for (let item in this.filters[group].items)
      this.filters[group].items[item].selected = false;
  }

  keysForObject(object: any) {
    // console.log("Converting OBJECT = ",JSON.stringify(object));
    return Object.keys(object);
  }

  valuesForObject(object: any) {
    return Object.values(object);
  }

  getPrice(collection: ProductCollection) {
    return UtilityHelper.formatPrice(collection.retailPrice);
  }

  unitTypeToMeasure(unitType: string) {
    return UtilityHelper.unitTypeToMeasure(unitType);
  }

  getProductsFilteredWithStockAndSearchTerm() {
    let selectedStockFilter = '';
    let selectedStockFilterValue = '1';
    this.filters['Stock'].items.forEach((filter) => {
      if (filter.selected) {
        selectedStockFilter = filter.name;
        if (filter.inputField?.dataEntered) {
          selectedStockFilterValue = filter.inputField?.dataEntered;
        }
      }
    });
    return this.productCollections.filter((collection: ProductCollection) => {
      //   if ((collection.collectionName.toUpperCase().startsWith(this.searchTerm.toUpperCase()) || collection.collectionName.toUpperCase().includes(this.searchTerm.toUpperCase()))) {
      if (selectedStockFilter === 'In stock') {
        return collection.brand === 'Epoxy Warehouse' ||
          collection.brand !== 'GH Commercial'
          ? true
          : collection.maximumStockAvailable >= +selectedStockFilterValue;
      } else if (selectedStockFilter === 'Out of stock') {
        return collection.brand === 'Epoxy Warehouse' ||
          collection.brand !== 'GH Commercial'
          ? false
          : collection.maximumStockAvailable === 0;
      }
      // }
      return false;
    });
  }

  get isPhone() {
    return UtilityHelper.isPhone();
  }

  get filters() {
    var filters = DatabaseHelper.instance.marketPlaceFilters;
    Object.keys(filters).forEach((filterKey) => {
      if (
        filterKey === 'Stock' ||
        filterKey === 'Product View' ||
        filterKey === 'Industries'
      ) {
        filters[filterKey].items.forEach((item) => {
          item.count = -1;
        });
      } else {
        filters[filterKey].items.forEach((item) => {
          item.count = 0;
        });
      }
    });
    this.productCollections.forEach((productCollection: any) => {
      filters['Brands'].items.forEach((item) => {
        if (productCollection.brand === item.name) {
          item.count = item.count + 1;
        }
      });
      filters['Industries'].items.forEach((item) => {
        if (productCollection.industryOptions.includes[item.name]) {
          item.count = item.count + 1;
        }
      });
      filters['Shop by price'].items.forEach((item) => {
        if (productCollection.priceRangeOptions.includes(item.name)) {
          item.count = item.count + 1;
        }
      });
      filters['Product Type'].items.forEach((item) => {
        if (productCollection.category === item.name) {
          item.count = item.count + 1;
        }
      });
      filters['Colour'].items.forEach((item) => {
        if (productCollection.colourOptions.includes(item.name)) {
          item.count = item.count + 1;
        }
      });
    });
    // return filters;
    return DatabaseHelper.instance.marketPlaceFilters;
    // return this.productFilters;
  }

  get isClientView() {
    return DatabaseHelper.instance.isClientView();
  }
}
