import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EstimateJobItem } from 'src/app/classes/EstimateJobItem';
import { EstimationCart } from 'src/app/classes/EstimationCart';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';

@Component({
  selector: 'app-estimation-details',
  templateUrl: './estimation-details.component.html',
  styleUrls: ['./estimation-details.component.scss']
})
export class EstimationDetailsComponent implements OnInit {

  estimation: EstimationCart = new EstimationCart();
  activeTab = "Estimates";

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog) { }

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView("Estimation Details Modal");
    this.estimation = this.data.estimation;
  }

  isChoosingEstimation() {
    if (this.data != null) {
      return this.data.choosingEstimation;
    }
    else {
      return false;
    }
  }

  useThisEstimateButtonClicked() {
    console.log("Use this estimate button clicked");
    DatabaseHelper.instance.dataCallbacks.dataCallback({ page: 'selectedEstimations', estimation: this.estimation });
  }

  close() {
    this.dialog.getDialogById('estimation-details')?.addPanelClass("animate-out");
    setTimeout(() => {
      this.dialog.getDialogById('estimation-details')?.close();
    }, 500);
  }

  reportError() {
    window.open("mailto:team@flrhub.com.au?subject=Issue%20with%20estimation%20#" + this.estimation.estimationNumber);
  }

  downloadDocument(doc: any) {
    const url = doc.url ? doc.url : doc;
    const fileName = doc.name ? doc.name : doc;
    AnalyticsHelper.instance.logEvent("estimation_file_downloaded", { file_url: url, file_name: fileName });
    window.open(url, "_blank");
  }

  formatFilename(url: string) {
    const selectedDocHref = url as string
    if (selectedDocHref != '') {
      const clean1 = selectedDocHref.split('/').pop() as string
      const clean2 = clean1.split('#')[0].split('?')[0];
      const clean3 = clean2.replace('FloorPlansFiles%2F', '')
      const clean4 = clean3.indexOf('-')
      const clean5 = clean3.substring(clean4 + 1);
      return clean5;
    }
    return 'Uploaded file';
  }

  subProducts(jobItem: EstimateJobItem) {
    return jobItem.estimates.filter(e => e.description !== jobItem.code);
  }

  productEstimate(jobItem: EstimateJobItem) {
    return jobItem.estimates.filter(e => e.description === jobItem.code)[0];
  }

}
