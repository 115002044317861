import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/classes/User';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { AuthHelper } from 'src/app/helpers/AuthHelper';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';
import { UtilityHelper } from 'src/app/helpers/UtilityHelper';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-sign-up-details',
  templateUrl: './sign-up-details.component.html',
  styleUrls: ['./sign-up-details.component.scss']
})
export class SignUpDetailsComponent implements OnInit {

  password: string = '';
  showPassword: boolean = false;
  errorMessage: string[] = [];
  errors: any = {};
  startValidation: boolean = false;
  imageUploading = false;
  imageSizeError = false;
  loading = false;

  constructor(private authHelper: AuthHelper, public router: Router) {
  }

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView("Sign Up Details");
  }


  validate(): boolean {
    this.errors = {};
    if (!this.startValidation) return false;
    if (!this.user.firstName) {
      this.errors.firstName = true;
    }
    if (!this.user.lastName) {
      this.errors.lastName = true;
    }
    if (!UtilityHelper.isPhoneValid(this.user.mobileNumber)) {
      this.errors.mobileNumber = true;
    }
    if (!this.user.homeAddress) {
      this.errors.homeAddress = true;
    }
    /*if (!this.user.driversLicenceNumber) {
      this.errors.driversLicenceNumber = true;
    }*/
    return Object.keys(this.errors).length === 0;
  }


  handleAddressChange(address: any) {
    console.log('Address change');
    console.log('Address change = '+JSON.stringify(address));
    // this.userAddress = address.formatted_address
    // this.userLatitude = address.geometry.location.lat()
    // this.userLongitude = address.geometry.location.lng()
    var state = '';
    var suburb = '';
    address.address_components.forEach( (component : any) => {
      component.types.forEach( (type : any) => {
        if(type === 'administrative_area_level_1')
        {
          state = component.short_name;
        }
        if(type === 'locality')
        {
          suburb = component.short_name;
        }
      });
    });
    console.log('state = '+state);
    console.log('suburb = '+suburb);
    this.user.homeAddress = address.formatted_address;
  }

  async signup() {
    this.startValidation = true;
    this.loading = true;
    if (this.validate()) {
      console.log(this.user);
      //await this.user.save();
      AuthHelper.instance.updateUser(this.user).then(userResponse => {
        console.log('User Response:', userResponse);
        this.router.navigateByUrl('sign-up-company-details');
      }).catch((error) => {
        let errorMsg=error instanceof HttpErrorResponse? error.error.error:error.message;
        this.loading = false;
        this.errorMessage.push(errorMsg);//Something went wrong while creating your account, please try again shortly.')
      });
    } else {
      this.loading = false;
    }
  }

  uploadImage(event: any) {
    this.imageUploading = true;
    this.imageSizeError = false;

    var reader = new FileReader();

    //Read the contents of Image File.
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (e) => {

      //Initiate the JavaScript Image object.
      var image = new Image();

      //Set the Base64 string return from FileReader as source.
      image.src = e.target!.result!.toString();

      //Validate the File Height and Width.
      image.onload = () => {
        var height = image.height;
        var width = image.width;
        if (height < 300 || width < 300) {
          this.imageSizeError = true;
          this.imageUploading = false;
          return false;
        }
        const name = event.target.files[0].name;
        const lastDot = name.lastIndexOf('.');
        const ext = name.substring(lastDot + 1);

        const fileName = this.user.firstName + new Date().getTime() + "." + ext;

        DatabaseHelper.instance.uploadFile("CompanyLogos/" + fileName, event.target.files[0]).then(val => {
          this.user.companyLogoUrl = val;
          this.imageUploading = false;
        });
        return true;
      };
    };
  }

  goTo(url: string) {
    this.router.navigateByUrl(url);
  }

  get user() {
    return AuthHelper.instance.user;
  }

  back() {
    window.history.back();
  }

}
