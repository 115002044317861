<div class="header">
    <div class="header-title" [ngClass]="{'hide-phone': showBackButton}">{{title}}</div>
    <!-- <img class="logo show-phone" *ngIf="title === 'My Hub' && !showBackButton" src="../../../assets/images/logo.png"> -->
    <div class="small-button show-phone" *ngIf="showBackButton" (click)="back()">Back</div>
    <div class="spacer"></div>
    <!-- <div class="icon-button favourite-button"><img class="icon" src="../../../assets/images/favourite_icon.png"></div> -->
    <div class="secondary-button dark-button estimate-button hide-phone" gaEvent="leave_client_mode" gaCategory="click_event" (click)="leaveClientMode()" *ngIf="isClientView">
        Leave selections hub
    </div>
    <div class="icon-button cart-button" gaEvent="cart_button" gaCategory="click_event" (click)="cart()" [ngClass]="{filled: user.cart && user.cart.length > 0}"><img
            class="icon"
            [src]="'../../../assets/images/cart_' + (user.cart && user.cart.length > 0 ? 'filled' : 'empty') + '_icon.png'">
    </div>
    <!--<div class="secondary-button dark-button estimate-button hide-phone" gaEvent="get_estimation_button" gaCategory="click_event" (click)="getEstimation()" *ngIf="!isClientView"><img class="icon"
        src="../../../assets/images/ruler_icon.png">Get An Estimation</div>-->
    <div class="secondary-button user-button hide-phone" (click)="showMenu = !showMenu"
        [ngClass]="{loading: !user.firstName}" *ngIf="!isClientView">
        {{user.firstName}} {{user.lastName}}<img class="icon icon-end" src="../../../assets/images/down_arrow.png">
    </div>
    <div class="icon-button user-button show-phone" (click)="showMenu = !showMenu"
        [ngClass]="{loading: !user.firstName}" *ngIf="!isClientView"><img class="icon" src="../../../assets/images/avatar_icon.png">
    </div>
    <div class="small-button close-button" (click)="closeDialog()">Close</div>
</div>

<div *ngIf="showMenu" class="user-menu-container" gaEvent="user_menu_button" gaCategory="click_event" (click)="showMenu = false;">
    <div class="user-menu">
        <div class="user-menu-item" gaEvent="my_profile_button" gaCategory="click_event" (click)="profile()">My Profile</div>
        <div class="user-menu-item" gaEvent="my_account_button" gaCategory="click_event" (click)="settings()">My Account</div>
        <div class="user-menu-item" gaEvent="logout_button" gaCategory="click_event" (click)="logout()">Logout</div>
    </div>
</div>