<div class="header">
    <div class="spacer"></div>
    <img class="close-icon" src="../../../assets/images/close_icon.png" (click)="close()">
</div>

<div class="page-container">
    <div class="submitted-container" *ngIf="this.isBuilder && !acceptingQuote && jobQuote.won">
        <img class="order-submitted-image" src="../../../assets/images/order_submitted_icon.png">
        <div class="title">This quote was successfully accepted</div>
        <div class="description">We’ve notified {{jobQuote.installerName}}.</div>
    </div>
    <div class="submitted-container" *ngIf="this.user.uid == this.jobQuote.userId && isQuoteSubmitted && this.jobQuote.won">
        <img class="order-submitted-image" src="../../../assets/images/order_submitted_icon.png">
        <div class="title">Your quote was successfully {{jobQuote.won ? 'accepted' : 'submitted'}}</div>
        <div class="description" *ngIf="!jobQuote.won">We’ll let you know if your quote was chosen for the job.</div>
        <div class="description" *ngIf="jobQuote.won">Please get in touch with the builder.</div>
    </div>
    <div class="submitted-container" *ngIf="!this.jobQuote.won && this.user.uid == this.jobQuote.userId && !this.isBuilder && this.isQuoteSubmitted && this.jobQuote.job.postStatus === 'Closed'">
        <div class="title">Your quote was not accepted</div>
    </div>
    <div class="page-header">
        <div *ngIf="!isBuilder" class="page-header-tab" [ngClass]="{active: activeTab === 'My Quotes'}"
            (click)="activeTab = 'My Quotes'">
            My Quotes</div>
        <div *ngIf="!isBuilder" class="page-header-tab" [ngClass]="{active: activeTab === 'Job Details'}"
            (click)="activeTab = 'Job Details'">
            Job Details</div>
        <div *ngIf="!isBuilder" class="page-header-tab" [ngClass]="{active: activeTab === 'View Profile'}" (click)="activeTab = 'View Profile'">
          View Builder Profile
        </div>
        <div *ngIf="isBuilder" class="page-header-tab installer-name" [ngClass]="{active: activeTab === 'My Quotes'}" (click)="activeTab = 'My Quotes'">{{jobQuote.installerName}}'s Quote</div>
        <div *ngIf="isBuilder" class="page-header-tab" [ngClass]="{active: activeTab === 'View Profile'}" (click)="activeTab = 'View Profile'">
          View Installer Profile
        </div>
        <div class="spacer"></div>
        <div *ngIf="!isBuilder && !isQuoteSubmitted" class="primary-button regular-font hide-phone"
            [ngClass]="{loading: this.submittingQuote}" (click)="submitQuote()">Submit Quote</div>
        <div *ngIf="(isBuilder && !jobQuote.won && jobQuote.job.postStatus !== 'Closed') || this.acceptingQuote"
            class="primary-button regular-font hide-phone" [ngClass]="{loading: this.acceptingQuote}"
            (click)="acceptQuote()">Accept Quote</div>
        <div *ngIf="isBuilder && builderJob.winnerQuoteNumber && !jobQuote.won && jobQuote.job.postStatus == 'Closed'" class="hide-phone">You've already
            accepted another quote.</div>
    </div>
    <div class="content-container" *ngIf="activeTab === 'My Quotes'">
        <div class="estimations-title-container">
            <div class="estimation-title">{{jobQuote.job.jobName}}{{isPartialQuote ? ' (Partial Quote)' : ''}}</div>
            <div class="spacer"></div>
            <div class="total-header hide-phone">Total ex GST</div>
            <div class="total hide-phone">{{totalQuoteAmount}}</div>
        </div>
        <div class="scroll-content hide-scrollbar">
            <div class="product-container" *ngFor="let jobItem of jobQuote.products; let i = index; trackBy: trackByFn">
                <div class="estimation-header-tab">
                    <div class="products-header">Products</div>
                    <div class="total-header hide-phone">Total m<sup>2</sup>/lm</div>
                    <div class="total-header hide-phone">Per m<sup>2</sup>/lm ($)</div>
                    <div class="total-header hide-phone">Total ex GST</div>
                </div>
                <div class="product-row">
                    <div class="product-title-container">
                        <img class="product-image" *ngIf="!jobQuote.job.siteVisitRequired" [src]="jobItem.product.thumbnailImageUrl">
                        <div class="product-title" *ngIf="!jobQuote.job.siteVisitRequired">{{jobItem.product.productCollection+" - "+jobItem.product.productDesign}}{{jobItem.product.variations && jobItem.product.variations['Size'] ? ' - '+jobItem.product.variations['Size'].option : ''}}</div>
                        <div class="product-title product-container small" *ngIf="jobQuote.job.siteVisitRequired"><input [(ngModel)]="jobItem.product.productCollection"
                          [readOnly]="isBuilder || isQuoteSubmitted"
                          [ngModelOptions]="{standalone: true}" class="input-field"
                          placeholder="Type here..."></div>
                    </div>
                    <div class="total-container margin-right">
                        <div class="total-header show-phone">Total m<sup>2</sup>/lm</div>
                        <div class="total" *ngIf="!jobQuote.job.siteVisitRequired">{{jobItem.quantity}}</div>
                        <div class="total" *ngIf="jobQuote.job.siteVisitRequired"><input numbersOnly [(ngModel)]="jobItem.quantity"
                          [readOnly]="isBuilder || isQuoteSubmitted" [ngModelOptions]="{standalone: true}"
                          placeholder="Type here...">
                  </div>
                    </div>
                    <div class="total-container">
                        <div class="total-header show-phone">Per m<sup>2</sup>/lm ($)</div>
                        <div class="total"><input numbersOnly [(ngModel)]="jobItem.price"
                                [readOnly]="isBuilder || isQuoteSubmitted" [ngModelOptions]="{standalone: true}"
                                class="input-field" [ngClass]="{error: errors.price.includes(i)}"
                                [placeholder]="isBuilder || isQuoteSubmitted ? 'N/A' : 'Type here...'">
                        </div>
                    </div>
                    <div class="total-container">
                        <div class="total-header show-phone">Total ex GST</div>
                        <div class="total">{{jobItem.price ? utilityHelper.formatPrice(+jobItem.quantity *
                            +jobItem.price) : "$0.00"}}</div>
                    </div>
                </div>
                <div class="sub-product-container">
                    <div class="sub-product-row"
                        *ngFor="let item of jobItem.subItems; let j = index; trackBy: trackByFn">
                        <div class="side-bar"></div>
                        <div class="product-title"><input [(ngModel)]="item.name"
                                [readOnly]="isBuilder || !item.installerAdded || isQuoteSubmitted"
                                [ngModelOptions]="{standalone: true}" class="input-field"
                                [ngClass]="{error: errors.subItems[i] && errors.subItems[i].name.includes(j)}"
                                placeholder="Type here..."></div>
                        <div class="total-container margin-right">
                            <div class="total-header show-phone">Total m<sup>2</sup>/lm</div>
                            <div class="total"><input [(ngModel)]="item.quantity"
                                    [readOnly]="isBuilder || !item.installerAdded || isQuoteSubmitted"
                                    [ngModelOptions]="{standalone: true}" class="input-field"
                                    [ngClass]="{error: errors.subItems[i] && errors.subItems[i].quantity.includes(j)}"
                                    placeholder="Type here..."></div>
                        </div>
                        <div class="total-container">
                            <div class="total-header show-phone">Per m<sup>2</sup>/lm ($)</div>
                            <div class="total"><input numbersOnly [(ngModel)]="!isNumber(item.quantity) ? 'N/A' : item.price"
                                    [readOnly]="isBuilder || isQuoteSubmitted || !isNumber(item.quantity)" class="input-field"
                                    [ngClass]="{error: errors.subItems[i] && errors.subItems[i].price.includes(j)}"
                                    [ngModelOptions]="{standalone: true}" [placeholder]="!isNumber(item.quantity) || isBuilder || isQuoteSubmitted ? 'N/A' : 'Type here...'">
                            </div>
                        </div>
                        <div class="total-container">
                            <div class="total-header show-phone">Total ex GST</div>
                            <div *ngIf="!isNumber(item.quantity)" class="total"><input numbersOnly [(ngModel)]="item.price"
                              [readOnly]="isBuilder || isQuoteSubmitted" [ngModelOptions]="{standalone: true}"
                              class="input-field" [ngClass]="{error: errors.price.includes(i)}"
                              placeholder="Type here...">
                            </div>
                            <div *ngIf="isNumber(item.quantity)" class="total">{{item.price ? utilityHelper.formatPrice(+item.quantity * +item.price) :
                                "$0.00"}}</div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!isBuilder && !isQuoteSubmitted" class="add-container" (click)="addRow(i)">+ Add another row
                </div>
            </div>
            <div class="product-container" *ngIf="jobQuote.job.siteVisitRequired">
              <div *ngIf="!isBuilder && !isQuoteSubmitted" class="add-container" (click)="addProduct()">+ Add another product
              </div>
            </div>
            <div class="quote-total"><span class="label">Total ex GST</span><span
                    class="total">{{totalQuoteAmount}}</span></div>
            <div *ngIf="!isBuilder && !isQuoteSubmitted" class="primary-button regular-font show-phone submit-button"
                [ngClass]="{loading: this.submittingQuote}" (click)="submitQuote()">Submit Quote</div>
            <div *ngIf="(isBuilder && !jobQuote.won && jobQuote.job.postStatus !== 'Closed') || this.acceptingQuote"
                class="primary-button regular-font show-phone submit-button" [ngClass]="{loading: this.acceptingQuote}"
                (click)="acceptQuote()">Accept Quote</div>
            <div *ngIf="isBuilder && builderJob.winnerQuoteNumber && !jobQuote.won  && jobQuote.job.postStatus == 'Closed'" class="show-phone">You've already
                accepted another quote.</div>
        </div>
    </div>
    <div class="content-container job-details-container" *ngIf="activeTab === 'Job Details'">
        <div class="job-name-row">
            <div class="job-name">{{jobQuote.job.jobName}}</div>
        </div>
        <div class="job-date">Posted {{utilityHelper.formatDate(jobQuote.job.submittedDate)}}</div>
        <div class="job-description">{{jobQuote.job.jobDescription}}</div>
        <div class="item-container">
            <div class="item">
                <div class="title">Site Address</div>
                <div class="description">{{jobQuote.job.suburb}}, {{jobQuote.job.state}}</div>
            </div>
            <div class="item">
                <div class="title">Job Size</div>
                <div class="description">{{jobQuote.job.jobSize}}</div>
            </div>
            <div class="item">
                <div class="title">Material Type</div>
                <div *ngIf="jobQuote.job.materialType" class="description">{{jobQuote.job.materialType.join(", ")}}</div>
            </div>
        </div>
        <div class="item-container">
            <div class="item">
                <div class="title">Estimated Start Date</div>
                <div class="description">{{jobQuote.job.constructionPeriod}}</div>
            </div>
            <div class="item" [ngStyle]="{display: 'none'}">
                <div class="title">Working Days</div>
                <div class="description">{{jobQuote.job.workingDays}}</div>
            </div>
        </div>
        <div class="separator"></div>
        <div class="section-title">Materials Supplied</div>
        <div class="materials-container hide-scrollbar">
            <div class="cart-item" *ngFor="let jobItems of jobQuote.job.products; let i = index;">
                <img class="cart-item-image" [src]="jobItems.product.thumbnailImageUrl">
                <div class="cart-item-container">
                    <div class="item-name">{{jobItems.product.productCollection}}</div>
                    <p class="category" [innerHTML]="jobItems.product.brand + (jobItems.product.subBrand ? ' - '+jobItems.product.subBrand : '') +' | '+ (jobItems.product.productCategory) | safeHtml"></p>
                    <p class="design">{{jobItems.product.productDesign}}{{jobItems.product.variations && jobItems.product.variations['Size'] ? ' - '+jobItems.product.variations['Size'].option : ''}}</p>
                    <div class="total">{{jobItems.quantity}}m²</div>
                </div>
            </div>
        </div>
        <div class="separator"></div>
        <div class="section-title">Plans/Specifications</div>
        <div class="files-container">
            <div class="secondary-button small-button" *ngFor="let item of jobQuote.job.specificationDrawings"
                (click)="openUrl(item.url)"><img class="icon"
                    src="../../../assets/images/download_icon.png">{{item.name}}</div>
        </div>
        <div class="section-title">Uploaded Files (Example: Photos or Drawings)</div>
        <div class="files-container">
            <div class="secondary-button small-button" *ngFor="let item of jobQuote.job.otherDocuments"
                (click)="openUrl(item.url)"><img class="icon"
                    src="../../../assets/images/download_icon.png">{{item.name}}</div>
        </div>
        <div class="separator"></div>
        <div class="section-title">Scope of Works</div>
        <div class="numbered-list">
            <div class="numbered-list-item" *ngFor="let item of jobQuote.job.scopeOfWorks; let i = index;">
                <div class="number">{{i + 1}}</div>
                <div class="description">{{item}}</div>
            </div>
        </div>
        <div class="separator"></div>
        <div class="section-title">Special Conditions</div>
        <div class="numbered-list">
            <div class="numbered-list-item" *ngFor="let item of jobQuote.job.specialConditions; let i = index;">
                <div class="number">{{i + 1}}</div>
                <div class="description">{{item}}</div>
            </div>
        </div>
        <div *ngIf="jobQuote.job.siteVisitRequired">
          <div class="separator"></div>
          <h3 class="section-title">Site Visit Information</h3>
          <p class="description">
            {{
              jobQuote.job.siteVisitType === "Contact"
                ? "Contact builder to arrange a time"
                : "Only certain times allowed"
            }}
          </p>
          <p *ngIf="jobQuote.job.siteVisitInfo.length" class="description">
            {{ jobQuote.job.siteVisitInfo }}
          </p>
          <p *ngIf="jobQuote.job.siteContactName.length">
            {{ "Site contact name: "+jobQuote.job.siteContactName }}
          </p>
          <p *ngIf="jobQuote.job.siteContactNumber.length">
            {{ "Site contact number: "+jobQuote.job.siteContactNumber }}
          </p>
        </div>
    </div>
    <div class="content-container view-profile-container" *ngIf="activeTab === 'View Profile'">
      <div class="profile-loading" *ngIf="jobProfile === null"><p>Loading...</p></div>
      <!-- General info -->
      <div class="profile-content" *ngIf="jobProfile !== null">
        <div class="profile-company-header" *ngIf="jobProfile.companyLogoUrl">
          <img [src]="jobProfile.companyLogoUrl" alt="" class="profile-company-logo">
          <h2 class="profile-company-name" *ngIf="jobProfile.companyName">{{ jobProfile.companyName }}</h2>
        </div>
        <div *ngIf="jobProfile.companyLogoUrl || jobProfile.companyName" class="separator"></div>
        <div class="profile-about" *ngIf="jobProfile.about">
          <p class="profile-about-title">About</p>
          <p class="profile-about-content">{{ jobProfile.about }}</p>
        </div>
        <div class="separator"></div>
        <div class="profile-details fields">
          <!-- Personal details -->
          <div class="row">
            <div class="field">
              <p class="field-title">First Name</p>
              <p class="field-description">{{ jobProfile.firstName ? jobProfile.firstName : '-' }}</p>
            </div>
            <div class="field">
              <p class="field-title">Last Name</p>
              <p class="field-description">{{ jobProfile.lastName ? jobProfile.lastName : '-' }}</p>
            </div>
          </div>
          <div class="row">
            <div class="field">
              <p class="field-title">Mobile Number</p>
              <p class="field-description">{{ jobProfile.mobileNumber ? jobProfile.mobileNumber : '-' }}</p>
            </div>
          </div>
          <div class="separator"></div>
          <!-- Company details -->
          <div class="row">
            <div class="field">
              <p class="field-title">Company Name</p>
              <p class="field-description">{{ jobProfile.companyName ? jobProfile.companyName : '-' }}</p>
            </div>
            <div class="field">
              <p class="field-title">ABN</p>
              <p class="field-description">{{ jobProfile.abn ? jobProfile.abn : '-' }}</p>
            </div>
          </div>
          <div class="row">
            <div class="field">
              <p class="field-title">Company Phone Number</p>
              <p class="field-description">{{ jobProfile.companyPhone ? jobProfile.companyPhone : '-' }}</p>
            </div>
            <div class="field">
              <p class="field-title">Company Email</p>
              <p class="field-description">{{ jobProfile.companyEmail ? jobProfile.companyEmail : '-' }}</p>
            </div>
          </div>
          <div class="row">
            <div class="field">
              <p class="field-title">Company Address</p>
              <p class="field-description">{{ jobProfile.companyAddress ? jobProfile.companyAddress : '-' }}</p>
            </div>
          </div>
          <div class="row">
            <div class="field">
              <p class="field-title">Business Type</p>
              <p class="field-description">{{ jobProfile.companyBusinessType ? jobProfile.companyBusinessType : '-' }}</p>
            </div>
            <div class="field">
              <p class="field-title">Job Title</p>
              <p class="field-description">{{ jobProfile.companyJobTitle ? jobProfile.companyJobTitle : '-' }}</p>
            </div>
          </div>

        </div>

      </div>
    </div>
</div>