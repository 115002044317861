<div class="page-container">
    <!-- <app-menu active="My Hub"></app-menu> -->
    <div class="content-container">
        <app-header title="My Hub"></app-header>
        <!-- <app-my-hub-tabs active="Home"></app-my-hub-tabs> -->
        <div class="content" style="overflow-y: auto">
            <div class="inner-container">
                <div class="home-cards-container">
                    <!-- 1st sets card -->
                    <div *ngFor="let item of getCardItems(); let i = index" class="home-card"
                        [ngStyle]="{'background-image': 'linear-gradient(rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.7)), url(' + item.image + ')'}"
                        (click)="goTo(item.url)" [style.cursor]="'pointer'" [attr.gaEvent]="item.gaEvent"
                        [attr.gaCategory]="'click_event'">

                        <div class="corner-decoration top-left"></div>
                        <div class="corner-decoration bottom-right"></div>
                        <div class="card-header">
                            <div class="card-icon">
                                <img *ngIf="i === 0" src="../../../assets/images/homepage-icons/marketplace_icon.svg"
                                    alt="View Prices">
                                <img *ngIf="i === 1" src="../../../assets/images/homepage-icons/order_icon.svg"
                                    alt="Job Board">
                                <img *ngIf="i === 2" src="../../../assets/images/homepage-icons/job_icon.svg"
                                    alt="No Hidden Fees">
                            </div>
                            <div class="text-wrapper">
                                <p class="title">
                                    {{ i === 0 ? 'Marketplace' : (i === 1 ? 'My Orders' : 'My Jobs') }}</p>
                                <p class="description">
                                    {{ i === 0
                                        ? "Access Australia's leading flooring marketplace"
                                        : (i === 1
                                            ? 'Place and view your orders in one central location'
                                            : 'Get quality leads on our Job Hub') }}
                                </p>
                            </div>
                            <p class="arrow">>>>>>>></p>
                        </div>
                    </div>
                    <!-- 2nd sets card -->
                    <div *ngFor="let image of backgroundImages.slice(0, 2); let i = index" class="home-card"
                        [ngStyle]="{'background-image': 'linear-gradient(rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.7)), url(' + image + ')'}"
                        (click)="i === 0 ? goTo('/estimations') : null"[style.cursor]="i === 0 ? 'pointer' : 'default'"
                        [attr.gaEvent]="i === 0 ? 'estimation_card' : ''"
                        [attr.gaCategory]="i === 0 ? 'click_event' : ''">
                        <div class="corner-decoration top-left"></div>
                        <div class="corner-decoration bottom-right"></div>
                        <div class="card-header">
                            <div class="card-icon">
                                <img *ngIf="i === 0" src="../../../assets/images/homepage-icons/star_icon.svg"
                                    alt="Analytics">
                                <img *ngIf="i === 1" src="../../../assets/images/homepage-icons/community_icon.svg"
                                    alt="Support">
                            </div>
                            <div class="text-wrapper">
                                <p class="title">
                                    {{ i === 0 ? 'My Estimations' : 'Community Hub' }}</p>
                                <p class="description">
                                    {{ i === 0 ? 'Professional estimation services available on FLRHUB' : 'Coming soon...' }}
                                </p>
                            </div>
                            <p class="arrow">>>>>>>></p>
                        </div>
                    </div>
                    <!-- Q Card -->
                    <div class="home-card question-card">
                        <div class="corner-decoration top-left"></div>
                        <div class="corner-decoration bottom-right"></div>
                        <div class="card-header">
                            <div class="card-icon">
                                <img src="../../../assets/images/homepage-icons/Question_icon.svg" alt="Questions">
                            </div>
                            <div class="text-wrapper">
                                <p class="title">Any Questions?</p>
                                <ul class="question-links">
                                    <li><a href="mailto:team@flrhub.com.au">Contact Us</a></li>
                                    <li><a target="_blank" href="/terms">Terms & Conditions</a></li>
                                    <li><a target="_blank" href="/privacy">Privacy Policy</a></li>
                                    <li><a target="_blank" href="/returns">Returns Policy</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
