import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UtilityHelper } from 'src/app/helpers/UtilityHelper';
import { AuthHelper } from 'src/app/helpers/AuthHelper';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';
import { PaymentsHelper } from 'src/app/helpers/PaymentsHelper';
import { EstimateQuestionsHelper } from 'src/app/helpers/EstimateQuestionsHelper';

import { EstimationStepsProductsComponent } from 'src/app/modals/estimation-steps-products/estimation-steps-products.component';
import { ProductsComponent } from 'src/app/pages/products/products.component';
import { ProductDetailsComponent } from 'src/app/pages/product-details/product-details.component';
import { EstimationCart } from 'src/app/classes/EstimationCart';
import { AlertViewComponent } from '../alert-view/alert-view.component';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { EstimateJobItem } from 'src/app/classes/EstimateJobItem';

@Component({
  selector: 'app-estimation-steps',
  templateUrl: './estimation-steps.component.html',
  styleUrls: ['./estimation-steps.component.scss']
})
export class EstimationStepsComponent implements OnInit {

  estimationDocumentUploading = false
  loadingNextSteps = false
  errors: any = {};
  test: any;
  termsAccepted = false;

  paymentErrorMessage: string = "";
  paymentLoading = false;
  paymentStartValidation: boolean = false;

  cardName: string = '';
  cardNumber: string = '';
  cardExpiry: string = '';
  cardCvc: string = '';
  useNewCard = false;

  activeCode = "F1";

  constructor(public dialog: MatDialog, private router: Router) { }

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView("Estimation Steps Modal");
  }

  getQuestion(category: string): Array<any> {
    return EstimateQuestionsHelper.mapQuestionPerCategory(category)
  }

  checkJobName() {
    // check if job name has been entered
    const jobNameField = document.querySelector('input[name="project_name"]') as HTMLInputElement
    if (jobNameField?.value.trim() == '' || jobNameField?.value == null) {
      document.querySelector('.steps-context .head')?.classList.add('has-error')
      return false;
    } else {
      document.querySelector('.steps-context .head')?.classList.remove('has-error')
      return true;
    }
  }

  openProductSelector() {

    if (!this.checkJobName()) {
      return;
    } else {
      // save to database if different value
      const jobNameField = document.querySelector('input[name="project_name"]') as HTMLInputElement
      if (this.user.estimationCart.jobName != jobNameField.value.trim() && jobNameField.value.trim() != '') {
        this.user.estimationCart.jobName = jobNameField.value.trim()
        //this.user.save()
      }
      console.log(JSON.stringify(this.user.estimationCart));
    }

    let detailsDialog: MatDialogRef<ProductDetailsComponent, any>;
    let productsDialog: MatDialogRef<ProductsComponent, any>;
    productsDialog = this.dialog.open(ProductsComponent, {
      disableClose: true,
      height: this.isPhone ? '100vh' : 'calc(100vh - 35px)',
      width: this.isPhone ? '100vw' : 'calc(100vw - 62px)',
      maxWidth: '100vw',
      maxHeight: '-webkit-fill-available',
      position: { bottom: '0', left: this.isPhone ? '0' : '32px' },
      enterAnimationDuration: '0',
      panelClass: ['choose-product-modal', 'no-padding'],
      id: 'products-modal',
      data: {
        choosingProduct: true
      },
    });
    DatabaseHelper.instance.dataCallbacks.dataCallback = (navEvent: any) => {
      if (navEvent.page == 'product-details') {
        console.log('neil:',navEvent.productCollection);
        detailsDialog = this.dialog.open(ProductDetailsComponent, {
          disableClose: true,
          height: this.isPhone ? '100vh' : 'calc(100vh - 35px)',
          width: this.isPhone ? '100vw' : 'calc(100vw - 62px)',
          maxWidth: '100vw',
          maxHeight: '-webkit-fill-available',
          position: { bottom: '0', left: this.isPhone ? '0' : '32px' },
          panelClass: ['product-details-modal', 'no-padding'],
          id: 'product-details-modal',
          data: {
            productCollection: navEvent.productCollection,
            choosingProduct: true
          },
        });
      } else if (navEvent.page == 'dismissProductDetails') {
        detailsDialog.close();
      } else if (navEvent.page == 'dismissProducts') {
        if (detailsDialog) {
          detailsDialog.close();
        } else {
          productsDialog.close();
        }

      } else if (navEvent.page == 'selectingProduct') {

        let selectedProduct = navEvent.product;
        let jobItem = new EstimateJobItem();
        jobItem.product = selectedProduct;
        jobItem.questions = EstimateQuestionsHelper.mapQuestionPerCategory(selectedProduct);
        this.user.estimationCart.jobItems.push(jobItem);

        this.errors = {};
        this.user.save().then(val => {
          detailsDialog.close();
          productsDialog.close();
        })
      }
    }
  }

  deleteJobItem(index: number) {
    this.user.estimationCart.jobItems.splice(index, 1);
    //this.user.save();
  }

  scrollToTop() {
    console.log("scroll to top");
    document.getElementById("scrollContainerEstimationSteps")?.scrollTo({ top: 0 });
  }

  dynamicPlaceholder(index: number, total: number) {
    var alpha = ['E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    var iteration = 0
    var iterateIndex = 1
    var prepareArray = []
    for (let i = 0; i < total; i++) {

      if (i % 9 == 0) {
        iteration++
        iterateIndex = 1 // reset to 1
      }
      var at = alpha.at(iteration)
      prepareArray.push(at + '' + iterateIndex)
      iterateIndex++

    }

    return prepareArray[index]
  }

  continueUploadDocument() {
    if (!this.checkJobName()) {
      return;
    } else {
      // save to database if different value
      const jobNameField = document.querySelector('input[name="project_name"]') as HTMLInputElement
      if (this.user.estimationCart.jobName != jobNameField.value.trim() && jobNameField.value.trim() != '') {
        this.user.estimationCart.jobName = jobNameField.value.trim()
        //this.user.save()
      }
    }

    this.scrollToTop();

    const stepCurrent = document.getElementById('step-chose-materials') as HTMLElement
    stepCurrent.style.display = "none"

    const stepNext = document.getElementById('step-upload-document') as HTMLElement
    stepNext.style.display = "flex"

  }

  uploadFloorPlans(event: any, fileType: string) {
    // if (event.target.files.length != 0) {

    //   this.estimationDocumentUploading = true

    //   var reader = new FileReader();
    //   //Read the contents of Image File.
    //   reader.readAsDataURL(event.target.files[0]);
    //   reader.onload = (e) => {
    //     const name = event.target.files[0].name;
    //     const lastDot = name.lastIndexOf('.');
    //     const ext = name.substring(lastDot + 1);

    //     // prepare the real filename
    //     const extLength = (name.substring(lastDot + 1).length + 1) * -1
    //     const nameOnly = name.slice(0, extLength)
    //     const nameSanitize = nameOnly.replace(/[^a-zA-Z0-9]/g, '-');
    //     const nameSanitizeClean = nameSanitize.replace(/-+/g, '-')

    //     const fileName = this.user.firstName + this.user.lastName + new Date().getTime() + "-" + nameSanitizeClean + "." + ext;

    //     DatabaseHelper.instance.uploadFile("FloorPlansFiles/" + fileName, event.target.files[0]).then(val => {
    //       this.user.estimationCart.jobDocument = val;
    //       this.estimationDocumentUploading = false;
    //       const materialError = document.querySelector('.map-document .error.message') as HTMLElement;
    //       materialError.style.display = 'none';
    //     });

    //     // debug only
    //     //this.user.estimationCart.jobDocument = fileName;
    //     //this.estimationDocumentUploading = false;

    //     return true;
    //   };

    // }

    if (event.target.files[0] === undefined) {
      return
    }
    this.estimationDocumentUploading = true;
    const promises: any[] = [];
    Array.from(event.target.files).forEach((file: any) => {
      promises.push(new Promise((res: any, rej: any) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          const name = file.name;
          const lastDot = name.lastIndexOf('.');
          const ext = name.substring(lastDot + 1);
          // prepare the real filename
          const extLength = (name.substring(lastDot + 1).length + 1) * -1
          const nameOnly = name.slice(0, extLength)
          const nameSanitize = nameOnly.replace(/[^a-zA-Z0-9]/g, '-');
          const nameSanitizeClean = nameSanitize.replace(/-+/g, '-')

          const fileName = this.user.firstName + this.user.lastName + new Date().getTime() + "-" + nameSanitizeClean + "." + ext;

          DatabaseHelper.instance.uploadFile("FloorPlanFiles/" + fileName, file).then(val => {
            this.user.estimationCart.jobDocuments = !this.user.estimationCart.jobDocuments ? [] : this.user.estimationCart.jobDocuments;
            this.user.estimationCart.jobDocuments.push({ name: fileName, url: val });
            res(true);
          });
        };
      }));
    });
    Promise.all(promises).then(() => {
      this.estimationDocumentUploading = false;
      event.target.value = "";
    });

  }

  uploadLevelFloorPlan(event: any, question: any) {
    if (event.target.files.length != 0) {

      document.querySelectorAll('.error-required').forEach((el) => {
        var error = el as HTMLElement
        el.classList.remove('error-required')
      });

      this.estimationDocumentUploading = true

      var reader = new FileReader();
      //Read the contents of Image File.
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e) => {
        const name = event.target.files[0].name;
        const lastDot = name.lastIndexOf('.');
        const ext = name.substring(lastDot + 1);

        // prepare the real filename
        const extLength = (name.substring(lastDot + 1).length + 1) * -1
        const nameOnly = name.slice(0, extLength)
        const nameSanitize = nameOnly.replace(/[^a-zA-Z0-9]/g, '-');
        const nameSanitizeClean = nameSanitize.replace(/-+/g, '-')

        const fileName = this.user.firstName + this.user.lastName + new Date().getTime() + "-" + nameSanitizeClean + "." + ext;

        DatabaseHelper.instance.uploadFile("FloorPlansFiles/ExtraFiles/" + fileName, event.target.files[0]).then(val => {
          question.fileUrl = val;
          this.estimationDocumentUploading = false;
        });

        // debug only
        //this.user.estimationCart.jobDocument = fileName;
        //this.estimationDocumentUploading = false;

        return true;
      };
    }
  }

  continueAssignDocument(step: any) {

    const errorFound = []
    const submittedCode: string[] = []

    // hide error if any
    document.querySelectorAll('.error.message').forEach(el => {
      const errorField = el as HTMLElement
      errorField.style.display = "none"
    })

    // if any codes is empty
    document.querySelectorAll('.map-material .input input').forEach(el => {
      const codeField = el as HTMLInputElement
      const codeFieldValue = codeField.value.replace(/\s+/g, '').toUpperCase()
      codeField.value = codeFieldValue;

      submittedCode.push(codeFieldValue)
      if (codeFieldValue == '') {
        errorFound.push('code_empty')
      }
    })

    // check for unique value
    const uniqueOnly = submittedCode.filter((x, i, a) => a.indexOf(x) == i)
    if (uniqueOnly.length != submittedCode.length) {
      // found non unique value return
      errorFound.push('non_unique')
    }

    // check if file is attached
    if (!this.user.estimationCart.jobDocuments) {
      errorFound.push('no_floorplan')
    }

    // if error found bail and show message
    if (errorFound.length > 0) {
      if (errorFound.includes('code_empty') || errorFound.includes('non_unique')) {
        const materialError = document.querySelector('.map-material .error.message') as HTMLElement
        materialError.style.display = 'block'
      }
      if (errorFound.includes('no_floorplan')) {
        const materialError = document.querySelector('.map-document .error.message') as HTMLElement
        materialError.style.display = 'block'
      }

      // bail
      return
    }


    // prepare codes item to save if new one, once saved nGmodal will do the task
    document.querySelectorAll('.map-material .input input').forEach((el, i) => {
      const inputField = el as HTMLInputElement
      const inputFieldVal = inputField.value
      this.user.estimationCart.jobItems[i].code = inputFieldVal
    })

    this.loadingNextSteps = true

    //this.user.save().then(val => {
    // open next steps
    const stepCurrent = document.getElementById('step-upload-document') as HTMLElement
    stepCurrent.style.display = "none"

    const stepNext = document.getElementById('step-assign-code-document') as HTMLElement
    stepNext.style.display = "flex"

    this.scrollToTop();

    // update active steps
    this.checkCurrentSteps(step)
    if (step == 2) {
      this.setupScrollProductHighlight();
    }
    this.loadingNextSteps = false
    //});

  }

  setupScrollProductHighlight() {
    this.activeCode = this.user.estimationCart.jobItems[0].code;
    document.getElementById('scrollContainer')?.addEventListener('scroll', (event) => {
      for (let i = 0; i <= this.user.estimationCart.jobItems.length; i++) {
        const item = this.user.estimationCart.jobItems[i];
        const questionEl = document.querySelector('.question-items[data-code="' + item.code + '"]')!;
        const rect = questionEl.getBoundingClientRect();
        if (rect.height + rect.top > 0) {
          this.activeCode = item.code;
          break;
        }
      }
    });
  }

  scrollToProduct(code: string) {
    this.activeCode = code;
    const questionEl = document.querySelector('.question-items[data-code="' + code + '"]')!;
    questionEl.scrollIntoView({ behavior: 'smooth' });
  }

  formatFilename(url: string) {
    const selectedDocHref = url as string
    if (selectedDocHref != '') {
      const clean1 = selectedDocHref.split('/').pop() as string
      const clean2 = clean1.split('#')[0].split('?')[0];
      const clean3 = clean2.replace('FloorPlansFiles%2F', '')
      const clean4 = clean3.indexOf('-')
      const clean5 = clean3.substring(clean4 + 1);
      return clean5;
    }
    return 'Uploaded file';
  }

  inputCheckLogic($event: any, item: any, index: any) {

    // save field
    this.checkFieldChanges(item)

  }

  textareaCheckLogic($event: any, item: any, index: any) {

    // save field
    //console.log("Textarea Value Changed:", $event.target);
    console.log("Before Update - Job Item Additional Info:", this.user.estimationCart.jobItems[index]?.questions);

    // Update the value in the estimationCart
    //this.user.estimationCart.jobItems[index].questions[item.qindex].answer = $event.target.value;

    console.log("After Update - Job Item Additional Info:", this.user.estimationCart.jobItems[index]?.questions);
    
    this.checkFieldChanges(item)

  }

  booleanCheckLogic($event: any, item: any, $logic: any, index: any) {

    const checkbox = item;
    //console.log(checkbox)
    const checkboxQuestion = checkbox.closest('.question-item').querySelector('.question').innerText
    const checkboxVal = $event.toLowerCase()

    // save field
    this.checkFieldChanges(checkbox)

    return


  }

  booleanExtraCheckLogic($event: any, item: any, $logic: any, jobItemIndex: number, qindex: number) {

    const checkbox = item;
    //console.log(checkbox)
    const checkboxQuestion = checkbox.closest('.question-item').querySelector('.question').innerText
    const checkboxVal = $event.toLowerCase()

    // save field
    this.checkFieldChanges(checkbox)

    const matchedCheckbox = 'show_if_' + checkboxVal
    const extraField = checkbox.closest('.question-field.type-boolean').querySelector('.extra-field') as HTMLElement
    let extraQuestion = ''
    if (matchedCheckbox == $logic) {
      extraField.style.display = 'block'
      extraQuestion = (extraField.querySelector('input') as HTMLInputElement).value
    } else {
      extraField.style.display = 'none'
      extraQuestion = ''
    }

    // save the value
    let theRealBoolean = $event

    this.user.estimationCart.jobItems[jobItemIndex].questions[qindex].answer = theRealBoolean

  }

  radioExtraCheckLogic($event: any, item: any, $logic: any, jobItemIndex: number, qindex: number) {

    // save the value
    let theRealBoolean = $event

    this.user.estimationCart.jobItems[jobItemIndex].questions[qindex].answer = theRealBoolean

  }

  // helper extra field update value
  checkExtraFieldChanges($event: any, item: any, $logic: any, jobItemIndex: number, qindex: number) {

    const field = item;
    const fieldItem = field.closest('.extra-field')
    fieldItem.classList.remove('error-required')

    // get parent selected options
    // let theRealBoolean = field.closest('.question-field').querySelector('input[type="radio"]:checked')?.value
    let theRealBoolean = this.user.estimationCart.jobItems[jobItemIndex].questions[qindex].answer.split(', ')[0];
    let extraValue = ', ' + $event.trim()
    let booleanWithExtra = theRealBoolean + extraValue

    this.user.estimationCart.jobItems[jobItemIndex].questions[qindex].answer = booleanWithExtra

  }

  getBooleanExtra(answer: any, position: number) {

    if (answer?.includes(',')) {
      return answer.split(", ")[position];
      // if (position == 0) {
      //   return answer.slice(0, answer.indexOf(','));
      // } else if (position == 1) {
      //   return answer.slice(answer.indexOf(',') + 1);
      // } else {
      //   return ''
      // }
    } else {
      if (position == 0) {
        return answer
      }
      else {
        return "";
      }
    }
  }

  checkBooleanDisplay(answer: any) {
    if (answer?.includes(',')) {
      return {
        display: 'block'
      }
    } else {
      return {
        display: 'none'
      }
    }
  }

  showRadioExtra(i: number, qindex: number, question: any) {
    return this.user.estimationCart.jobItems[i].questions[qindex].answer && question.extra_field_condition.some((e: any) => this.user.estimationCart.jobItems[i].questions[qindex].answer.includes(e));
  }


  radioCheckLogic($event: any, item: any, $logic: any, index: any) {

    const checkbox = item;
    const checkboxQuestion = checkbox.closest('.question-item').querySelector('.question').innerText
    const checkboxVal = $event

    // save field
    this.checkFieldChanges(checkbox)

    // logic for show/hide other fields
    if ($logic == undefined) {
      return
    }

    const questionItem = checkbox.closest('.question-items') as HTMLElement
    questionItem.querySelectorAll('[data-condition-target="' + checkboxQuestion + '"]').forEach((el) => {
      const element = el as HTMLInputElement
      const elementLogic = element.getAttribute('data-condition-value')
      if (elementLogic == checkboxVal) {
        element.style.display = 'block'
      } else {
        element.style.display = 'none'
      }
    })

  }

  // helper update value
  checkFieldChanges($event: any) {

    const field = $event;
    const fieldItem = field.closest('.question-item')
    fieldItem.classList.remove('error-required')


    return;

  }


  continuePayment(step: any) {

    // reset all appended error required
    document.querySelectorAll('.error-required').forEach((el) => {
      var error = el as HTMLElement
      el.classList.remove('error-required')
    })

    // validation
    document.querySelectorAll('.question-item').forEach((el) => {
      const element = el as HTMLElement
      if (getComputedStyle(element).display != 'none') {
        const question = (element.querySelector('.question') as HTMLElement).innerText
        const fieldType = element.getAttribute('data-type')
        let value = ''
        if (fieldType == 'boolean_extra' && element.classList.contains('required')) {
          value = (element.querySelector('.question-field input[type="radio"]:checked') as HTMLInputElement)?.value
          if (value == undefined) {
            el.classList.add('error-required')
          } else if (value == 'Yes') {
            console.log(element.querySelector('.extra-field input[type="text"]'));
            value = (element.querySelector('.extra-field input[type="text"]') as HTMLInputElement)?.value;
            if (value == undefined || value.trim() == '') {
              element.querySelector('.extra-field')?.classList.add('error-required');
            }
          }
        } else if (fieldType == 'boolean_extra_with_file_upload' && element.classList.contains('required')) {
          value = (element.querySelector('.question-field input[type="radio"]:checked') as HTMLInputElement)?.value
          if (value == undefined) {
            el.classList.add('error-required')
          } else if (value == 'Yes') {
            value = (element.querySelector('.extra-field input[type="text"]') as HTMLInputElement)?.value;
            const jobItemIndex = +element.getAttribute('data-job-item-index')!;
            const qIndex = +element.getAttribute('data-question-index')!;
            const fileUrl = this.user.estimationCart.jobItems[jobItemIndex].questions[qIndex].fileUrl;
            if ((value == undefined || value.trim() == '') && (fileUrl === undefined || fileUrl === '')) {
              element.querySelector('.extra-field')?.classList.add('error-required');
            }
          }
        } else if (fieldType == 'boolean' && element.classList.contains('required')) {
          value = (element.querySelector('.question-field input[type="radio"]:checked') as HTMLInputElement)?.value
          if (value == undefined) {
            el.classList.add('error-required')
          }

          // boolean has another text field, check this too
          if (element.querySelector('.question-field .extra-field') != null) {
            let extraField = element.querySelector('.question-field .extra-field') as HTMLElement
            if (getComputedStyle(extraField).display == 'block' && extraField.classList.contains('required')) {
              value = (extraField.querySelector('input[type="text"]') as HTMLInputElement)?.value
              if (value == undefined || value.trim() == '') {
                extraField.classList.add('error-required')
              }
            }
          }

        } else if (fieldType == 'radio' && element.classList.contains('required')) {
          value = (element.querySelector('.question-field input[type="radio"]:checked') as HTMLInputElement)?.value
          if (value == undefined || value.trim() == '') {
            el.classList.add('error-required')
          }
        } else if (fieldType == 'radio_extra' && element.classList.contains('required')) {
          value = (element.querySelector('.question-field input[type="radio"]:checked') as HTMLInputElement)?.value
          if (value == undefined) {
            el.classList.add('error-required')
          } else {
            if (element.querySelector('.extra-field input[type="text"]')) {
              value = (element.querySelector('.extra-field input[type="text"]') as HTMLInputElement)?.value;
              if (value == undefined || value.trim() == '') {
                element.querySelector('.extra-field')?.classList.add('error-required');
              }
            }
          }
        } else if (fieldType == 'text' && element.classList.contains('required')) {
          value = (element.querySelector('.question-field input[type="text"]') as HTMLInputElement)?.value
          if (value == undefined || value.trim() == '') {
            el.classList.add('error-required')
          }
        } else if (fieldType == 'textarea' && element.classList.contains('required')) {
          value = (element.querySelector('.question-field textarea') as HTMLInputElement)?.value
          if (value == undefined) {
            el.classList.add('error-required')
          }
        }
      }
    });

    // check if error visible
    if (document.querySelectorAll('.error-required').length > 0) {
      return;
    }

    this.loadingNextSteps = true

    //this.user.save().then(val => {

    this.scrollToTop();

    const stepCurrent = document.getElementById('step-assign-code-document') as HTMLElement
    stepCurrent.style.display = "none"

    const stepNext = document.getElementById('step-payment') as HTMLElement
    stepNext.style.display = "flex"

    // update active steps
    this.checkCurrentSteps(step)

    this.loadingNextSteps = false

    //})




  }

  backToChooseMaterials() {
    const stepCurrent = document.getElementById('step-upload-document') as HTMLElement
    stepCurrent.style.display = "none"
    const stepPrevious = document.getElementById('step-chose-materials') as HTMLElement
    stepPrevious.style.display = "flex"
  }

  backToUploadDocument(step: any) {
    const stepCurrent = document.getElementById('step-assign-code-document') as HTMLElement
    stepCurrent.style.display = "none"
    const stepPrevious = document.getElementById('step-upload-document') as HTMLElement
    stepPrevious.style.display = "flex"

    // update active steps
    this.checkCurrentSteps(step)
  }

  backToAssignCode(step: any) {
    const stepCurrent = document.getElementById('step-payment') as HTMLElement
    stepCurrent.style.display = "none"
    const stepPrevious = document.getElementById('step-assign-code-document') as HTMLElement
    stepPrevious.style.display = "flex"

    // update active steps
    this.checkCurrentSteps(step)
  }

  validateCard(): boolean {
    this.errors = {};
    if (!this.paymentStartValidation) return false;
    if (!this.cardName) {
      this.errors.cardName = true;
    }
    if (!this.cardNumber) {
      this.errors.cardNumber = true;
    }
    if (!this.cardExpiry || this.cardExpiry.length < 5) {
      this.errors.cardExpiry = true;
    }
    if (!this.cardCvc) {
      this.errors.cardCvc = true;
    }
    return Object.keys(this.errors).length === 0;
  }

  submitEstimation(){
    this.paymentErrorMessage = '';
    this.paymentStartValidation = true;
    console.log("user = "+JSON.stringify(this.user));
    console.log("Estimation data = " + JSON.stringify(this.user.estimationCart));
    DatabaseHelper.instance.submitEstimation(this.user.estimationCart).then(val3 => {
                 this.user.estimationCart = new EstimationCart();
                 //this.user.save();
                 this.cancel(); // Close dialog
                 this.router.navigateByUrl("/estimation-submitted");
               });
  }
  async submitEstimationOrder() {
    this.paymentErrorMessage = '';
    this.paymentStartValidation = true;
    if (!this.validateCard()) {
      return;
    }
    if (!this.termsAccepted) {
      this.errors.termsAccepted = true;
      return;
    }

    this.paymentLoading = true
    console.log("Card Creation #0");
    // chargeCardWithoutToken

    const tokenData = await PaymentsHelper.instance.createCardToken(this.user.uid + "_Estimate", this.cardNumber, this.cardCvc, this.cardExpiry);
    console.log('generated token data', JSON.stringify(tokenData));
    console.log('user estimation data', JSON.stringify(this.user.estimationCart));

    PaymentsHelper.instance.chargeCardWithToken(this.user.uid + "_Estimate", this.user.estimationCart, "FLRHUB Estimation Services", 'token', this.user.simplifyCustomerId, tokenData).then((val: any) => {
      console.log("Card Creation #2 = " + JSON.stringify(val));
      if (val.error) {

        console.log("Card Creation #8");

      }

      this.cancel(); // Close dialog
      this.router.navigateByUrl("/estimation-submitted");

    }).catch(err => {
      console.log('error is ', err);
      this.dialog.open(AlertViewComponent, {
        disableClose: false,
        maxWidth: "400px",
        panelClass: ['alert-modal', 'no-padding'],
        id: 'alert-modal',
        data: {
          title: err.error.message,
          message: "Error:" + err.error.error,
          primaryButton: "OK",
        }
      });
      this.paymentLoading = false;
    });  // -----Neil here*/ 

    // PaymentsHelper.instance.createCardToken(this.cardName, this.cardNumber, this.cardCvc, this.cardExpiry).then(val => {
    //   console.log("Card Creation #2 = "+JSON.stringify(val));
    //   if (val.error) {
    //     val.error.fieldErrors.forEach((e: any) => {
    //       if (e.field === "card.name") {
    //         console.log("Card Creation #3");
    //         this.errors.name = true;
    //       } else if (e.field === "card.number") {
    //         console.log("Card Creation #4");
    //         this.errors.cardNumber = true;
    //       } else if (e.field === "card.expMonth") {
    //         console.log("Card Creation #5");
    //         this.errors.expiry = true;
    //       } else if (e.field === "card.expYear") {
    //         console.log("Card Creation #6");
    //         this.errors.expiry = true;
    //       } else if (e.field === "card.cvc") {
    //         console.log("Card Creation #7");
    //         this.errors.cvc = true;
    //       }
    //       else
    //       {
    //         console.log("Card Creation #8");
    //         this.dialog.open(AlertViewComponent, {
    //           disableClose: false,
    //           maxWidth: "400px",
    //           panelClass: ['alert-modal', 'no-padding'],
    //           id: 'alert-modal',
    //           data: {
    //             title: "We could not process the payment.",
    //             message: "Error:"+JSON.stringify(e),
    //             primaryButton: "OK",
    //           }
    //         });
    //       }
    //     });
    //     this.paymentLoading = false;
    //   } else {
    //     // payment success, create estimate order record
    //     console.log("Card Carge #1");
    //     PaymentsHelper.instance.chargeCard(this.user.uid + "_Estimate", this.user.estimationCart.jobPrice, "FLRHUB Estimation Services", val.id, this.user.simplifyCustomerId).then(val2 => {
    //       console.log("Card Carge #2 = "+JSON.stringify(val2));
    //       if (val2.error) {
    //         this.dialog.open(AlertViewComponent, {
    //           disableClose: false,
    //           maxWidth: "400px",
    //           panelClass: ['alert-modal', 'no-padding'],
    //           id: 'alert-modal',
    //           data: {
    //             title: "We could not process the payment.",
    //             message: "Please check your card details and try again.",
    //             primaryButton: "OK",
    //           }
    //         });
    //         this.paymentLoading = false;
    //       } else {
    //         this.user.estimationCart.userId = this.user.uid;
    //         this.user.estimationCart.estimationStatus = "PENDING";
    //         this.user.estimationCart.estimationDate = new Date().getTime().toString();
    //         this.user.estimationCart.cardPaymentData = val2;

    //         //Setting up estimator reference details
    //         var length = 32;
    //         var wishlist = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    //         var password = Array.from(crypto.getRandomValues(new Uint32Array(length))).map((x) => wishlist[x % wishlist.length]).join('');
    //         this.user.estimationCart.timestamp = "" + Date.now();
    //         this.user.estimationCart.code = password;

    //         DatabaseHelper.instance.submitEstimation(this.user.estimationCart).then(val3 => {
    //           this.user.estimationCart = new EstimationCart();
    //           this.user.save();
    //           this.cancel(); // Close dialog
    //           this.router.navigateByUrl("/estimation-submitted");
    //         });
    //       }
    //     })
    //   }
    // }).catch(err => {
    //   this.paymentLoading = false;
    // });
  }

  checkCurrentSteps(step: string) {
    const activeStep = parseFloat(step) as number
    const totalSteps = 3
    for (let i = 1; i <= totalSteps; i++) {
      if (i == activeStep) {
        const current = document.querySelector('.steps-counter ul li[data-index="' + i + '"]') as HTMLElement
        current.classList.add('active')
        current.classList.remove('passed')
      } else if (i < activeStep) {
        const prev = document.querySelector('.steps-counter ul li[data-index="' + i + '"]') as HTMLElement
        prev.classList.add('passed')
      } else if (i > activeStep) {
        const next = document.querySelector('.steps-counter ul li[data-index="' + i + '"]') as HTMLElement
        next.classList.remove('active')
        next.classList.remove('passed')
      }
    }
  }

  cancel() {
    this.dialog.getDialogById('estimation-steps')?.addPanelClass("animate-out");
    setTimeout(() => {
      this.dialog.getDialogById('estimation-steps')?.close();
    }, 500);
  }

  openUrl(url: string) {
    window.open(url, "_blank");
  }

  deleteFloorPlans(url: string) {
    this.estimationDocumentUploading = true;
    DatabaseHelper.instance.deleteFile(url).then(() => {
      this.user.estimationCart.jobDocuments.splice(this.user.estimationCart.jobDocuments.findIndex(e => e.url === url), 1);
      this.estimationDocumentUploading = false;
    }).catch(() => {
      this.user.estimationCart.jobDocuments.splice(this.user.estimationCart.jobDocuments.findIndex(e => e.url === url), 1);
      this.estimationDocumentUploading = false;
    });
  }


  get user() {
    return AuthHelper.instance.user;
  }

  get isPhone() {
    return UtilityHelper.isPhone();
  }

}
