import { Component, Input, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthHelper } from 'src/app/helpers/AuthHelper';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  @Input() active = '';
  isHomePage: boolean = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.isHomePage = this.router.url === '/home';
    console.log('Initial check isHomePage:', this.isHomePage);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isHomePage = event.url === '/home';
        console.log('Route changed, isHomePage:', this.isHomePage, event.url);
      }
    });
  }

  get user() {
    return AuthHelper.instance.user;
  }

  goTo(url: string) {
    this.router.navigateByUrl(url);
  }
}
