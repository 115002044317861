import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { environment } from 'src/environments/environment';
import { EstimationCart } from '../classes/EstimationCart';
import { Job } from '../classes/Job';
import { JobQuote } from '../classes/JobQuote';
import { Order } from '../classes/Order';
import { Product } from '../classes/Product';
import { User } from '../classes/User';
import { AuthHelper } from './AuthHelper';
import { HttpService } from 'src/app/http.service';

@Injectable()
/**
 * This is where all the endpoint calls are made.
 * The general state of the application is stored here during runtime.
 */
export class DatabaseHelper {
  private static _instance: DatabaseHelper;

  private clientView = false;

  dataCallbacks: any = {};
  marketPlaceFilters: {
    [name: string]: {
      items: {
        count: number;
        subCategories?: any;
        name: string;
        selected: boolean;
        inputField?: {
          dataEntered: string;
          title: string;
          placeholder: string;
        };
      }[];
      expanded: boolean;
      singleSelectionOnly: boolean;
    };
  } = {
      Industries: {
        items: [
          { selected: false, name: 'Commercial', count: 0 },
          { selected: false, name: 'Residential', count: 0 },
          { selected: false, name: 'Industrial', count: 0 },
        ],
        expanded: false,
        singleSelectionOnly: false,
      },
      Brands: {
        items: [
          { selected: false, name: 'Epoxy Warehouse', count: 0 },
          { selected: false, name: 'GH Commercial', count: 0 },
          { selected: false, name: 'Interface', count: 0 },
          { selected: false, name: 'Pentarch', count: 0 },
          { selected: false, name: 'Tarkett', count: 0 },
        ],
        expanded: false,
        singleSelectionOnly: false,
      },
      'Shop by price': {
        items: [
          { selected: false, name: '$', count: 0 },
          { selected: false, name: '$$', count: 0 },
          { selected: false, name: '$$$', count: 0 },
        ],
        expanded: false,
        singleSelectionOnly: false,
      },
      // "Clearance": { items: [{ selected: false, name: "Commercial" }, { selected: false, name: "Residential" }, { selected: false, name: "Industrial" }], expanded: false, singleSelectionOnly: false },
      'Product Type': { items: [], expanded: false, singleSelectionOnly: false },
      Stock: {
        items: [
          {
            selected: false,
            name: 'In stock',
            count: -1,
            inputField: {
              dataEntered: '',
              title: 'minimum stock',
              placeholder: 'minimum stock',
            },
          },
          { selected: false, name: 'Out of stock', count: -1 },
          { selected: false, name: 'Made to order', count: -1 },
        ],
        expanded: false,
        singleSelectionOnly: true,
      },
      Colour: { items: [], expanded: false, singleSelectionOnly: false },
      'Product View': {
        items: [{ selected: false, name: 'Selections Hub', count: -1 }],
        expanded: false,
        singleSelectionOnly: true,
      },
    };

  jobBoardFilters: {
    [name: string]: {
      items: {
        name: string;
        selected: boolean;
        inputField?: {
          dataEntered: string;
          title: string;
          placeholder: string;
        };
      }[];
      expanded: boolean;
      singleSelectionOnly: boolean;
    };
  } = {
      Industries: { items: [], expanded: false, singleSelectionOnly: false },
      'Material Type': { items: [], expanded: false, singleSelectionOnly: false },
      Location: { items: [], expanded: false, singleSelectionOnly: false },
      'Job Size': { items: [], expanded: false, singleSelectionOnly: false },
      Urgency: { items: [], expanded: false, singleSelectionOnly: false },
    };
  settings: any = {};

  cache: any = {};

  constructor(
    private storage: AngularFireStorage,
    private httpService: HttpService,
    private functions: AngularFireFunctions,
  ) {
    DatabaseHelper._instance = this;
    this.getSettings().then((val) => (this.settings = val));
  }

  static get instance() {
    return this._instance;
  }

  /**
   * Main function to make calls to endpoints
   * @param endpoint Endpoint to make the call to
   * @param requestType Type of request GET | POST
   * @param body Parameters to pass to the request
   */
  public async performWebRequest(
    endpoint: string,
    requestType: 'GET' | 'POST',
    body: any,
  ): Promise<any> {
    //var url: string = environment.baseAPIUrl +  endpoint;
    //console.log('Web Service Request: ' + url);
    console.log('Web Service Body: ' + JSON.stringify(body));

    //For HTTP functions
    return this.httpService
      .request(requestType, '/' + endpoint, {
        headers: new HttpHeaders({
          //'Content-Type': 'application/json; charset=utf-8',
          'Cache-Control': 'no-cache',
          enctype: body instanceof FormData ? 'multipart/form-data' : '',
        }),
        body,
      })
      .toPromise();

    // For CALLABLE functions
    // return this.functions.httpsCallable(endpoint)(body).toPromise().then(data => {
    //     console.log('Web Service Response for ' + endpoint);
    //     console.log(data);
    //     return data;
    // });
  }

  public async performCachedRequest(
    endpoint: string,
    requestType: 'GET' | 'POST',
    body: any,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      if (Object.keys(this.cache).includes(endpoint)) {
        resolve(this.cache[endpoint]);
      } else {
        this.performWebRequest(endpoint, requestType, body).then((val: any) => {
          this.cache[endpoint] = val;
          resolve(this.cache[endpoint]);
        });
      }
    });
  }

  public uploadFile(path: string, file: File) {
    console.log('file: ', file);
    console.log('path: ', path);
    const formData: FormData = new FormData();
    formData.append('file', file);

    const headers = new HttpHeaders({ enctype: 'multipart/form-data' });
    return new Promise<string>((res, rej) => {
      this.performWebRequest('temp-file-upload', 'POST', formData).then(
        (val) => {
          res(val.url);
        },
      );
      // this.storage.upload(path, file).then((val) => {
      //   val.ref.getDownloadURL().then((url) => {
      //     res(url);
      //   });
      // });
    });
  }

  public deleteFile(url: string) {
    return this.storage.refFromURL(url).delete().toPromise();
  }

  public createUser(user: User, password: string): Promise<any> {
    return this.performWebRequest('register', 'POST', {
      user: user.toJson(),
      password: password,
    });
  }

  public sendAccountUnderReviewEmail(user: User) {
    return this.performWebRequest('sendAccountUnderReviewEmail', 'POST', {
      user: user.toJson(),
    });
  }

  public sendAccountDisabledEmail(user: User) {
    return this.performWebRequest('sendAccountDisabledEmail', 'POST', {
      user: user.toJson(),
    });
  }

  public updateUser(user: User, updateEmail: boolean): Promise<any> {
    const data: any = user.toJson();
    data.updateEmail = updateEmail;
    console.log(data);
    return this.performWebRequest('setUser', 'POST', data);
  }

  public deleteUser(id: string): Promise<any> {
    return this.performWebRequest('deleteUser', 'POST', { uid: id });
  }

  public getUser(id: string): Promise<any> {
    console.log('attempting to call getUser() with uid: ', id);
    return this.performWebRequest('getUser', 'POST', { uid: id });
  }

  public getSettings(): Promise<any> {
    return this.performWebRequest('getSettings', 'POST', {});
  }

  public getUsers(): Promise<any> {
    return this.performWebRequest('getUsers', 'POST', {});
  }

  public getProduct(productCode: string) {
    return this.performWebRequest('getProduct', 'POST', {
      productCode: productCode,
    });
  }

  public listProducts(perPage: number = 10, page: string = '') {
    return this.performWebRequest('listProducts', 'POST', {
      per_page: perPage,
      page: page,
    });
  }

  // public listProductCollections(perPage: number = 10, page: string = '', filters: any, stock?: number, maximumStock?: number) {
  public listProductCollections() {
    console.log('about to call!');
    // return this.performWebRequest('listProductCollections', 'POST', { per_page: perPage, page: page, filters: filters, stock: stock, maximumStock : maximumStock});
    return this.performCachedRequest('listProductCollections', 'POST', {
      per_page: 1,
    });
  }

  public getProductCollection(collectionId: string) {
    return this.performWebRequest('getProductCollection', 'POST', {
      collectionId: collectionId,
    });
  }

  public searchProducts(
    perPage: number = 10,
    page: string = '',
    searchTerm: string = '',
  ) {
    return this.performWebRequest('searchProducts', 'POST', {
      per_page: perPage,
      page: page,
      searchTerm: searchTerm,
    });
  }

  public searchProductCollections(
    perPage: number = 10,
    page: string = '',
    searchTerm: string = '',
    filters: any,
  ) {
    return this.performWebRequest('searchProductCollections', 'POST', {
      per_page: perPage,
      page: page,
      searchTerm: searchTerm,
      filters: filters,
    });
  }

  public updateProduct(product: Product): Promise<any> {
    return this.performWebRequest('updateProduct', 'POST', product);
  }

  public submitOrder(order: Order): Promise<any> {
    return this.performWebRequest('submitOrder', 'POST', {
      order: order,
      user: AuthHelper.instance.user.toJson(),
    });
  }

  public submitUpdatedOrder(order: Order): Promise<any> {
    return this.performWebRequest('submitOrder', 'POST', {
      order: order,
      user: AuthHelper.instance.user.toJson(),
      updated: true,
    });
  }

  public processOrderWithPayment(order: Order): Promise<any> {
    return this.performWebRequest('processOrderWithPayment', 'POST', {
      order: order,
      user: AuthHelper.instance.user.toJson(),
    });
  }

  public processOrderWithoutPayment(order: Order): Promise<any> {
    return this.performWebRequest('processOrderWithoutPayment', 'POST', {
      order: order,
      user: AuthHelper.instance.user.toJson(),
    });
  }

  public getUserOrders(
    uid: string,
    perPage: number = 10,
    page: string = '',
  ): Promise<any> {
    return this.performWebRequest('getUserOrders', 'POST', {
      userId: uid,
      per_page: perPage,
      page: page,
    });
  }

  public getUserEstimations(
    uid: string,
    perPage: number = 10,
    page: number = 1,
    onlyPending: boolean = false,
  ): Promise<any> {
    return this.performWebRequest('getUserEstimations', 'POST', {
      userId: uid,
      per_page: perPage,
      page: page,
      onlyPending: onlyPending,
    });
  }

  public getUserCompletedEstimations(uid: string): Promise<any> {
    return this.performWebRequest('getUserCompletedEstimations', 'POST', {
      userId: uid,
    });
  }

  public getFilters(): Promise<any> {
    return this.performCachedRequest('getFilters', 'POST', {});
  }

  // Checks if the coupon is valid for the user
  public applyCouponToOrder(user: User, code: string) {
    return this.performCachedRequest('applyCouponToOrder', 'POST', {
      user: user,
      code: code,
    });
  }

  // Marks the coupon as redeemed
  public redeemCoupon(user: User, code: string, orderNumber: string) {
    return this.performCachedRequest('redeemCoupon', 'POST', {
      user: user,
      code: code,
      order: { orderNumber: orderNumber },
    });
  }

  public applyCouponToSubscription(code: string) {
    return this.performCachedRequest('applyCouponToSubscription', 'POST', {
      code: code,
    });
  }

  public submitEstimation(estimation: EstimationCart): Promise<any> {
    return this.performWebRequest('submitEstimation', 'POST', {
      estimation: estimation,
      user: AuthHelper.instance.user.toJson(),
    });
  }

  public getJobBoardFilters(): Promise<any> {
    return this.performWebRequest('getJobBoardFilters', 'POST', {});
  }

  public getUserJobPosts(
    uid: string,
    perPage: number = 10,
    page: string = '',
  ): Promise<any> {
    console.log('getUserJobPosts called with uid: ', uid);
    return this.performWebRequest('getUserJobPosts', 'POST', {
      userId: uid,
      per_page: perPage,
      page: page,
    });
  }

  public getUserJobPost(jobPostNumber: string): Promise<any> {
    return this.performWebRequest('getUserJobPost', 'POST', {
      jobPostNumber: jobPostNumber,
    });
  }

  public getUserJobQuotes(
    uid: string,
    perPage: number = 10,
    page: string = '',
  ): Promise<any> {
    return this.performWebRequest('getUserJobQuotes', 'POST', {
      userId: uid,
      per_page: perPage,
      page: page,
    });
  }

  public listJobPosts(
    perPage: number = 10,
    page: string = '',
    filters: any,
  ): Promise<any> {
    return this.performWebRequest('listJobPosts', 'POST', {
      per_page: perPage,
      page: page,
      filters: filters,
    });
  }

  public submitJobPost(
    jobPost: Job,
    updated = false,
    submittingDraft = false,
  ): Promise<any> {
    return this.performWebRequest('submitJobPost', 'POST', {
      jobPost: jobPost,
      user: AuthHelper.instance.user.toJson(),
      updated: updated,
      submittingDraft: submittingDraft,
    });
  }

  public notifyInstallersOnNewJobPost(): Promise<any> {
    return this.performWebRequest('notifyInstallersOnNewJobPost', 'POST', {});
  }

  public submitJobQuote(jobQuote: JobQuote, updated = false): Promise<any> {
    return this.performWebRequest('submitJobQuote', 'POST', {
      jobQuote: jobQuote,
      updated: updated,
      user: AuthHelper.instance.user.toJson(),
    });
  }

  public acceptJobQuote(jobQuote: JobQuote, postStatus: String): Promise<any> {
    return this.performWebRequest('acceptJobQuote', 'POST', {
      jobQuote: jobQuote,
      postStatus: postStatus,
      user: AuthHelper.instance.user.toJson(),
    });
  }

  public getQuotesForJob(jobPostNumber: string): Promise<any> {
    return this.performWebRequest('getQuotesForJob', 'POST', {
      jobPostNumber: jobPostNumber,
    });
  }

  public getUserJobQuoteForJobPost(
    jobPostNumber: string,
    userId: string,
  ): Promise<any> {
    return this.performWebRequest('getUserJobQuoteForJobPost', 'POST', {
      jobPostNumber: jobPostNumber,
      userId: userId,
    });
  }

  public isClientView() {
    return this.clientView;
    //  let clientView = false;
    //  const selected = this.marketPlaceFilters['Product View'].items.find(
    //    (item) => item.selected,
    //  );
    //  if (selected && selected.name === 'Selections Hub') {
    //    clientView = true;
    //  }
    //  return clientView;
  }

  public setClientView(clientView: boolean) {
    this.clientView = clientView;
  }

  public leaveClientMode() {
    this.marketPlaceFilters['Product View'].items.find((item) => {
      item.selected = false;
    });
  }
}
