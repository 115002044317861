<div class="header">
  <div
    class="small-button secondary-button show-phone"
    *ngIf="!isChoosingEstimation()"
    (click)="reportError()"
  >
    There's an error in my estimate
  </div>
  <div class="spacer"></div>
  <div
    class="primary-button estimate-button"
    *ngIf="isChoosingEstimation()"
    (click)="useThisEstimateButtonClicked()"
  >
    Use this estimate
  </div>
  <img
    class="close-icon"
    src="../../../assets/images/close_icon.png"
    (click)="close()"
  />
</div>

<div class="page-container">
  <div class="page-header">
    <div
      class="page-header-tab"
      [ngClass]="{ active: activeTab === 'Estimates' }"
      (click)="activeTab = 'Estimates'"
    >
      Estimates
    </div>
    <div
      class="page-header-tab"
      [ngClass]="{ active: activeTab === 'Documents' }"
      (click)="activeTab = 'Documents'"
    >
      Documents
    </div>
    <div class="spacer"></div>
    <div
      class="small-button secondary-button hide-phone"
      *ngIf="
        estimation.estimationStatus === 'COMPLETED' && !isChoosingEstimation()
      "
      (click)="reportError()"
      gaEvent="estimate_error_button"
      gaCategory="click_event"
    >
      There's an error in my estimate
    </div>
  </div>
  <div class="content-container" *ngIf="activeTab === 'Estimates'">
    <div class="estimations-title-container">
      <div class="estimation-title">{{ estimation.jobName }}</div>
      <div
        class="pending-estimates"
        *ngIf="estimation.estimationStatus === 'PENDING'"
      >
        <img src="../../../assets/images/clock_icon.png" />Estimations pending
      </div>
    </div>
    <div class="estimation-header-tab">
      <div class="products-header">Products</div>
      <div class="total-header">Total m<sup>2</sup>/lm</div>
    </div>
    <div class="scroll-content hide-scrollbar">
      <div *ngFor="let jobItem of estimation.jobItems">
        <div class="product-row">
          <img
            class="product-image"
            [src]="jobItem.product.thumbnailImageUrl | safeUrl"
          />
          <div class="product-title">
            {{ jobItem.product.productCollection }}
          </div>
          <div
            class="total pending"
            *ngIf="estimation.estimationStatus === 'PENDING'"
          >
            <img src="../../../assets/images/clock_icon.png" />Estimations
            pending
          </div>
          <div
            class="total"
            *ngIf="estimation.estimationStatus === 'COMPLETED'"
          >
            {{ (+productEstimate(jobItem).quantity).toFixed(2)
            }}{{ productEstimate(jobItem).unit }}
          </div>
        </div>
        <div
          class="sub-product-container"
          *ngIf="estimation.estimationStatus === 'COMPLETED'"
        >
          <div
            class="sub-product-row"
            *ngFor="let item of subProducts(jobItem)"
          >
            <div class="side-bar"></div>
            <div class="product-title">{{ item.description }}</div>
            <div class="total">
              {{ (+item.quantity).toFixed(2) }}{{ item.unit }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="content-container" *ngIf="activeTab === 'Documents'">
    <div class="documents-grid">
      <div
        class="document"
        *ngIf="estimation.jobDocument"
        (click)="downloadDocument(estimation.jobDocument)"
      >
        <div class="image-container">
          <img
            class="document-image"
            src="../../../assets/images/document_icon.png"
          />
          <img
            class="download-image"
            src="../../../assets/images/download_icon_2.png"
          />
        </div>
        <div class="document-title">
          {{ formatFilename(estimation.jobDocument) }}
        </div>
      </div>
      <div
        class="document"
        *ngFor="let document of estimation.jobDocuments"
        (click)="downloadDocument(document)"
      >
        <div class="image-container">
          <img
            class="document-image"
            src="../../../assets/images/document_icon.png"
          />
          <img
            class="download-image"
            src="../../../assets/images/download_icon_2.png"
          />
        </div>
        <div class="document-title">{{ formatFilename(document.name) }}</div>
      </div>
      <!-- <div class="document" (click)="downloadDocument(estimation.costingSheetUrl)">
                <div class="image-container">
                    <img class="document-image" src="../../../assets/images/document_icon.png">
                    <img class="download-image" src="../../../assets/images/download_icon_2.png">
                </div>
                <div class="document-title">Costing sheet</div>
            </div> -->
      <div
        class="document"
        *ngFor="let document of estimation.projectFiles"
        (click)="downloadDocument(document)"
      >
        <div class="image-container">
          <img
            class="document-image"
            src="../../../assets/images/document_icon.png"
          />
          <img
            class="download-image"
            src="../../../assets/images/download_icon_2.png"
          />
        </div>
        <div class="document-title">{{ document.name }}</div>
      </div>
    </div>
  </div>
</div>
