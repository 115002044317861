import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthHelper } from 'src/app/helpers/AuthHelper';

@Component({
  selector: 'app-job-board-tabs',
  templateUrl: './job-board-tabs.component.html',
  styleUrls: ['./job-board-tabs.component.scss']
})
export class JobBoardTabsComponent implements OnInit {

  @Input() active = '';

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goTo(url: string) {
    this.router.navigateByUrl(url);
  }

  get user() {
    return AuthHelper.instance.user;
  }

}
