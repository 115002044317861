import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/http.service';
import { Order } from 'src/app/classes/Order';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(private httpService: HttpService) {}

  confirmOrder(order: Order) {
    return this.httpService.post('/confirm-order', {
      response: 'json',
      body: order,
    });
  }

  submitOrder(order: Order) {
    return this.httpService.post('/submitOrder', {
      response: 'json',
      body: { order },
    });
  }
}
